import React from 'react';
import Checkboxes from "../../../fields/Checkboxes";
import {Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {dictionaryFieldsSelector} from "../../../../store/slices/userSlice";

const Roles = ({form, setValue, errors, name}) => {
    const {t} = useTranslation();

    const fields = useSelector(state => dictionaryFieldsSelector(state, name, undefined, true));

    const getFieldSettings = (name) => fields?.find(f => f.name === name) || {};

    const allSectionNames = (settings) => [...new Set(settings?.forSelectValues.map(v => v.sectionName))]

    const checkboxes = (settings, section, noLabel) => {
        return <Checkboxes
            label={!noLabel && t(section || 'others')}
            name={settings.name}
            value={form[settings.name]}
            error={errors[settings.name]}
            setValue={setValue}
            forSelectValues={settings.forSelectValues?.filter(v => v.sectionName === section)}
        />
    }

    const pane = (name, noLabel) => {
        const fieldSettings = getFieldSettings(name);
        const allSectionName = allSectionNames(fieldSettings);

        return {
            menuItem: t(fieldSettings.name),
            render: () => <Tab.Pane className='checkboxes-field'>
                {
                    allSectionName.map(section => {
                        return checkboxes(fieldSettings, section, noLabel)
                    })
                }
            </Tab.Pane>
        }
    }

    const panes = [
        pane('permissions'),
        pane('actions'),
        pane('notifications', true)
    ]

    return (
        <Tab className='margin-top-7 w-100' panes={panes}/>
    );
};

export default Roles;