import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Confirm, Icon, Popup} from "semantic-ui-react";

const DeleteBlock = ({onDelete, className, deleteConfirmText = 'deleteConfirm'}) => {
    const {t} = useTranslation();

    const [confirmOpen, setConfirmOpen] = useState(false);

    return <>
        <Popup
            position='top center'
            content={t('delete')}
            trigger={<Icon
                className={`${className || ''} cursor-pointer`}
                name='trash alternate'
                onClick={() => setConfirmOpen(true)}
            />}
        />
        <Confirm
            dimmer="blurring"
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={() => {
                onDelete();
                setConfirmOpen(false)
            }}
            cancelButton={t('cancelButton')}
            content={t(deleteConfirmText)}
        />
    </>
};

export default DeleteBlock;