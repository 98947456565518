import {useState} from "react";
import _ from 'lodash';

export const useForm = (initialForm, ignoreChanges) => {
    const [form, setForm] = useState(initialForm || {});

    const setValue = (e, {name, value, checked}) => {
        setForm(form => ({
            ...form,
            [name]: checked !== undefined ? checked : value
        }))
    }

    return [
        form,
        setValue,
        setForm,
        {
            isChanged: !_.isEqual(
                {
                    ...initialForm,
                    ...(ignoreChanges || {})
                } || {},
                form || {}
            )
        }
    ];
}