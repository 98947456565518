import React, {useEffect} from 'react';
import DocsListViewCompact from "../../documents/DocsListViewCompact";
import {useLazyGetDocsQuery} from "../../../store/api/documentApi";
import LoadingSegment from "../../LoadingSegment";
import NoData from "./NoData";

const DocsViewer = ({viewIds, id, className, onClick, onClose}) => {
    const [getDocsById, {data: documents = [], isLoading}] = useLazyGetDocsQuery();

    useEffect(() => {
        if (id) {
            getDocsById({id})
        }
    }, [id])

    return (
        <LoadingSegment
            className={className}
            isLoading={isLoading}
        >
            {
                documents?.length
                    ? <DocsListViewCompact
                        documents={documents || []}
                        viewIds={viewIds}
                        onClick={onClick}
                        onClose={onClose}
                    />
                    : <div className='d-flex j-c-center margin-top-2em'>
                        <NoData text={'noDocs'}/>
                    </div>
            }
        </LoadingSegment>
    );
};

export default DocsViewer;