import React from 'react';
import {useTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import {ACTIONS} from "../../../constants/fieldTypes";

const TableHeader = ({columns, className, noWrap}) => {
    const {t} = useTranslation();

    return (
        <Table.Row className={className}>
            {
                columns.map(col => (
                    <Table.HeaderCell
                        key={col.name}
                        className={`scroll-table_12 ${col.type === ACTIONS ? 'hc-actions' : ''} ${noWrap ? 'scroll-table__nowrap' : ''}`}
                    >
                        {t(col.displayNameKey || col.name)}
                    </Table.HeaderCell>
                ))
            }
        </Table.Row>
    );
};

export default TableHeader;