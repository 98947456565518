import React from "react";
import {Input} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const Text = ({
                  value,
                  name,
                  onChange: onChangeValue,
                  isDisabled,
                  noLabel,
                  className,
                  label,
                  isRequired,
                  placeholder,
                  type,
                  isInteger
              }) => {
    const {t} = useTranslation();

    const isTime = type === 'time';

    const isIntegerCheck = (value) => {
        return !value || /^-?\d*$/.test(value)
    };

    const onChange = (e, {name, value}) => {
        if (!isInteger || isIntegerCheck(value)) {
            onChangeValue(null, {name, value: isTime ? value + ':00' : value})
        }
    }

    return (
        <>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(label || name)} {isRequired ? '*' : ''}</label> : null}
            <Input
                type={type}
                className={className}
                disabled={isDisabled || false}
                name={name}
                value={(isTime ? value?.slice(0, -3) : value) || ""}
                onChange={onChange}
                autoComplete="off"
                placeholder={placeholder}
            />
        </>
    );
};
export default Text;