import React from 'react';
import {Form, Grid, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FieldComponent from "../../../components/fields";
import {DATE_TIME, TEXT} from "../../../constants/fieldTypes";

const RouteInfo = ({isView, form, setValue, errors, fieldSettings}) => {
    const {t} = useTranslation();

    return (<>
        <Form.Field className="margin-bottom-0">
            <label className="margin-bottom-0">{t('route')}</label>
        </Form.Field>
        <Segment>
            <Grid className="margin-bottom-10">
                <Grid.Row className="padding-bottom-0">
                    <Grid.Column width={8}>
                        <FieldComponent
                            type={TEXT}
                            error={errors.tripNumber}
                            isDisabled={isView}
                            name='tripNumber'
                            value={form.tripNumber}
                            onChange={setValue}
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal" className="padding-bottom-0">
                    <Grid.Column>
                        <FieldComponent
                            type={TEXT}
                            error={errors.loadingCity}
                            isDisabled={isView}
                            isRequired
                            name='loadingCity'
                            value={form.loadingCity}
                            onChange={setValue}
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FieldComponent
                            type={TEXT}
                            error={errors.unloadingCity}
                            isDisabled={isView}
                            isRequired
                            name='unloadingCity'
                            value={form.unloadingCity}
                            onChange={setValue}
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal" className="padding-bottom-0">
                    <Grid.Column className="router-into-datepicker">
                        <FieldComponent
                            type={DATE_TIME}
                            error={errors.loadingDate}
                            name='loadingDate'
                            isRequired
                            popperPlacement="top-end"
                            value={form.loadingDate}
                            isDisabled={isView}
                            onChange={setValue}
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                    <Grid.Column className="router-into-datepicker">
                        <FieldComponent
                            type={DATE_TIME}
                            error={errors.unloadingDate}
                            name='unloadingDate'
                            isRequired
                            popperPlacement="top-end"
                            value={form.unloadingDate}
                            isDisabled={isView}
                            onChange={setValue}
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </>);
};

export default RouteInfo;