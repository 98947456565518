import React from 'react';
import {Table} from "semantic-ui-react";
import {defaultFunc} from "../../../utils/stateHelper";
import Value from "./Value";
import {ACTIONS, IS_ACTIVE} from "../../../constants/fieldTypes";
import EditValue from "./EditValue";


const TableCell = ({
                       row,
                       col,
                       load,
                       onClick,
                       clickToggle: clickMutation = defaultFunc,
                       actionFunc,
                       name,
                       changeFieldValue,
                       className,
                       rowSpan,
                       style
                   }) => {

    const value = <Value
        clickMutation={clickMutation}
        col={col}
        load={load}
        row={row}
        actionFunc={actionFunc}
        name={name}
    />;

    return (
        <Table.Cell
            style={style}
            rowSpan={rowSpan || 1}
            onClick={
                [ACTIONS].includes(col.type)
                    ? (e) => {
                        e.preventDefault()
                    }
                    : onClick
            }
            textAlign={[IS_ACTIVE, ACTIONS].includes(col.type) ? 'center' : 'left'}
            className={`table-td ${className || ''}`}
        >
            {
                !col.isBulkUpdateable
                    ? value
                    : <EditValue
                        col={col}
                        value={row[col.name]}
                        onChange={changeFieldValue}
                        load={load}
                        name={name}
                        id={row.id}
                    >
                        {value}
                    </EditValue>
            }
        </Table.Cell>
    );
};

export default TableCell;