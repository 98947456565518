import React from 'react';
import {Label, Table} from "semantic-ui-react";
import {fieldSettingsValues} from "../../constants/options";
import {useTranslation} from "react-i18next";
import FieldSettingDropdown from "./fieldSettingDropdown";
import {searchByName} from "../../utils/search";

const FieldsWithName = ({name, fields, states, searchValue, form, refresh, isCustom, entityType, matrix}) => {
    const {t} = useTranslation();

    const settingOptions = Object.keys(fieldSettingsValues).map(f => ({
        value: fieldSettingsValues[f],
        text: t(fieldSettingsValues[f]),
        key: fieldSettingsValues[f],
    }))

    const nameRow = (name) => (
        <Table.Row className='field-matrix__ribbon'>
            <Table.Cell colSpan={(states?.length || 0) + 1}>
                <Label>{t(name)}</Label>
            </Table.Cell>
        </Table.Row>
    )

    return <>
        {
            !!fields?.length && nameRow(name)
        }
        {
            searchByName(fields, searchValue)?.map((field, i) => {
                return <Table.Row key={i}>
                    <Table.Cell className='field-matrix__field-name'>
                        <div>
                            <b>{t(field.displayNameKey || field.name)}</b>
                        </div>
                        <FieldSettingDropdown
                            form={form}
                            field={field}
                            isCustom={isCustom}
                            isAll
                            settingOptions={settingOptions}
                            entityType={entityType}
                            refresh={refresh}
                        />
                    </Table.Cell>
                    {
                        states?.map(state => (
                            <Table.Cell key={state.name}>
                                <FieldSettingDropdown
                                    form={form}
                                    field={field}
                                    isCustom={isCustom}
                                    settingOptions={settingOptions}
                                    state={state}
                                    entityType={entityType}
                                    refresh={refresh}
                                    matrixElement={matrix?.find(m => (field.name === m.fieldName) && m.entityStatus === state.name)}
                                />
                            </Table.Cell>
                        ))
                    }
                </Table.Row>
            })
        }
    </>
};

export default FieldsWithName;