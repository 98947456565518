import React from 'react';
import {dictionarySettingsByName} from "../../constants/utils";
import CreateOrEditDictionaryModal from "./modals/CreateOrEditModal";
import {useSelector} from "react-redux";
import {dictionarySelector} from "../../store/slices/userSlice";

const EditBtn = ({btn, dictionaryName, ...props}) => {
    const dictionarySettings = dictionarySettingsByName(dictionaryName) || {};
    const dictionaryData = useSelector(state => dictionarySelector(state, dictionaryName)) || {};

    const {modalContentComponent, customTabs, withoutAutoForm, size, colsCounts} = dictionarySettings;
    const {withDocuments} = dictionaryData;

    return (
        <CreateOrEditDictionaryModal
            {...props}
            dictionaryName={dictionaryName}
            size={size}
            colsCounts={colsCounts}
            withoutAutoForm={withoutAutoForm}
            customFormSections={customTabs}
            modalContent={modalContentComponent}
            withHistory
            withDocuments={withDocuments}
        >
            {btn}
        </CreateOrEditDictionaryModal>
    );
};

export default EditBtn;