import React from 'react';

export const styleForFields = (colsCounts, initWidth) => {
    const width = 100 / colsCounts - 2;
    const margin = `calc(calc(${(100 - (width * colsCounts))}% / ${colsCounts}) / 2)`
    const customWidth = initWidth && `calc(${initWidth}% - ${margin} - ${margin})`

    return {
        width: customWidth || `${width}%`,
        marginLeft: margin,
        marginRight: margin,
    }
};

export const hexToRgb = (hexColor) => {
    const r = parseInt(hexColor.substring(1,3), 16);
    const g = parseInt(hexColor.substring(3,5), 16);
    const b = parseInt(hexColor.substring(5,7), 16);

    return {r, g, b}
}

export const getContrastColor = (hexColor) => {
    const {r, g, b} = hexToRgb(hexColor);
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luma < 180 ? '#ffffff' : '#000000';
}

export const convertColor = (r, g, b, alpha) => {
    const res = (code) => {
        return Math.round((code * alpha) + (255 * (1 - alpha)));
    }

    return `rgb(${res(r)}, ${res(g)}, ${res(b)})`;
}

export const setStyle = (styleString, id) => {
    const css = styleString,
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.id = id;
    if (style.styleSheet){
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
}

export const deleteStyle = (id) => {
    const element = document.getElementById(id);
    element?.remove();
}