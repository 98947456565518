import React from "react";
import TableBlock from "../../../components/TableBlock/TableBlock";
import {requestsTable} from "../../../constants/requests";
import {useActionMutation} from "../../../store/api/dictionaryApi";

export default function PreviousRequests({requests, deleteRequest}) {
    const getList = () => {
        return [
            () => null,
            {
                data: {
                    items: requests?.map(r => ({
                        ...r,
                        actions: r.status?.value === 'departed' && [
                            {
                                onClick: () => {
                                    deleteRequest(r.id)
                                },
                                icon: 'delete'
                            }
                        ]
                    })),
                    totalCount: requests
                },
            }
        ]
    }

    return <TableBlock
        key={`requests`}
        className='history-table history-table_requests'
        settings={requestsTable}
        getListQuery={getList}
        actionFunc={useActionMutation}
    />;
};