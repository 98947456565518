import React, {useCallback, useState} from 'react';
import {Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import LoadingSegment from "../../../components/LoadingSegment";
import {useTranslation} from "react-i18next";
import {PHONE_NUMBER, SELECT, TEXT} from "../../../constants/fieldTypes";
import FieldComponent from "../../../components/fields";

const CompanyInfo = ({form, isView, setValue, errors, fieldSettings}) => {
    const {t} = useTranslation();

    const handleChange = (name, value) => {
        setValue(null, {name, value})
    }
    
    const tcModeInitialValue = !!form.carrierSearchname;
    const [tcMode, setTcMode] = useState(tcModeInitialValue); // Режим выбора ТК: false - из списка, true - ввод SearchName ТК
    const handleTcModeChange = useCallback((e, { value }) => {
        setTcMode(value !== 'tc')
        if (value === 'tc') {
            handleChange("carrierSearchname", null);
        }
        else {
            handleChange("carrierId", null);
            handleChange("carrierInn", null);
        }
    }, [handleChange]);
    
    return (
        <LoadingSegment
            className='form-block'
        >
            <Form.Field className="margin-bottom-0">
                <label className="margin-bottom-0">{t('transportCompany')}</label>
            </Form.Field>
            <Segment>
                <Grid className="margin-bottom-10">
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column width={8}>
                            <Checkbox
                                radio
                                label={t('tcChoice') + (tcMode ? "" : " *")}
                                name="transportCompanyMode"
                                value="tc"
                                readOnly={isView}
                                checked={!tcMode}
                                onChange={handleTcModeChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Checkbox
                                radio
                                label={t('tcSearchName') + (tcMode ? " *" : "")}
                                name="transportCompanyMode"
                                value="searchname"
                                readOnly={isView}
                                checked={tcMode}
                                onChange={handleTcModeChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={SELECT}
                                error={errors.carrierId}
                                noLabel
                                value={form.carrierId}
                                name='carrierId'
                                dictionaryName='companies'
                                onChange={setValue}
                                isDisabled={isView || tcMode}
                                withEdit
                                withCreate
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={TEXT}
                                error={errors.carrierSearchname}
                                noLabel
                                isDisabled={isView || !tcMode}
                                value={form.carrierSearchname}
                                name='carrierSearchname'
                                onChange={setValue}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name='inn'
                                value={form.transportCompanyInn}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column>
                            <Checkbox
                                label={t('attractedTransport')}
                                disabled={isView}
                                checked={form.isAttracted}
                                onChange={(e, {checked}) => {
                                    handleChange("isAttracted", checked);
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled={isView || !form.isAttracted}
                                name='subcontractorInn'
                                onChange={setValue}
                                value={form.subcontractorInn}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={PHONE_NUMBER}
                                isDisabled={isView || !form.isAttracted}
                                name='subcontractorPhone'
                                onChange={setValue}
                                value={form.subcontractorPhone}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={TEXT}
                                label={t('attractedCompanyAtiCode')}
                                isDisabled={isView}
                                name='atiCode'
                                value={form.atiCode}
                                onChange={setValue}
                                error={errors.atiCode}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </LoadingSegment>
    );
};

export default CompanyInfo;