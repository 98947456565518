import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: baseQuery('/files'),
    endpoints: (build) => ({
        uploadFile: build.mutation({
            query: (data) => {
                return {
                    url: '/upload',
                    method: 'POST',
                    body: data
                }
            }
        }),
        viewFile: build.query({
            query: ({id}) => {
                return {
                    url: `/viewfile?fileId=${id}`,
                    responseHandler: async (response) => {
                        if (response.ok) {
                            const blobImage = await response?.blob();
                            return URL.createObjectURL(blobImage)
                        }
                    },
                }
            }
        }),
        getFile: build.query({
            query: ({id}) => {
                return {
                    url: `/get?id=${id}`,
                }
            }
        }),
        deleteFile: build.mutation({
            query: ({id}) => {
                return {
                    url: `/delete?id=${id}`,
                    method: 'DELETE'
                }
            }
        }),
        uploadBase64File: build.mutation({
            query: ({base64, fileName}) => {
                return {
                    url: `/uploadBase64`,
                    method: 'POST',
                    body: {
                        base64,
                        fileName
                    }
                }
            }
        }),
    })
});

export const {
    useLazyGetFileQuery,
    useViewFileQuery,
    useUploadFileMutation,
    useDeleteFileMutation,
    useUploadBase64FileMutation,
} = fileApi;