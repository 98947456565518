import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Icon} from "semantic-ui-react";
import './styles.scss';
import {useTranslation} from "react-i18next";
import FieldComponent from "../fields";
import {defaultFunc} from "../../utils/stateHelper";
import LoadingSegment from "../LoadingSegment";
import {toast} from "react-toastify";
import {dictionaryOrGridEditPermission} from "../../store/slices/userSlice";
import {useSelector} from "react-redux";


const FormField = ({form, valueField, setFormValue, getFieldSettings = defaultFunc, gridName, readOnly, className}) => {
    const {t} = useTranslation();

    const [getFieldSettingsRequest, {
        data: fieldSettings,
        isLoading: fieldSettingsLoading,
        isFetching: fieldSettingsFetching
    }] = getFieldSettings();

    useEffect(() => {
        (form.id) && getFieldSettingsRequest({
            name: gridName,
            id: form.id
        })
    }, [gridName, form.id]);

    return <LoadingSegment
        className={className}
        isLoading={fieldSettingsLoading || fieldSettingsFetching}
    >
        <FieldComponent
            {...valueField}
            key={valueField.name}
            name={valueField.name}
            onChange={setFormValue}
            value={form[valueField.name]}
            label={t(form.description)}
            fieldSettings={fieldSettings}
            isDisabled={readOnly || valueField.isDisabled}
        />
    </LoadingSegment>
}


const FormCard = ({name, forms, valueField, setFormValue, getFieldSettings = defaultFunc, gridName, readOnly}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(true);

    return <Card
        className='card-form'
        key={name}
    >
        <Card.Content className='card-form__card-header'>
            <Card.Header>{t(name)}</Card.Header>
            <Icon
                name={isOpen ? 'angle up' : 'angle down'}
                onClick={() => setIsOpen(open => !open)}
            />
        </Card.Content>

        {isOpen && <Card.Content>
            <Form>
                {forms.map((form, i) => <FormField
                    className={(i > 0) && 'padding-top-20'}
                    form={form}
                    valueField={valueField}
                    setFormValue={(e, {name, value}) => setFormValue(form.id, name, value)}
                    getFieldSettings={getFieldSettings}
                    gridName={gridName}
                    readOnly={readOnly}
                />)}
            </Form>
        </Card.Content>}
    </Card>
}

const CardsForm = ({data, columns, getFieldSettings, name, save = defaultFunc, refresh}) => {
    const {t} = useTranslation();
    const canEdit = useSelector(state => dictionaryOrGridEditPermission(state, name));

    const [arrayOfForms, setArrayOfForms] = useState(data);
    const [isChanged, setIsChanged] = useState(false);

    const [saveRequest, {
        isLoading: saveLoading,
        isFetching: saveFetching
    }] = save();

    const setFormValue = (id, name, value) => {
        const newArray = [...arrayOfForms];

        const index = newArray.findIndex(f => f.id === id);
        if (index > -1) {
            newArray[index] = {
                ...newArray[index],
                [name]: value
            }
            setArrayOfForms(newArray);
            setIsChanged(true);
        }
    }

    useEffect(() => {
        setArrayOfForms(data);
    }, [data]);

    const allFormNames = [...new Set(arrayOfForms.map(f => f.name))];

    const valueField = columns?.find(col => col.name === 'value');

    const handleSave = () => {

        const errorFunc = res => {
            toast.error(res?.data.message || t('error'));
        }

        saveRequest({
            name,
            form: arrayOfForms
        }).unwrap().then(res => {
            if (!res?.isError) {
                setIsChanged(false);
                refresh && refresh();
                toast.info(res?.message || t('saved'))
            } else {
                errorFunc(res)
            }
        }).catch(errorFunc)
    }

    return (
        <div className='cards-form'>
            <LoadingSegment
                className='cards-form__segment'
                isLoading={saveLoading || saveFetching}
            >
                {
                    allFormNames?.map(formName => <FormCard
                        name={formName}
                        key={formName}
                        readOnly={!canEdit}
                        forms={arrayOfForms.filter(f => f.name === formName)}
                        valueField={valueField}
                        setFormValue={setFormValue}
                        getFieldSettings={getFieldSettings}
                        gridName={name}
                    />)
                }
            </LoadingSegment>
            <div className='cards-form__btns'>
                {canEdit && <Button
                    color="green"
                    icon='check'
                    labelPosition='right'
                    content={t('saveBtn')}
                    onClick={handleSave}
                    disabled={!isChanged || saveLoading || saveFetching}
                    loading={saveLoading || saveFetching}
                />}
            </div>
        </div>
    );
};

export default CardsForm;
