import React from 'react';
import FieldComponent from "../../../fields";
import {SELECT} from "../../../../constants/fieldTypes";
import {Form} from "semantic-ui-react";
import {exceptionSchedulesCols, standardSchedulesCols} from "../../../../constants/dictionaries";
import EditAndCreateTable from "../../../table/formTable/editAndCreateTable";
import {useTranslation} from "react-i18next";

const GateSchedule = ({i, form, item, setValue, allActivitiesIds}) => {
    const {t} = useTranslation();
    const setVal = (e, {value, name}) => {
        setValue(e, {value, name}, i);
    }

    const setRows = (rows, name) => {
        setValue(null, {
            value: rows,
            name
        }, i)
    }

    return (
        <Form className='gate-schedule'>
            <FieldComponent
                className='z-index-max'
                isDisabled={!form.platformId}
                type={SELECT}
                value={item.activityId}
                name='activityId'
                dictionaryName='activities'
                onChange={setVal}
                clearable
                filter={{
                    platformId: form.platformId?.value
                }}
                hiddenValues={allActivitiesIds}
            />

            <Form.Field>
                <label>{t('exceptions')}</label>
                <EditAndCreateTable
                    autoValues={{
                        activityId: item.activityId
                    }}
                    columns={exceptionSchedulesCols}
                    rows={item && item['exceptions']}
                    setValue={(rows) => setRows(rows, 'exceptions')}
                />
            </Form.Field>

            <Form.Field>
                <label>{t('standardSchedule')}</label>
                <EditAndCreateTable
                    autoValues={{
                        activityId: item.activityId
                    }}
                    columns={standardSchedulesCols}
                    rows={item && item['schedules']}
                    setValue={(rows) => setRows(rows, 'schedules')}
                />
            </Form.Field>
        </Form>
    );
};

export default GateSchedule;