import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const avisationApi = createApi({
    reducerPath: 'avisationApi',
    baseQuery: baseQuery('/avization'),
    endpoints: (build) => ({
        getTimeslots: build.mutation({
            query: (data) => {
                return {
                    url: `/`,
                    method: 'POST',
                    body: data
                }
            }
        }),
    })
});

export const {
    useGetTimeslotsMutation,
} = avisationApi;