import React from 'react';
import {Icon, Popup, Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const GateReviewsTable = ({gates, idKey}) => {
    const {t} = useTranslation();
    const getTimeSlotData = (gate, time) => {
        return gate.timeSlots.find(timeSlot => timeSlot.time === time)
    }

    const content = (timeSlotData) => {
        return <div className='white-space_pre-line'>
            <div><b>{timeSlotData.greenCorridorInfo || ''}</b></div>
            <div>{timeSlotData.reservationInfo || ''}</div>
        </div>
    }

    return (
        <div
            className='gate-reviews__table'
            id={idKey}
        >
            {!!gates?.length && <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            className='gate-reviews__first-hc'
                            textAlign='center'
                        >
                            <div>{t('gates')}</div>
                            <div>{t('timeSlots')}</div>
                        </Table.HeaderCell>
                        {
                            (gates || []).map(gate => <Table.HeaderCell
                                key={gate.name}
                                textAlign='center'
                            >
                                {gate.name}
                            </Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        gates[0].timeSlots?.map(timeSlot => <Table.Row key={timeSlot.time}>
                            <Table.Cell
                                key={`name_${timeSlot.time}`}
                                textAlign='right'
                            >
                                {timeSlot.time}
                            </Table.Cell>
                            {
                                (gates || []).map(gate => {
                                    const timeSlotData = getTimeSlotData(gate, timeSlot.time) || {};

                                    return <Table.Cell
                                        textAlign='center'
                                        key={`${gate.name}_${timeSlot.time}`}
                                        className={`background-color_${timeSlotData.gateReviewColor}`}
                                    >
                                        {
                                            timeSlotData.isReserved && <Popup
                                                position='top center'
                                                trigger={<Icon
                                                    name='delete'
                                                />}
                                                content={content(timeSlotData)}
                                            />
                                        }
                                    </Table.Cell>
                                })
                            }
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>}
        </div>
    );
};

export default GateReviewsTable;