import React, {useEffect} from 'react';
import {Form, Grid, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import DocsListViewCompact from "../../../components/documents/DocsListViewCompact";
import LoadingSegment from "../../../components/LoadingSegment";
import {useLazyGetDocsQuery} from "../../../store/api/documentApi";
import {securityServiceGridName} from "../../../constants/security";
import FieldComponent from "../../../components/fields";
import {ENUM, SELECT, TEXT, TEXTAREA} from "../../../constants/fieldTypes";
import {useLazyGetElementQuery} from "../../../store/api/dictionaryApi";

const TransportInfo = ({form, setValue, isView, isStateEdit, name, withType, errors, fieldSettings}) => {
    const {t} = useTranslation();

    const getDictionaryName = (name) => name + 's';
    const getIdName = (name) => name + 'Id';
    const getNameState = (name) => name + 'Status';
    const getNameNotes = (name) => name + 'Notes';

    const typeName = 'vehicleType';

    const dictionaryName = getDictionaryName(name);
    const idName = getIdName(name);
    const typeIdName = getIdName('truckType');
    const nameState = getNameState(name);
    const nameNotes = getNameNotes(name);

    const [getDocsById, {data: documents = [], isLoading}] = useLazyGetDocsQuery();
    const [getElement] = useLazyGetElementQuery();

    useEffect(() => {
        form[idName] && getDocsById({id: form[idName].value})
    }, [form[idName]])

    const onChange = (e, {name: valueName, value}) => {
        setValue(null, {name: valueName, value});
        if (name === 'truck' && value) {
            if (value.description) {
                setValue(null, {name: typeIdName, value: value.description});
            } else {
                getElement({name: dictionaryName, id: value.value})
                    .unwrap()
                    .then((data) => {
                        setValue(null, {name: typeIdName, value: data.vehicleTypeId?.name});
                    })
            }
        }
    }

    return (
        <LoadingSegment
            className='form-block'
            isLoading={isLoading}
        >
            <Form.Field className="margin-bottom-0">
                <label className="margin-bottom-0">{t(name)}</label>
            </Form.Field>
            <Segment>
                <Grid className="margin-bottom-10">
                    <Grid.Row className="padding-bottom-0 transport-info">
                        <Grid.Column width={withType ? 5 : 11} className="transport-info-item">
                            <FieldComponent
                                type={SELECT}
                                error={errors[idName]}
                                value={form[idName]}
                                label={t(name)}
                                name={idName}
                                dictionaryName={dictionaryName}
                                onChange={onChange}
                                isDisabled={isView}
                                withEdit
                                withCreate
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        {withType && <Grid.Column width={6}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                label={t(typeName)}
                                value={form[typeIdName]}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>}
                        <Grid.Column width={5}>
                            {isView && <FieldComponent
                                type={ENUM}
                                error={errors[nameState]}
                                name={nameState}
                                value={form[nameState]}
                                onChange={setValue}
                                gridName={securityServiceGridName}
                                isDisabled={!isStateEdit}
                                label='securityCheckState'
                                isRequired
                                fieldSettings={fieldSettings}
                            />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="padding-bottom-0 transport-info">
                        <Grid.Column width={8} className={documents?.length && 'transport-info-document'}>
                            <DocsListViewCompact
                                documents={documents || []}
                                viewIds={form[`${name}Documents`]}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <FieldComponent
                                type={TEXTAREA}
                                isDisabled
                                name={t(nameNotes)}
                                value={form[nameNotes]}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </LoadingSegment>
    );
};

export default TransportInfo;