import React, {useState} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {SELECT} from "../../../constants/fieldTypes";
import FieldComponent from "../../fields";
import {useForm} from "../../../hooks/useForm";
import EditBtn from "../EditBtn";
import {useSelector} from "react-redux";
import {userInfoSelector} from "../../../store/slices/userSlice";

const GroupTaskModal = ({children, onClick, ids, refresh}) => {
    const userInfo = useSelector(userInfoSelector);

    const {t} = useTranslation();

    const currentPlatform = userInfo?.currentPlatform?.value;
    const currentPlatformIsNull = !currentPlatform;

    const initForm = {
        platform: currentPlatform && { value: currentPlatform },
        visitTaskType: {
            "value": "unloading",
        }
    };
    const [form, setValue, setForm] = useForm(initForm);
    const [modalOpen, setModalOpen] = useState(false);
    const [platformVisible, setPlatformVisible] = useState(false);

    const onOpen = () => {
        setModalOpen(true);
    }

    const onClose = () => {
        setForm(initForm);
        modalOpen && setModalOpen(false)
        onClick && onClick(false);
        platformVisible && setPlatformVisible(false);
    }

    const onSave = () => {
        onClose();
        refresh && refresh();
    }

    return (
        <Modal
            trigger={children}
            open={modalOpen}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape
            closeOnDimmerClick={false}
            closeIcon
        >
            <Modal.Header>{t('groupIntoATask')}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <FieldComponent
                            clearable
                            type={SELECT}
                            value={form.visitNumber}
                            label={t('visitNumber')}
                            name='visitNumber'
                            gridName='visits'
                            onChange={(e, {name, value}) => {
                                setValue(null, {name, value})
                                if (form.taskNumber && value?.value !== form.taskNumber.value) {
                                    setValue(null, {name: 'taskNumber', value: null})
                                }
                            }}
                            filter={{
                                platformId: form.platform?.value
                            }}
                        />
                        <FieldComponent
                            clearable
                            type={SELECT}
                            value={form.taskNumber}
                            label={t('taskNumber')}
                            name='taskNumber'
                            gridName='visitTasks'
                            onChange={(e, {name, value}) => {
                                setValue(null, {name, value})
                                setValue(null, {name: 'visitNumber', value: value?.data ? {value: value.data} : null})
                            }}
                            filter={{
                                platformId: form.platform?.value,
                                visitId: form.visitNumber?.value
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="2">
                        <FieldComponent
                            type={SELECT}
                            value={form.visitTaskType}
                            label={t('visitTaskType')}
                            name='visitTaskType'
                            dictionaryName='companies/visitTaskType'
                            onChange={setValue}
                            filter={{
                                //todo
                            }}
                        />
                        {
                            platformVisible && <FieldComponent
                                type={SELECT}
                                value={form.platform}
                                label={t('platform')}
                                name='platform'
                                dictionaryName='platforms'
                                onChange={setValue}
                                filter={{
                                    //todo
                                }}
                            />
                        }
                    </Form.Group>
                </Form>
                {
                    platformVisible && !form.visitNumber && !form.taskNumber && !form.platform && <Form widths="equal">
                        <Message negative>
                            {t('selectPlatform')}
                        </Message>
                    </Form>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={t("cancelButton")}
                    onClick={onClose}
                />
                {
                    (currentPlatformIsNull && !form.visitNumber && !form.taskNumber && !form.platform)
                        ? <Button
                            color='blue'
                            onClick={() => setPlatformVisible(true)}
                        >
                            {t('saveBtn')}
                        </Button>
                        : <EditBtn
                            {...form}
                            orderIds={ids}
                            gridName='visits'
                            id={form.visitNumber?.value}
                            refresh={onSave}
                            btn={
                                <Button color='blue'>
                                    {t('saveBtn')}
                                </Button>
                            }
                        />
                }
            </Modal.Actions>
        </Modal>
    );
};

export default GroupTaskModal;