import React, {useEffect, useState} from 'react';
import {Input} from "semantic-ui-react";

const Search = ({placeholder, value: initialValue, onChange, innerRef, size, isAuto, onEnter}) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            handleBlur(e)
            onEnter && onEnter();
        }
    }

    const handleBlur = (e) => {
        if (initialValue !== value) {
            onChange(e, {value});
        }
    }

    const change = (e, {value}) => {
        !isAuto
            ? setValue(value)
            : onChange(e, {value})
    }

    return (
        <Input
            placeholder={placeholder}
            icon="search"
            className="search-input"
            onKeyDown={handleKeyPress}
            onChange={change}
            value={value || ""}
            onBlur={handleBlur}
            ref={innerRef}
            size={size}
        />
    );
};

export default Search;