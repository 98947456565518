import React from 'react';
import {Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const Bool = ({value, name, onChange, isDisabled, label, isRequired, noLabel}) => {
    const {t} = useTranslation();

    return (
        <Form.Checkbox
            disabled={isDisabled}
            required={isRequired}
            label={!noLabel && t(label)}
            checked={value}
            name={name}
            onChange={onChange}
        />
    );
};

export default Bool;