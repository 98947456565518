import React from "react";
import {Input} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import InputMask from 'react-input-mask';

const PhoneNumber = ({
                         value,
                         name,
                         onChange,
                         isDisabled,
                         noLabel,
                         className,
                         label,
                         isRequired,
                         placeholder
                     }) => {
    const {t} = useTranslation();

    const pastHandler = e => {
        const pastedValue = e.clipboardData.getData('Text').trim()
        const phoneNumber = normalizePhoneNumber(pastedValue);
        if (phoneNumber.length === 12) {
            e.preventDefault()
            onChange(e, {value: pastedValue, name})
            onChange(e, {value: phoneNumber, name})
        }
    }

    const normalizePhoneNumber = value => {
        let processedValue = ''

        const allowedFirstChar = ['8', '7', '+7']
        if (allowedFirstChar.includes(value[0])) {
            for (let i = 0; i < value.length; ++i) {
                const char = value[i]

                if (!isNaN(parseInt(char))) {
                    processedValue += char;
                }
            }
            processedValue = `+7${processedValue.substring(1, processedValue.length)}`
        }
        return processedValue
    }

    return (
        <>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(label || name)} {isRequired ? '*' : ''}</label> : null}
            <InputMask
                mask="+79999999999"
                maskChar={null}
                value={value || ''}
                disabled={isDisabled}
                onChange={e => onChange(e, {value: e.target.value, name})}
                onPaste={pastHandler}
            >
                {
                    inputProps => <Input
                        {...inputProps}
                        className={className}
                        disabled={isDisabled || false}
                        name={name}
                        value={value || ""}
                        autoComplete="off"
                        placeholder={placeholder}
                    />
                }
            </InputMask>
        </>
    );
};
export default PhoneNumber;