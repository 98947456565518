import React, {useEffect, useState} from 'react';
import {Button} from "semantic-ui-react";
import FieldComponent from "../../fields";
import {defaultFunc} from "../../../utils/stateHelper";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const EditValue = ({children, col, value: initValue, onChange = defaultFunc, load, name, id}) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(initValue);

    const [change, {isLoading, isFetching}] = onChange();

    useEffect(() => {
        !editMode && setValue(initValue)
    }, [editMode]);

    const onSave = () => {
        change({
            name,
            value,
            fieldName: col.name,
            ids: [id]
        })
            .unwrap()
            .then((res) => {
                if (res.isError) {
                    toast.error(t(res.message || 'error'))
                } else {
                    res.message && toast.info(t(res.message));
                    load && load();
                    setEditMode(false)
                }
            })
    }

    return (
        <div className='edit-value'>
            <div className='edit-value__val'>
                {
                    !editMode
                        ? children
                        : <FieldComponent
                            {...col}
                            noLabel
                            key={col.name}
                            onChange={(e, {value}) => setValue(value)}
                            value={value}
                            label={col.displayNameKey}
                        />
                }
            </div>
            <div className='edit-value__btns'>
                {editMode && <Button
                    disabled={isLoading || isFetching}
                    loading={isLoading || isFetching}
                    size='mini'
                    icon='check'
                    onClick={onSave}
                />}
                <Button
                    disabled={isLoading || isFetching}
                    size='mini'
                    icon={editMode ? 'delete' : 'edit'}
                    onClick={() => setEditMode(!editMode)}
                />
            </div>
        </div>
    );
};

export default EditValue;