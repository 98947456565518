import {toast} from "react-toastify";
import {t} from "i18next";

export const copyToClipboard = (text) => {
    navigator.clipboard &&
    navigator.clipboard.writeText(text).then(
        () => {
            toast.info(t('copiedToClipboard'));
        }
    );
}