import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const documentApi = createApi({
    reducerPath: 'documentApi',
    baseQuery: baseQuery('/documents'),
    endpoints: (build) => ({
        saveDoc: build.mutation({
            query: ({document, objectId}) => {
                return {
                    url: '/save',
                    method: 'POST',
                    body: {...document, objectId}
                }
            }
        }),
        getDocs: build.query({
            query: ({id}) => {
                return {
                    url: `/${id}/get`,
                }
            }
        }),
        deleteDoc: build.mutation({
            query: ({id}) => {
                return {
                    url: `/delete?documentId=${id}`,
                    method: 'DELETE'
                }
            }
        }),
    })
});

export const {
    useLazyGetDocsQuery,
    useSaveDocMutation,
    useDeleteDocMutation,
} = documentApi;