import {MULTISELECT, MULTISELECT_WITH_ALL, TEXT} from "./fieldTypes";

export const PROFILE_NAME = 'profile';

export const profileFields = [
    {
        name: 'email',
        displayNameKey: 'email',
        type: TEXT
    },
    {
        name: 'secondName',
        displayNameKey: 'secondName',
        type: TEXT
    },
    {
        name: 'firstName',
        displayNameKey: 'firstName',
        type: TEXT
    },
    {
        name: 'patronymicName',
        displayNameKey: 'patronymicName',
        type: TEXT
    },
    {
        name: 'roles',
        displayNameKey: 'roles',
        dictionaryName: 'roles',
        type: MULTISELECT,
        isDisabled: true
    },
    {
        name: 'platformNames',
        displayNameKey: 'platforms',
        type: MULTISELECT_WITH_ALL,
        dictionaryName: 'platforms',
        isDisabled: true
    },
    {
        name: 'activityNames',
        displayNameKey: 'activities',
        type: MULTISELECT_WITH_ALL,
        dictionaryName: 'activities',
        isDisabled: true
    },
    {
        name: 'companyNames',
        displayNameKey: 'companies',
        type: MULTISELECT_WITH_ALL,
        dictionaryName: 'companies',
        isDisabled: true
    },
]