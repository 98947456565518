import _ from 'lodash';

export const checkValuesInFields = (objectsArray, fieldName, valuesArray) => {
    return valuesArray.every(value => objectsArray.some(obj => obj[fieldName] === value));
}

export function transformArrayToObject(arr) {
    const result = {};

    arr.forEach(item => {
        const { name, message, objectType, sequence } = item;

        if (!result[objectType]) {
            result[objectType] = {};
        }

        if (!result[objectType][sequence]) {
            result[objectType][sequence] = {};
        }

        result[objectType][sequence][name] = message;
    });

    return result;
}

export function updateValue(obj, objectType, sequence, name, newValue) {
    const updatedObj = _.cloneDeep(obj)

    if (updatedObj[objectType] && updatedObj[objectType][sequence] && updatedObj[objectType][sequence][name] !== undefined) {
        updatedObj[objectType][sequence][name] = newValue;
    }

    return updatedObj;
}

export function removeNonUUIDIds(data) {
    if (typeof data !== 'object' || !data) {
        return data;
    }

    if (Array.isArray(data)) {
        data.forEach((item, index) => {
            if (typeof item === 'object' && item !== null) {
                if (typeof item.id === 'number') {
                    delete data[index].id;
                } else {
                    removeNonUUIDIds(item);
                }
            }
        });
    } else {
        for (let key in data) {
            if (typeof data[key] === 'object' && data[key] !== null) {
                if (typeof data[key].id === 'number') {
                    delete data[key].id;
                } else {
                    removeNonUUIDIds(data[key]);
                }
            }
        }
    }
    return data;
}

export const maxValueFromArray = (arr, name) => {
    return arr.length ? Math.max(...(arr?.map(v => v[name] || 0))) : 0
}

export const fieldsToValues = (fields) => {
    return fields?.map(f => ({
        value: f.name,
        name: f.displayNameKey || f.name,
    }))
}

export const removeDuplicates = (array) => {
    const seenValues = new Set();
    return (array || []).filter(item => {
        if (seenValues.has(item.value)) {
            return false;
        } else {
            seenValues.add(item.value);
            return true;
        }
    });
}