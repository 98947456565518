import React from 'react';
import SectionsForm from "../../../form/SectionsForm";
import Tools from "./Tools";

const Driver = (props) => {
    return (
        <SectionsForm
            {...props}
            colsCounts={1}
            sectionColCounts={2}
            customColsCounts={{
                acceptPersonalDataSection: 3
            }}
            customObjects={{
                acceptPersonalDataSection:
                    ({style}) => <Tools
                        onAccept={props.getData}
                        driverId={props.form?.id}
                        style={style}
                    />
            }}
        />
    );
};

export default Driver;