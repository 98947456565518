import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import {getJwt, removeJwt} from "../../utils/localeStorageHelper";
import {LOGIN_LINK} from "../../route/links";
import {userAuthActions} from "../slices/userSlice";

const baseQueryFetch = (url) => fetchBaseQuery({
    baseUrl: `/api${url || ''}`,
    prepareHeaders: (headers) => {
        const token = getJwt();
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

export const baseQuery = (url) => async (args, api, extraOptions) => {
    let result = await baseQueryFetch(url)(args, api, extraOptions)
    if (result?.error?.originalStatus === 401 || result?.error?.status === 401) {
        removeJwt();
        api.dispatch(userAuthActions.removeUser());
        if (window.location.pathname !== LOGIN_LINK) {
            window.location.replace(LOGIN_LINK);
        }
    }
    return result
}
