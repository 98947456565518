import React, {useState} from 'react';
import {Button, Dropdown, Form, Grid, Input} from "semantic-ui-react";
import {getLanguage, setLanguage} from "../../utils/localeStorageHelper";
import './login.css'
import logo from '../../img/logo.png'
import {useLoginMutation} from "../../store/api/userApi";
import {useActions} from "../../hooks/actions";
import {useNavigate} from "react-router-dom";
import {HOME_LINK} from "../../route/links";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import {useTranslation} from "react-i18next";
import RequestsModal from "../requests/requester/RequestsModal";

const langOptions = [
    {
        text: "Русский",
        value: "ru",
        flag: "ru"
    },
    {
        text: "English",
        value: "en",
        flag: "gb"
    }
];

const Login = ({viewCreateInvitation}) => {
    const {t, i18n} = useTranslation();

    const {setUser} = useActions();
    const navigate = useNavigate();

    const initialState = {
        login: '',
        password: '',
        language: getLanguage() || langOptions[0].value
    };

    const [form, setForm] = useState(initialState);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [login, {data, isLoading, isError}] = useLoginMutation();

    const setValue = (e, {name, value}) => {
        setForm(form => ({
                ...form,
                [name]: value
            })
        )
    }

    const handleChangeLang = (e, {value}) => {
        setValue(e, {name: 'language', value})
        setLanguage(value)
        i18n.changeLanguage(value);
    }

    const onSubmit = () => {
        !isLoading && login(form)
            .unwrap()
            .then((res) => {
                setUser(res)
                navigate(HOME_LINK)
            })
            .catch((error) => {
                setError(error.data)
            })
    }

    return (
        <>
            <ForgotPasswordModal open={modalOpen} toggleModal={() => setModalOpen(!modalOpen)}/>
            <div className="centered-div">
                <Grid className="login-form-wrapper">
                    <Grid.Row>
                        <Grid.Column className="login-form-description">
                            <div>
                                <img src={logo} title="FM Logistic" alt="FM Logistic"/>
                            </div>
                            <div>
                                <p>{t("login_welcome")}</p>
                                <p>{t("login_support")}: <a href="tel:84957373955">+7(495)737-39-55</a></p>
                            </div>
                        </Grid.Column>
                        <Grid.Column className="login-form-input-wrapper">
                            <Form onSubmit={onSubmit}>
                                <div className="margin-bottom-10">
                                    <Input
                                        onChange={setValue}
                                        name='login'
                                        value={form.login}
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Email'/>
                                </div>
                                <div className="margin-bottom-10">
                                    <Input
                                        autoComplete='on'
                                        type="password"
                                        onChange={setValue}
                                        name='password'
                                        value={form.password}
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder={t("password_placeholder")}/>
                                </div>
                                <div>
                                    <a className="login-form-forgot-pass" onClick={() => setModalOpen(true)}>
                                        {t("forgot_pass")}
                                    </a>
                                    <Button
                                        loading={isLoading}
                                        floated='right'
                                        type='submit'
                                    >
                                        {t("enter_btn")}
                                    </Button>
                                </div>
                                <div className="error">
                                    {error}
                                </div>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className="language-switcher">
                <i className={`${langOptions[(form.language === "ru" ? 0 : 1)].flag} flag`}></i>
                <span>{t("language")}: </span>
                <Dropdown
                    inline
                    options={langOptions}
                    onChange={handleChangeLang}
                    defaultValue={langOptions[(form.language === "ru" ? 0 : 1)].value}
                />
            </div>
            <RequestsModal defaultOpen={viewCreateInvitation} />
        </>
    );
};

export default Login;