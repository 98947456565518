import React from 'react';
import DocWithEditor from "./DocWithEditor";
import {useTranslation} from "react-i18next";
import {useDeleteDocMutation, useSaveDocMutation} from "../../store/api/documentApi";

const DocListEdit = ({documents, onChange, deleteDisabled, type, onSaveForm, objectId, readOnly}) => {
    const {t} = useTranslation();

    const [saveDoc, {isLoading}] = useSaveDocMutation();

    const onDocumentEdit = (document) => {
        const doc = {
            id: document.id,
            fileId: document.fileId,
            fileName: document.fileName,
            documentTypeId: document.documentTypeId,
            mimeType: document.mimeType,
            expiryDate: document.expiryDate
        }

        saveDoc({document: doc, objectId}).then(() => {
            onChange(documents, type);
        })
    };

    const [deleteFile, {data}] = useDeleteDocMutation();

    const onDocumentDelete = (docIndex) => {
        deleteFile({id: documents[docIndex].id})
            .unwrap()
            .then(
                () => {
                    onChange(documents.filter((d, i) => i !== docIndex), type);
                    onSaveForm && onSaveForm();
                }
            );
    };

    return (
        <div className="flex-container">
            {
                (documents.length || !readOnly)
                    ? documents.map((document, index) => (
                        <DocWithEditor
                            key={document.fileId + index}
                            docIndex={index}
                            document={document}
                            onEdit={onDocumentEdit}
                            onDelete={onDocumentDelete}
                            titleText={t("editDocument")}
                            okButtonText={t("saveButton")}
                            type={type}
                            deleteDisabled={readOnly || deleteDisabled}
                            editDisabled={readOnly}
                        />
                    ))
                    : <div className='padding-20'>{t('noData')}</div>
            }
            {!readOnly && <DocWithEditor
                docIndex={documents.length}
                onEdit={onDocumentEdit}
                titleText={t("addDocument")}
                okButtonText={t("addButton")}
                type={type}
                deleteDisabled={deleteDisabled}
            />}
        </div>
    );
};

export default DocListEdit;