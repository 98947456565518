import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";
import {endpoints} from "./gridAndDictionaryEndpoints";

export const gridApi = createApi({
    reducerPath: 'gridApi',
    baseQuery: baseQuery('/grid'),
    tagTypes: ['GridElement'],
    endpoints: (build) => ({
        ...endpoints(build, 'GridElement'),
        getColumns: build.mutation({
            query: ({name, currentPlatformId}) => {
                return {
                    url: `/${name}/getColumns`,
                    method: 'POST',
                    body: {
                        currentPlatformId
                    }
                }
            }
        }),
        getHistoryTable: build.query({
            query: ({name, id, url, data}) => {
                return {
                    url: `/${name}/${url}`,
                    method: 'POST',
                    body: {
                        id,
                        ...data
                    }
                }
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                return endpointName + queryArgs?.id;
            },
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs?.arg?.data?.skip > 0) {
                    currentCache.items.push(...newItems.items)
                    currentCache.totalCount = newItems.totalCount
                    return currentCache;
                } else {
                    return newItems;
                }
            },
            forceRefetch({currentArg, previousArg}) {
                return currentArg !== previousArg
            },
        }),
        changeStatus: build.mutation({
            query: ({name, params, id, status}) => {
                const requestData = {
                    url: `/${name}${!params ? `/${id}` : ''}/${status}`,
                    method: params ? 'POST' : 'GET',
                }
                if (params) requestData.body = params
                return requestData;
            }
        }),
        getCustomColumns: build.mutation({
            query: ({name, platformId, activityId}) => {
                return {
                    url: `/${name}/getCustomColumns`,
                    method: 'POST',
                    body: {
                        platformId,
                        activityId
                    }
                };
            }
        }),
        getActions: build.mutation({
            query: ({name, platformId, activityId, ids}) => {
                return {
                    url: `/${name}/getActions`,
                    method: 'POST',
                    body: {
                        platformId,
                        activityId,
                        ids
                    }
                };
            }
        }),
        loadOrderInfo: build.mutation({
            query: ({name, data}) => {
                return {
                    url: `/${name}/loadOrdersInfo`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        getVisitTasks: build.query({
            query: ({id}) => {
                return {
                    url: `/visitTasks/${id}/getByVisitId`,
                }
            }
        }),
        getOrders: build.query({
            query: ({id}) => {
                return {
                    url: `/orders/${id}/getByTaskId`,
                }
            }
        }),
        getBacklights: build.mutation({
            query: ({name}) => {
                return {
                    url: `/${name}/getBacklights`,
                    method: 'POST',
                    body: []
                }
            }
        })
    })
});

export const {
    useGetBacklightsMutation,
    useGetActionsMutation,
    useGetCustomColumnsMutation,
    useGetColumnsMutation,
    useLazyGetHistoryTableQuery,
    useLazyGetVisitTasksQuery,
    useLazyGetOrdersQuery,
    useChangeStatusMutation,
    useLoadOrderInfoMutation,

    //общие для справочников и гридов:
    useLazyGetListQuery,
    useLazyGetIdsQuery,
    useLazyGetDefaultFormQuery,
    useLazyGetCountersQuery,
    useLazyGetElementQuery,
    useLazyGetElementsByIdsQuery,
    useSaveMutation,
    useDeleteMutation,
    useExportMutation,
    useImportMutation,
    useActionMutation,
    useGetFieldSettingsMutation,
    useLazyGetListForDropdownQuery,
    useEditValueMutation,
    useGetCustomFieldSettingsMutation,
} = gridApi;