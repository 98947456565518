import React, {useEffect, useState} from 'react';
import LoadingSegment from "../../components/LoadingSegment";
import {useTranslation} from "react-i18next";
import {Checkbox, Table} from "semantic-ui-react";
import Search from "../../components/inputs/Search";
import {searchByName} from "../../utils/search";
import {useGetMatrixMutation, useSaveMutation} from "../../store/api/dictionariesSettingsApi";
import {settingNames} from "../../constants/dictionaries";
import {toast} from "react-toastify";


const MatrixToggle = ({name, field, data, form, onSave}) => {
    const [save, {isLoading: loadingSave, isFetching: fetchingSave}] = useSaveMutation();

    const getDataForField = (fieldName) => {
        return data?.find(item => item.name === fieldName);
    }

    const onChangeValue = (dataForField, {name, checked}) => {
        const clearAllButOne = (name) => {
            const data = {};
            settingNames.forEach(n => {
                if (n !== name) {
                    data[n] = false
                }
            });
            return data;
        };

        let data = {
            ...dataForField,
            dictionaryId: form?.dictionaryId,
            roleId: form?.roleId,
            [name]: checked
        }
        if (checked && [settingNames[0], settingNames[3]].includes(name)) {
            data = {
                ...data,
                ...clearAllButOne(name)
            }
        }
        if (checked && ![settingNames[0], settingNames[3]].includes(name)) {
            data = {
                ...data,
                [settingNames[0]]: false,
                [settingNames[3]]: false
            }
        }

        save(data)
            .unwrap()
            .then((res) => {
                if (res.isError) {
                    toast.error(res.message);
                } else {
                    onSave && onSave();
                }
            })
    }

    const dataForField = getDataForField(field.name) || {};

    return <Table.Cell textAlign='center'>
        <Checkbox
            name={name}
            disabled={dataForField.isBlocked || loadingSave || fetchingSave}
            checked={dataForField?.[name]}
            toggle
            onChange={(e, data) => onChangeValue(dataForField, data)}
        />
    </Table.Cell>
}

const DictionariesSettingTable = ({form, onChange}) => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const fields = form?.dictionaryId?.data?.filter(f => !f.isSettingHidden) || [];
    const [getMatrix, {data, isLoading, isFetching}] = useGetMatrixMutation();

    const getData = () => {
        form?.dictionaryId && getMatrix({
            dictionaryId: form.dictionaryId.value,
            roleId: form.roleId?.value,
        })
    }

    useEffect(() => {
        getData();
    }, [form?.dictionaryId, form?.roleId]);

    const onSave = () => {
        getData();
        onChange && onChange();
    }

    return (
        <LoadingSegment
            isLoading={isLoading || isFetching}
            className='field-matrix__table'
        >
            <Table celled selectable>
                <Table.Header className='field-matrix__header'>
                    <Table.Row>
                        <Table.HeaderCell className='field-matrix__first-col'>
                            <Search
                                size="mini"
                                isAuto
                                value={searchValue}
                                onChange={(e, {value}) => setSearchValue(value)}
                                placeholder={t('searchByFieldName')}
                            />
                        </Table.HeaderCell>
                        {
                            settingNames?.map(settingName => <Table.HeaderCell
                                key={settingName}
                                textAlign='center'
                            >
                                <div className="status">
                                    {t(settingName)}
                                </div>
                            </Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        searchByName(fields, searchValue)?.map((field, i) => <Table.Row
                                key={`${field.name}_${i}`}
                            >
                                <Table.Cell className='field-matrix__field-name'>
                                    <div>
                                        <b>{t(field.displayNameKey || field.name)}</b>
                                    </div>
                                </Table.Cell>
                                {
                                    settingNames.map(name =>
                                        <MatrixToggle
                                            key={name}
                                            data={data}
                                            form={form}
                                            field={field}
                                            name={name}
                                            onSave={onSave}
                                        />
                                    )
                                }
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </LoadingSegment>
    );
};

export default DictionariesSettingTable;