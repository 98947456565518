import React from "react";
import {Label} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const StatusValue = ({value, stateColors, text}) => {
    const {t} = useTranslation();

    const state = stateColors.find(x => x.value === value),
        color = state ? state.color : "grey";

    return (
        <div className="status">
            <Label className="status-label-middle" color={color} horizontal>{text ? text : t(value)}</Label>
        </div>
    );
};
export default StatusValue;
