import {dictionaries} from "./dictionaries";
import {grids} from "./grids";
import {MULTISELECT, MULTISELECT_WITH_ALL, TIMESLOT} from "./fieldTypes";
import {GATE_REVIEWS_NAME} from "./options";

export const dictionarySettingsByName = (name) => {
    return dictionaries.find(d => d.name === name);
}
export const gridSettingsByName = (name) => {
    return grids.find(d => d.name === name);
}

export const settingsByName = (name, fieldsSettings) => {
    return fieldsSettings?.find(s => s.fieldName?.toUpperCase() === name?.toUpperCase())
}

export const getFiltersForField = (field, form, platformId, globalPlatformId, parentForm) => {
    const filters = {
        ...(field.filter || {}),
    }
    if (parentForm?.pageName === GATE_REVIEWS_NAME) {
        if (['activityId', 'gates', 'companyIds', 'visits'].includes(field.name)) {
            filters.platformId = form.platformId
        }
        if (['companyIds', 'visits'].includes(field.name)) {
            filters.activityId = form.activityId
        }
        if (['visits'].includes(field.name)) {
            filters.visitDate = form.visitDate
        }
    } else {
        if (field.source === 'activities') {
            filters['platformId'] = platformId
                || ([MULTISELECT_WITH_ALL, MULTISELECT].includes(field.type)
                    ? form['platforms']?.map(p => p.value).join('|')
                    : form['platformId']?.value)
                || globalPlatformId
        }
        if (field.type === TIMESLOT) {
            filters.visitDate = form.visitDate;
            filters.activityId = form.activityId?.value;
            filters.taskType = form.type?.value;
            filters.providerId = form.supplierId?.value;
            filters.transportCompanyId = form.carrierId?.value;
            filters.platformId = platformId || globalPlatformId;
            filters.visitTaskId = form.id;
            // filters.gate = form.gateIdPlan?.value; todo: gate
        }
        if (['gateIdPlan', 'gateIdFact'].includes(field.name)) {
            filters.platformId = platformId || form.platformId?.value || globalPlatformId;
        }
        if (['driverId', 'coDriverId'].includes(field.name)) {
            filters.blockedActivities = form.activityIds?.join('|')
            filters.companies = form.companyIds?.join('|')
        }
        if (['trailerId', 'truckId'].includes(field.name)) {
            filters.companies = form.companyIds?.join('|')
        }
        if (field.name === 'ruleType') {
            filters.fieldName = form.fieldName?.name
            filters.applicableToObject = parentForm?.applicableToObject?.value
        }
        if (field.name === 'propertyRuleType') {
            filters.fieldName = form.fieldName?.name
            filters.propertyFieldName = form.propertyFieldName?.name
            filters.applicableToObject = parentForm?.applicableToObject?.value
        }
    }
    return filters;
}

export const eventStopPropagation = (e) => {
    e.stopPropagation();
}