import React, {useEffect, useState} from 'react';
import DocView from "./DocView";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FileUploader from "./FileUploader";
import WebCamUploader from "./WebCamUploader";
import FtpUploader from "./FtpUploader";
import {getLanguage} from "../../utils/localeStorageHelper";
import {dateToString, parseDate} from "../../utils/dateTime";
import DatePicker from "react-datepicker";
import {SELECT, TEXT} from "../../constants/fieldTypes";
import FieldComponent from "../fields";
import {useDeleteDocMutation} from "../../store/api/documentApi";

const DocWithEditor = ({document, deleteDisabled, editDisabled, onEdit, onDelete, okButtonText, titleText, docIndex, type}) => {
    const {t} = useTranslation();

    const [deleteFile, {data}] = useDeleteDocMutation();

    const [modalOpen, setModalOpen] = useState(false);
    const [doc, setDoc] = useState({});

    useEffect(() => {
        setDoc(document || {})
    }, [document, modalOpen])

    const handleDelete = () => {
        onDelete(docIndex)
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = (doNotDelete = false) => {
        if (doc?.fileId && !document && !doNotDelete) {
            deleteFile({id: doc.id});
        }
        setModalOpen(false);
    }

    const addFile = (fileDto) => {
        if (fileDto) {
            const extension = getExtension(fileDto.name);

            setDoc({
                ...doc,
                creationDate: fileDto.creationDate,
                fileId: fileDto.fileId,
                fileName: fileDto.name,
                mimeType: extension
            });
        }
    }

    const getExtension = (currentName) => {
        return currentName.substring(currentName.lastIndexOf("."));
    }

    const getFileName = () => {
        if (!doc || !doc.fileName)
            return "";
        const name = doc.fileName;
        return name.lastIndexOf(".") !== -1 ? name.substring(0, name.lastIndexOf(".")) : "";
    }

    const saveFileName = (e, {value}) => {
        if (!value ||
            !doc ||
            !doc.fileName ||
            doc.fileName.lastIndexOf(".") === -1
        ) return;

        const extension = getExtension(doc.fileName);

        setDoc(doc => ({
            ...doc,
            fileName: value + extension,
            mimeType: extension
        }))
    }

    const handleTypeChange = (e, {value}) => {
        setDoc(doc => ({
            ...doc,
            documentTypeId: value,
            expiryDate: value.hasExpiryDate ? doc.expiryDate : null
        }))
    }

    const handleSave = () => {
        if (doc?.fileId) {
            onEdit(doc, docIndex);
            handleClose(true);
        }
    }

    const showDate = doc.documentTypeId?.hasExpiryDate;

    return (
        <DocView document={document}>
            {document ? (
                <div>
                    {
                        !deleteDisabled && <Icon
                            name="times"
                            className="uploaded-image-delete-button"
                            onClick={handleDelete}
                        />
                    }
                    {
                        !editDisabled && <Icon
                            name="pencil alternate"
                            className="uploaded-image-edit-button"
                            onClick={handleOpen}
                        />
                    }
                </div>
            ) : (
                <Icon name="plus" className="uploaded-image-add-button" onClick={handleOpen}/>
            )}

            <Modal
                size='mini'
                open={modalOpen}
                closeOnEscape
                closeOnDimmerClick={false}
                onClose={handleClose}
            >
                <Modal.Header>{t(titleText)}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <FileUploader document={doc} onChange={addFile}/>
                        <WebCamUploader onChange={addFile}>
                            <Button
                                size="small"
                                floated='right'
                                icon='photo'
                                content='Webcam'
                            />
                        </WebCamUploader>
                        <FtpUploader onChange={addFile}>
                            <Button
                                size="small"
                                floated='right'
                                icon='file'
                                content='Ftp'
                            />
                        </FtpUploader>
                        <FieldComponent
                            type={TEXT}
                            label='documentName'
                            name='uploadFile'
                            placeholder={t("uploadFile")}
                            value={getFileName()}
                            onChange={saveFileName}
                        />
                        <FieldComponent
                            type={SELECT}
                            value={doc.documentTypeId}
                            label={t('type')}
                            name='documentTypeId'
                            dictionaryName='documentTypes'
                            onChange={handleTypeChange}
                            filter={{
                                applicableTo: type
                            }}
                        />
                        {showDate
                            ? <Form.Field>
                                <label>{t("expirationDate")}</label>
                                <DatePicker
                                    locale={getLanguage()}
                                    selected={parseDate(doc.expiryDate)}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(ev) => {
                                        setDoc(doc => ({...doc, expiryDate: dateToString(ev)}))
                                    }}
                                />
                            </Form.Field>
                            : null
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon='ban'
                        content={t("cancelButton")}
                        onClick={handleClose}
                    />
                    <Button
                        disabled={!doc.documentTypeId || !doc.fileId}
                        icon='check'
                        positive
                        content={okButtonText}
                        onClick={handleSave}
                    />
                </Modal.Actions>
            </Modal>
        </DocView>
    );
};

export default DocWithEditor;