import React from 'react';
import {Button, Dropdown, Popup} from "semantic-ui-react";
import {fieldSettingsValues} from "../../constants/options";
import {toast} from "react-toastify";
import {useSaveMutation} from "../../store/api/fieldMatrixApi";
import {useTranslation} from "react-i18next";

const FieldSettingDropdown = ({
                                  field,
                                  settingOptions,
                                  form,
                                  state,
                                  isCustom,
                                  isAll,
                                  entityType,
                                  refresh,
                                  matrixElement
                              }) => {
    const {t} = useTranslation();
    const [save, {isLoading: loadingSave, isFetching: fetchingSave}] = useSaveMutation();

    const getValue = () => {
        if (!matrixElement) {
            return fieldSettingsValues.SHOW
        } else {
            const {canEdit, canView} = matrixElement;

            return canEdit
                ? fieldSettingsValues.EDIT
                : (
                    canView
                        ? fieldSettingsValues.SHOW
                        : fieldSettingsValues.HIDDEN
                );
        }
    }

    const saveSetting = (id, fieldName, canView, canEdit, entityStatus) => {
        const {roleId, platformId, activityId} = form;
        save({
            id,
            roleId: roleId?.value,
            platformId: platformId?.value,
            activityId: activityId?.value,
            entityType,
            entityStatus,
            fieldName,
            isCustom,
            canView,
            canEdit
        })
            .unwrap()
            .then((res) => {
                res.isError && toast.error(t(res.message || 'error'))
                refresh && refresh();
            })
            .catch(e => toast.error(t(e.message || 'error')))
    }

    const onChange = (value, state) => {
        saveSetting(
            matrixElement?.id,
            field.fieldName || field.name,
            value === fieldSettingsValues.SHOW,
            value === fieldSettingsValues.EDIT,
            state
        )
    };

    const disabled = loadingSave || fetchingSave;

    return (
        <Dropdown
            trigger={isAll &&
                <Popup
                    disabled={disabled}
                    trigger={<Button
                        size='mini'
                        loading={disabled}
                        icon='setting'
                    />}
                    content={t("settingForAllStates")}
                />}
            selectOnBlur={false}
            options={settingOptions}
            icon={isAll && null}
            value={isAll ? null : getValue()}
            onChange={(e, {value}) => onChange(value, isAll ? null : state.name)}
            loading={disabled}
            disabled={disabled}
        />
    );
};

export default FieldSettingDropdown;