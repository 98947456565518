import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const historyApi = createApi({
    reducerPath: 'historyApi',
    baseQuery: baseQuery('/histories'),
    endpoints: (build) => ({
        getHistory: build.query({
            query: ({id}) => {
                return {
                    url: `/get?id=${id}`,
                }
            }
        }),
    })
});

export const {
    useLazyGetHistoryQuery,
} = historyApi;