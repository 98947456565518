import React, {useState} from 'react';
import {Button, Card, Popup} from "semantic-ui-react";
import './styles.scss';
import {useTranslation} from "react-i18next";
import {defaultFunc} from "../../utils/stateHelper";

const DataBlock = ({label, canExport, excelFunc = defaultFunc, filters = {}, content, headerBlock}) => {
    const {t} = useTranslation();
    const [fullSize, setFullSize] = useState(false);
    const [getFile, {isLoading: isLoadingFile, isFetching: isFetchingFile}] = excelFunc();

    const exportLoading = isLoadingFile || isFetchingFile;

    const exportToExcel = () => {
        getFile(filters);
    }

    return (
        <Card className={`data-block ${fullSize ? 'data-block__full-size' : ''}`}>
            <Card.Content>
                <Card.Header>
                    <div className='data-block__header'>
                        {t(label)}
                        {headerBlock && headerBlock}
                    </div>
                    <div>
                        <Popup
                            content={t(fullSize ? 'miniSize' : 'fullSize')}
                            position='bottom left'
                            trigger={
                                <Button
                                    onClick={() => setFullSize(!fullSize)}
                                    icon={fullSize ? 'compress' : 'expand'}
                                />
                            }
                        />
                        {canExport && <Popup
                            content={t('exportToExcel')}
                            position='bottom left'
                            trigger={
                                <Button
                                    loading={exportLoading}
                                    disabled={exportLoading}
                                    onClick={exportToExcel}
                                    icon='download'
                                />
                            }
                        />}
                    </div>
                </Card.Header>
                {content}
            </Card.Content>
        </Card>
    );
};

export default DataBlock;