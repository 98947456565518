import React, {useState, useEffect} from "react";
import NewRequest from "./NewRequest";
import PreviousRequests from "./PreviousRequests";
import {Button, Segment, Tab} from "semantic-ui-react";
import {toast} from "react-toastify";
import {GoogleLogin, googleLogout, GoogleOAuthProvider} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import {useTranslation} from "react-i18next";
import ControlledModal from "../../../components/modals/ControlledModal";
import {parseDate} from "../../../utils/dateTime";
import LoadingSegment from "../../../components/LoadingSegment";
import {
    useCreateViaGoogleMutation,
    useLazyGoogleApiKeyQuery,
    useReloadPreviousMutation,
    useDeleteRequestMutation
} from "../../../store/api/dictionaryApi";

export default function RequestsModal({defaultOpen = false}) {

    const initialState = {
        requesterPhoneNumber: "",
        truckNumber: "",
        purpose: "",
        platforms: [],
        visitorNames: [""]
    };

    const [gmailInfo, setGmailInfo] = useState(null);
    const [data, setData] = useState(initialState);
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState(null);
    const [googleKey, setGoogleKey] = useState("");
    const [googleTokenId, setGoogleTokenId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const initScrollContainer = React.createRef();

    const [getGoogleApiKey, {data: gkData, isLoading: gkIsLoading}] = useLazyGoogleApiKeyQuery();
    const [reloadPrevious, {isLoading: rpIsLoading}] = useReloadPreviousMutation();
    const [create, {isLoading: crIsLoading}] = useCreateViaGoogleMutation();
    const [remove, { isLoading: drIsLoading}] = useDeleteRequestMutation();

    useEffect(() => {
        getGoogleApiKey();
        setGmailInfo(null);
    }, []);

    useEffect(() => {
        gkData && setGoogleKey(gkData.key);
    }, [gkData])

    const onClose = () => {
        setGmailInfo(null);
    };

    const reloadPreviousRequests = (email, tokenId, callback) => {
        reloadPrevious({email, tokenId})
            .unwrap()
            .then(res => {
                if (res.isError) {
                    if (res.error || res.message) {
                        setError(res.error || res.message);
                    }
                } else {
                    callback && callback();
                    setRequests(res?.result);
                    setError(null)
                }
            })
    };

    const createRequest = () => {
        setIsLoading(true);
        return create({data: data, tokenId: googleTokenId})
    };

    const onSaveThen = (res) => {
        setIsLoading(false);

        if (res.isError) {
            setError(res.error);
            return null;
        } else {
            toast.info(t("visitRequestSent"));
            reloadPreviousRequests(data.requesterEmail, googleTokenId);
            setError(null);

            // Сбрасываем поля; из предыдущего состояния сохраняем только name и email
            const newState = initialState;
            newState.requesterName = data.requesterName;
            newState.requesterEmail = data.requesterEmail;
            setData(newState);
            return data;
        }
    }

    const onSaveCatch = (e) => {
        toast.error(t(e?.message || 'error'))
    }

    const deleteRequest = (id) => {
        setIsLoading(true);
        remove({data: {id: id, email: data.requesterEmail}, tokenId: googleTokenId})
            .unwrap()
            .then(res => {
                setIsLoading(false);

                if (res.error) {
                    setError(res.error);
                } else {
                    reloadPreviousRequests(data.requesterEmail, googleTokenId);
                    setError(null);
                }
            })
    };

    const handleChange = (e, {name, value}) => {
        setData({...data, [name]: value});
    };

    const handleLogin = (info) => {
        const jwtDecode = jwt_decode(info.credential);
        const email = jwtDecode.email;


        setData({
            ...data,
            requesterName: jwtDecode.name,
            requesterEmail: email
        });

        setGoogleTokenId(info.credential);
        reloadPreviousRequests(email, info.credential, () => {
            setGmailInfo(info);
        });

        setError(null);
    };

    const handleLogout = () => {
        googleLogout();
        setGmailInfo(null);
    };

    const isNewRequestValid = () => {
        // Не заполнено одно из полей,
        if (!gmailInfo || !data.requesterName || !data.startDate || !data.finishDate || !data.purpose || !data.visitorCompany) {
            return false;
        }

        // Не задано ни одной платформы при отжатом флажке "Все платформы"
        if (data.platforms != null && data.platforms.length <= 0) {
            return false;
        }

        // Не задано ни одного посетителя
        if (data.visitorNames.length <= 0) {
            return false;
        }

        // Имя одного из посетителей не задано
        if (data.visitorNames.some(value => !value || !value.trim())) {
            return false;
        }

        // Проверяем, что даты валидны
        let parsedFinishDate = parseDate(data.finishDate);
        let parsedStartDate = parseDate(data.startDate);
        if (!parsedStartDate || !parsedFinishDate) {
            return false;
        }

        return parsedStartDate <= parsedFinishDate;
    };

    const {t} = useTranslation();

    return (
        <ControlledModal
            title={t("createRequest")}
            buttonClassName="send-request"
            buttonSize="small"
            size="large"
            buttonName={t("createRequest")}
            saveButtonName={t("sendButton")}
            saveButtonDisabled={!isNewRequestValid()}
            onClose={onClose}
            onSave={createRequest}
            onSaveThen={onSaveThen}
            onSaveCatch={onSaveCatch}
            isConfirmForCloseModal={gmailInfo}
            textForCloseModal={t("youWillBeLoggedOutAreYouSureToCloseTheWindow")}
            textForCloseModalCancelButton={t("close")}
            error={error}
            defaultOpen={defaultOpen}
            isScrolling
            isLoading={isLoading}
            withoutCancel
        >
            <LoadingSegment
                isLoading={gkIsLoading || rpIsLoading || crIsLoading || drIsLoading}
            >
                {googleKey && <GoogleOAuthProvider
                    clientId={googleKey}
                >
                    {!gmailInfo
                        ? <div style={{textAlign: "center", padding: 60}}>
                            <p>{t("pleaseLoginToGoogleAccount")}</p>
                            <div className='jc-c'>
                                <GoogleLogin
                                    onSuccess={handleLogin}
                                    cookiePolicy="single_host_origin"
                                />
                            </div>
                        </div>
                        : <>
                            <div style={{textAlign: "right"}}>
                                <Button inverted color='red' onClick={handleLogout}>
                                    {t("logout")}
                                </Button>
                            </div>

                            <Tab style={{marginTop: "-30px"}} panes={[
                                {
                                    menuItem: t("requestNewTab"),
                                    key: "1",
                                    render: () => {
                                        return (
                                            <Tab.Pane className="init-scroll">
                                                <div ref={initScrollContainer}>
                                                    <NewRequest
                                                        request={data}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                            </Tab.Pane>);
                                    }
                                },
                                {
                                    menuItem: t("requestPreviousRequestTab") + ` (${requests.length})`,
                                    key: "2",
                                    render: () => {
                                        return (<Tab.Pane className="init-scroll fixed-height">
                                            <div>
                                                <Segment vertical textAlign="center">
                                                    <b>{t("requestPreviousRequestsNotification")}</b>
                                                </Segment>

                                                <PreviousRequests
                                                    requests={requests}
                                                    deleteRequest={deleteRequest}
                                                />
                                            </div>
                                        </Tab.Pane>);
                                    }
                                }]}/>
                        </>
                    }
                </GoogleOAuthProvider>}
            </LoadingSegment>
        </ControlledModal>
    );
}