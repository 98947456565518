import React, {useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import {dateFormat} from "../../utils/dateTime";
import {useLazyGetHistoryQuery} from "../../store/api/historyApi";
import LoadingSegment from "../LoadingSegment";
import './style.scss'
import {useTranslation} from "react-i18next";

const History = ({items: initItems, isLocalDate, id}) => {
    const {t} = useTranslation();
    const [items, setItems] = useState(initItems);
    const [getHistory, {isLoading, isFetching}] = useLazyGetHistoryQuery();

    useEffect(() => {
        id
            ? getHistory({id})
                .unwrap()
                .then((data) => {
                    setItems(data)
                })
            : setItems(initItems)
    }, [id, initItems]);

    return (<LoadingSegment isLoading={isLoading || isFetching}>
        <Grid className='history-block'>
            {
                (items || []).map((historyItem, i) => <Grid.Row key={i}>
                    <Grid.Column width={5}>
                        <div>{isLocalDate ? dateFormat(historyItem.createdAt) : historyItem.createdAt}</div>
                        <div className="history-who">{historyItem.who}</div>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <div className="history-comment">
                            {historyItem.text}
                        </div>
                    </Grid.Column>
                </Grid.Row>)
            }
            {
                (!items?.length && !isLoading && !isFetching) &&
                <div className='history-block__no-data'>{t('noData')}</div>
            }
        </Grid>
    </LoadingSegment>);
};
export default History;