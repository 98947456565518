import React from 'react';
import {Tab} from "semantic-ui-react";
import {viewPanes} from "../../../../utils/stateHelper";
import {useTranslation} from "react-i18next";
import History from "../../../history/History";
import Information from "./Information";
import Docs from "./Docs";
import '../../style.scss'

const VisitTab = ({ isCreate, id, form, setValue, errors, customFields, name, colsCounts, getFieldSettings, readOnly, getCustomCols, getCustomFieldSettings }) => {
    const {t} = useTranslation();

    const panes = [
        {
            view: true,
            menuItem: t('information'),
            render: () => <Tab.Pane className='info'>
                <Information
                    isCreate={isCreate}
                    name={name}
                    form={form}
                    setValue={setValue}
                    errors={errors}
                    colsCounts={colsCounts}
                    getFieldSettings={getFieldSettings}
                    readOnly={readOnly}
                    getCustomFieldSettings={getCustomFieldSettings}
                    getCustomCols={getCustomCols}
                />
            </Tab.Pane>
        },
        {
            view: true,
            menuItem: t('documents'),
            render: () => <Tab.Pane>
                <Docs
                    form={form}
                    readOnly={readOnly}
                />
            </Tab.Pane>
        },
        {
            view: true,
            menuItem: t('history'),
            render: () => <Tab.Pane>
                <History id={id} isLocalDate/>
            </Tab.Pane>
        },
    ]
    return (
        <Tab
            className='modal-tabs'
            panes={viewPanes(panes)}
            menu={{ pointing: true }}
        />
    );
};

export default VisitTab;