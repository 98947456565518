import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const fieldMatrixApi = createApi({
    reducerPath: 'fieldMatrixApi',
    baseQuery: baseQuery('/fieldMatrix'),
    endpoints: (build) => ({
        getMatrix: build.mutation({
            query: ({fieldName, roleId, platformId, activityId, entityType}) => {
                return {
                    url: `/getFields`,
                    method: 'POST',
                    body: {
                        fieldName,
                        roleId,
                        platformId,
                        activityId,
                        entityType
                    }
                }
            }
        }),
        getFields: build.mutation({
            query: ({fieldName, roleId, platformId, activityId, entityType}) => {
                return {
                    url: `/getCustomFields`,
                    method: 'POST',
                    body: {
                        fieldName,
                        roleId,
                        platformId,
                        activityId,
                        entityType
                    }
                }
            }
        }),
        getStates: build.query({
            query: ({entityType}) => {
                return {
                    url: `/getStates/${entityType}`,
                }
            }
        }),
        save: build.mutation({
            query: ({
                        id,
                        roleId,
                        platformId,
                        activityId,
                        entityType,
                        entityStatus,
                        fieldName,
                        isCustom,
                        canView,
                        canEdit
                    }) => {
                return {
                    url: `/save`,
                    method: 'POST',
                    body: {
                        id,
                        roleId,
                        platformId,
                        activityId,
                        entityType,
                        entityStatus,
                        fieldName,
                        isCustom,
                        canView,
                        canEdit
                    }
                }
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                return endpointName + queryArgs.fieldName + queryArgs.id;
            },
        }),
    })
});

export const {
    useGetMatrixMutation,
    useLazyGetStatesQuery,
    useGetFieldsMutation,
    useSaveMutation,
} = fieldMatrixApi;