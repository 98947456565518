import React, {useState, useEffect} from "react";
import {
    Form,
    Modal,
    TextArea,
    Button,
    Radio,
    Message,
} from "semantic-ui-react";
import LoadingSegment from "../../components/LoadingSegment";
import {useTranslation} from "react-i18next";
import {useChangeStatusMutation} from "../../store/api/gridApi";
import {securityServiceGridName} from "../../constants/security";
import {MULTISELECT, ENUM, SELECT} from "../../constants/fieldTypes";
import FieldComponent from "../../components/fields";

const SecurityDecisionModal = ({
                                   children,
                                   caseId,
                                   driverId,
                                   title,
                                   onClose,
                                   isReject,
                                   isReturn,
                                   isConfirm,
                                   isReturnToWork,
                                   driverState,
                                   truckState,
                                   trailerState
                               }) => {
    const {t} = useTranslation();

    const [openModal, setOpenModal] = useState(false);
    const [comment, setComment] = useState("");
    const [rejectType, setRejectType] = useState(isReject ? "fullReject" : null);
    const [activities, setActivities] = useState([]);
    const [reasonId, setReasonId] = useState(null);
    const [errorText, setErrorText] = useState("");

    const [changeStatus, {isLoading}] = useChangeStatusMutation();

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setErrorText(null);
        setOpenModal(false);
    };

    const handleChangeRejectType = (e, {value}) => {
        setRejectType(value);
    };

    const changeCaseStatus = (status, params, id) => {
        return changeStatus({
            name: securityServiceGridName,
            params,
            status,
            id
        })
            .unwrap()
            .then(data => handleCaseApiResponse(data))
    }

    const handleReject = () => {
        let params = {
            id: caseId,
            comment: comment,
            driverState: driverState,
            truckState: truckState,
            trailerState: trailerState
        };

        if (rejectType === "partialReject") {
            params = {
                ...params,
                activitiesIds: activities?.map(a => a.value)
            };
        }

        if (rejectType !== "temporaryReject") {
            params = {
                ...params,
                reason: reasonId
            };
        }

        return changeCaseStatus(rejectType, params)
    };

    const handleReturn = () => {
        return changeCaseStatus('return', {
            id: caseId,
            reason: reasonId,
            comment: comment,
            driverState: driverState,
            truckState: truckState,
            trailerState: trailerState
        })
    };

    const handleConfirm = () => {
        return changeCaseStatus('confirm', null, caseId)
    };

    const handleReturnToWork = () => {
        return changeCaseStatus('returnToWork', null, caseId)
    };

    const handleCaseApiResponse = (data) => {
        if (data.isError) {
            setErrorText(data.message);
        } else {
            handleClose();
            onClose(true);
        }

        return data;
    };

    useEffect(
        () => {
            setReasonId(null);
            setComment("");
        },
        [rejectType]
    );

    const renderRejectContent = () => {
        const radiobuttons = [
            {
                label: 'completeRejectionBlacklist',
                value: 'fullReject'
            },
            {
                label: 'partialFailureForActivity',
                value: 'partialReject'
            },
            {
                label: 'temporaryRejected',
                value: 'temporaryReject'
            },
        ]

        return (
            <>
                {
                    radiobuttons.map(radiobtn => <Form.Field key={radiobtn.value}>
                        <Radio
                            key={radiobtn.value}
                            label={t(radiobtn.label)}
                            name="rejectType"
                            value={radiobtn.value}
                            checked={rejectType === radiobtn.value}
                            onChange={handleChangeRejectType}
                        />
                    </Form.Field>)
                }

                {rejectType === "partialReject" && (
                    <Form.Field>
                        <FieldComponent
                            type={MULTISELECT}
                            multiple
                            value={activities}
                            noLabel
                            placeholder={t('activities')}
                            dictionaryName='activities'
                            onChange={(e, {value}) => {
                                setActivities(value)
                            }}
                            filter={{
                                driverId
                            }}
                        />
                    </Form.Field>
                )}
            </>
        );
    };

    const renderReasonWithComment = () => {
        return (
            <>
                {rejectType !== "temporaryReject" && (
                    <Form.Field>
                        <FieldComponent
                            type={SELECT}
                            source="securityRejectReason"
                            name='resultReason'
                            value={reasonId}
                            onChange={(e, {value}) => setReasonId(value)}
                            placeholder={isReject ? t('rejectionReason') : t('reasonForReturn')}
                        />
                    </Form.Field>
                )}
                <Form.Field>
                    <TextArea
                        rows={2}
                        placeholder={t('yourComment')}
                        onChange={(e, {value}) => setComment(value)}
                    />
                </Form.Field>
            </>
        );
    };

    const renderConfirmContent = () => {
        return (<span>{t('areYouSureYouWantToConfirm')}</span>);
    };

    const renderReturnToWorkContent = () => {
        return (<span>{t('areYouSureYouWantToReturnToWork')}</span>);
    };

    return (
        <Modal
            dimmer="blurring"
            trigger={children}
            open={openModal}
            closeIcon
            closeOnEscape
            closeOnDimmerClick={false}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <LoadingSegment isLoading={isLoading}>
                    <Form>
                        {isReject && renderRejectContent()}
                        {(isReject || isReturn) && renderReasonWithComment()}
                        {isConfirm && renderConfirmContent()}
                        {isReturnToWork && renderReturnToWorkContent()}
                    </Form>
                    {errorText ? <Message error content={errorText}/> : null}
                </LoadingSegment>

            </Modal.Content>
            <Modal.Actions>
                {
                    isReject && (
                        <Button
                            negative
                            onClick={handleReject}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {t('rejected')}
                        </Button>
                    )
                }
                {
                    isReturn && (
                        <Button
                            color="yellow"
                            onClick={handleReturn}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {t('returnToInitiator')}
                        </Button>
                    )
                }
                {
                    (isConfirm || isReturnToWork) && <>
                        <Button
                            color="grey"
                            onClick={handleClose}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {t('no')}
                        </Button>
                        <Button
                            color="blue"
                            onClick={isConfirm ? handleConfirm : handleReturnToWork}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            {t('yes')}
                        </Button>
                    </>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default SecurityDecisionModal;