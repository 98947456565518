import React, {useEffect, useState} from 'react';
import {
    useDeleteMutation,
    useLazyGetDefaultFormQuery,
    useLazyGetVisitTasksQuery
} from "../../../../store/api/gridApi";
import LoadingSegment from "../../../LoadingSegment";
import {Button, Tab} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {dictionaryOrGridEditPermission} from "../../../../store/slices/userSlice";
import {useTranslation} from "react-i18next";
import Task from "./Task";
import {cloneDeep} from "lodash";
import DeleteBlock from "../../../deleteBtn/deleteBlock";

const TasksTab = ({
                      isCreate,
                      id,
                      taskId,
                      form,
                      errors,
                      setValue,
                      name = 'tasks',
                      closeModal,
                      setIgnoreChanges,
                      getFieldSettings,
                      getCustomFieldSettings,
                      orderIds,
                      newTaskIdForOrders,
                      setNewTaskIdForOrders,
                      getCustomCols,
                      readOnly
                  }) => {
    const {t} = useTranslation();

    const gridName = 'visitTasks'
    const visitTaskName = 'VisitTask'

    const [activeIndex, setActiveIndex] = useState(0);

    const [getTasks, {isLoading, isFetching}] = useLazyGetVisitTasksQuery();

    const [getDefaultData, {isLoading: defaultIsLoading, isFetching: defaultIsFetching}] = useLazyGetDefaultFormQuery();

    const [deleteTask] = useDeleteMutation();

    const canEdit = useSelector(state => dictionaryOrGridEditPermission(state, gridName)) && !readOnly;

    const tasks = form && (form[name] || []);

    const refresh = () => {
        getTasks({id})
            .unwrap()
            .then(res => {
                setValue(null, {
                    name,
                    value: res
                });

                // нужно для того, чтобы не считало подгрузку задач за изменение
                // и не выводило подтверждение при закрытии, если пользователь ничего не менял:
                setIgnoreChanges(i => ({...i, [name]: res}));

                if (taskId) {
                    let index = res.findIndex(x => x.id === taskId);
                    index > -1 && setActiveIndex(index);
                }
            })
    }

    useEffect(() => {
        if (id) {
            !(form && form[name]) && refresh()
        }
    }, [id]);

    const setTaskValue = (id, fieldName, value) => {
        const index = tasks.findIndex(task => task.id === id);

        if (index !== -1) {
            const newTasks = [...tasks];
            const newTask = {
                ...newTasks[index],
                [fieldName]: value
            }

            if (fieldName === 'timeslot') {
                newTask.isTimeslotChanged = true;
            }

            newTasks[index] = newTask;

            setValue(null, {
                name,
                value: newTasks
            }, {
                index,
                objectName: visitTaskName,
                fieldName
            })
        }
    }

    const setIgnore = (id, orders) => setIgnoreChanges(i => {
        const res = cloneDeep(i);
        if (res.tasks && orders) {
            const index = res.tasks.findIndex(t => t.id === id);
            if (index >= 0) {
                res.tasks[index] = {...res.tasks[index], orders}
            }
        }
        return res;
    });

    const panes = tasks.map((task, i) => ({
        menuItem: {
            key: i,
            content: <div className='d-flex flex-container-justify'>
                {`${task.number || t('newTask')} (${t(task.type?.name)}/${t(task.status?.name)})`}
                <DeleteBlock
                    deleteConfirmText='deleteTaskConfirm'
                    onDelete={() => {
                        deleteTask({name: gridName, ids: [task.id]}).then(() => {
                            setValue(null, {
                                name,
                                value: tasks.filter(t => t.id !== task.id)
                            })
                            setActiveIndex(0);
                        })
                    }}
                />
            </div>
        },
        render: () => {
            return <Task
                isCreate={isCreate}
                getCustomCols={getCustomCols}
                orderIds={([taskId, newTaskIdForOrders].includes(task.id)) && orderIds}
                setIgnoreChanges={setIgnore}
                setValue={setTaskValue}
                errors={errors && errors[visitTaskName] && (errors[visitTaskName][i] || {})}
                gridName={gridName}
                task={task}
                form={form}
                closeModal={closeModal}
                getFieldSettings={getFieldSettings}
                readOnly={readOnly}
                getCustomFieldSettings={getCustomFieldSettings}
            />
        }
    })) || [];

    useEffect(() => {
        //добавление новой задачи для добавления в нее заказов из списка orderIds
        if (!taskId && !newTaskIdForOrders && form[name] && orderIds?.length) {
            addTask(null, true)
        }
    }, [orderIds, form[name]]);

    const addTask = (e, isNewByOrders) => {
        e?.stopPropagation();
        getDefaultData({
            name: gridName
        })
            .unwrap()
            .then((res) => {
                setValue(null, {
                    name,
                    value: [...tasks, res]
                })
                isNewByOrders && setNewTaskIdForOrders(res.id)
                setActiveIndex(tasks.length);
            })
    }

    canEdit && panes.push({
        menuItem: {
            className: "add-button-tab-item",
            key: "new",
            content: <Button
                className="add-task-button"
                size='small'
                onClick={(e) => addTask(e)}
            >
                {t("addTask")}
            </Button>
        }
    })

    const loading = isLoading || isFetching || defaultIsFetching || defaultIsLoading;

    return (
        <LoadingSegment isLoading={loading}>
            <Tab
                activeIndex={activeIndex}
                onTabChange={(e, {activeIndex}) => setActiveIndex(activeIndex)}
                panes={panes}
                menu={{fluid: true, vertical: true}}
                menuPosition='left'
            />
        </LoadingSegment>
    );
};

export default TasksTab;