import React, {useRef, useState} from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";
import {useUploadBase64FileMutation} from "../../store/api/fileApi";
import {useTranslation} from "react-i18next";
import Webcam from "react-webcam";

const modeEnum = {
    webcam: 'webcam',
    image: 'image'
};

const defaultName = 'WebCamPhoto.jpg';


const WebCamUploader = ({children, onChange}) => {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);
    const [mode, setMode] = useState(modeEnum.webcam);
    const [imageSrc, setImageSrc] = useState('');

    const handleOpen = () => {
        setMode(modeEnum.webcam)
        setModalOpen(true)
    }
    const handleClose = () => {
        setModalOpen(false)
    }

    const ref = useRef();

    const takePhoto = () => {
        const imageSrc = ref.current.getScreenshot();
        setMode(modeEnum.image)
        setImageSrc(imageSrc)
    }

    const rephoto = () => {
        setMode(modeEnum.webcam)
        setImageSrc("")
    }

    const [uploadApi, {isLoading: loading}] = useUploadBase64FileMutation(); //todo

    const save = () => {
        uploadApi({
            base64: imageSrc,
            fileName: defaultName
        })
            .unwrap()
            .then(fileDto => {
                onChange(fileDto);
                setImageSrc('');
                handleClose();
            })
    }

    return (
        <Modal
            trigger={children}
            onOpen={handleOpen}
            open={modalOpen}
            closeOnEscape
            closeOnDimmerClick={false}
            onClose={handleClose}
        >
            <Modal.Header>{t('createAPhoto')}</Modal.Header>
            <Modal.Content>
                {
                    mode === modeEnum.image
                        ? <img src={imageSrc} alt={'file'}/>
                        : <Webcam ref={ref}/>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={handleClose}>
                    <Icon name='ban'/> {t('cancelButton')}
                </Button>
                {
                    mode === modeEnum.webcam && <Button color='green' onClick={takePhoto}>
                        <Icon name='photo'/> {t('photo')}
                    </Button>
                }
                {
                    mode === modeEnum.image && <>
                        <Button color='orange' onClick={rephoto}>
                            <Icon name='photo'/> {t('rephoto')}
                        </Button>
                        <Button color='green' onClick={save}>
                            <Icon name='save'/> {t('takeIt')}
                        </Button>
                    </>
                }
            </Modal.Actions>
        </Modal>
    );
};

export default WebCamUploader;