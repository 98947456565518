import React, { useEffect, useRef, useState } from 'react';
import LoadingSegment from "../LoadingSegment";

const InfiniteScroll = ({
                            children,
                            className = '',
                            loadNext,
                            loading,
                            id,
                            innerRef,
                            as,
                        }) => {
    const lastElementRef = useRef(null);
    const observerRef = useRef(null);
    const [isLoadingNext, setIsLoadingNext] = useState(false);

    useEffect(() => {
        const el = document.getElementById('infinity-scroll_' + id);

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !isLoadingNext && !loading) {
                    setIsLoadingNext(true);
                    loadNext();
                }
            });
        };

        if (loadNext && id && lastElementRef.current) {
            observerRef.current = new IntersectionObserver(observerCallback, {
                root: el,
                threshold: 1,
                rootMargin: '30px',
            });

            observerRef.current.observe(lastElementRef.current);

            return () => {
                if (observerRef.current) {
                    observerRef.current.disconnect();
                }
            };
        }
    }, [loadNext, id, isLoadingNext, loading]);

    useEffect(() => {
        if (!loading) {
            setIsLoadingNext(false);
        }
    }, [loading]);

    return (
        <LoadingSegment
            as={as}
            ref={innerRef}
            id={'infinity-scroll_' + id}
            className={`scroll-block ${className}`}
            isLoading={loading}
        >
            {children}
            <div ref={lastElementRef} id={'last-element' + id} />
        </LoadingSegment>
    );
};

export default InfiniteScroll;

