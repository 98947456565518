import React from 'react';
import {Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import Dictionary from "./Dictionary";

const DictionariesTabs = ({name, dictionaries}) => {
    const {t} = useTranslation();

    return (
        <div className='table-block'>
            <h2>{t(name)}</h2>
            <Tab
                panes={dictionaries.map(dictionary => ({
                    menuItem: t(dictionary),
                    key: dictionary,
                    render: () => <Tab.Pane>
                        <Dictionary isTab dictionaryName={dictionary}/>
                    </Tab.Pane>
                }))}
            />
        </div>
    );
};

export default DictionariesTabs;