import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const dictionariesSettingsApi = createApi({
    reducerPath: 'dictionariesSettingsApi',
    baseQuery: baseQuery('/dictionariesSettings'),
    endpoints: (build) => ({
        getMatrix: build.mutation({
            query: ({roleId, dictionaryId}) => {
                return {
                    url: `/getFields`,
                    method: 'POST',
                    body: {
                        roleId,
                        dictionaryId
                    }
                }
            }
        }),
        save: build.mutation({
            query: ({
                        id,
                        dictionaryId,
                        roleId,
                        name,
                        isHidden,
                        isRequired,
                        isUnique,
                        isReadOnly,
                        isBlocked
                    }) => {
                return {
                    url: `/save`,
                    method: 'POST',
                    body: {
                        id,
                        dictionaryId,
                        roleId,
                        name,
                        isHidden,
                        isRequired,
                        isUnique,
                        isReadOnly,
                        isBlocked
                    }
                }
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                return endpointName + queryArgs.fieldName + queryArgs.id;
            },
        }),
    })
});

export const {
    useGetMatrixMutation,
    useSaveMutation,
} = dictionariesSettingsApi;