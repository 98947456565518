import React from 'react';
import {Navigate} from "react-router-dom"
import {isAuthSelector} from "../store/slices/userSlice";
import {useSelector} from "react-redux";

export const UnauthRoute = ({ children, authPage }) => {
    const isAuth = useSelector(isAuthSelector);

    if (isAuth) {
        return <Navigate to={authPage} replace />;
    }

    return children;
};