import React, {useEffect} from 'react';
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import DocsListViewCompact from "../../../components/documents/DocsListViewCompact";
import {useLazyGetElementQuery} from "../../../store/api/dictionaryApi";
import LoadingSegment from "../../../components/LoadingSegment";
import {useLazyGetDocsQuery} from "../../../store/api/documentApi";
import {securityServiceGridName} from "../../../constants/security";
import {ENUM, TEXT, TEXTAREA} from "../../../constants/fieldTypes";
import FieldComponent from "../../../components/fields";

const DriverInfo = ({form, isView, isStateEdit, setValue, fieldSettings}) => {
    const {t} = useTranslation();

    const [getById, {data: driverInfo = {}, isLoading, isFetching}] = useLazyGetElementQuery();
    const [getDocsById, {data: documents = [], isLoading: docsIsLoading}] = useLazyGetDocsQuery();

    useEffect(() => {
        if (form.driverId) {
            getById({name: 'drivers', id: form.driverId.value});
            getDocsById({id: form.driverId.value})
        }
    }, [form.driverId])

    return (<LoadingSegment
            className='form-block'
            isLoading={isLoading || docsIsLoading || isFetching}
        >
            <Segment>
                <Grid columns="equal" className="margin-bottom-10">
                    <Grid.Row columns="equal">
                        <Grid.Column width={4}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('secondName')} *`}
                                value={driverInfo.secondName}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('firstName')} *`}
                                value={driverInfo.firstName}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={t('patronymicName')}
                                value={driverInfo.patronymicName}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0 padding-top-0"
                    >
                        <Grid.Column>{t('passportDetails')}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('passportSeriesAndNumber')} *`}
                                width={6}
                                value={driverInfo.passportSeriesAndNumber}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('inn')}`}
                                value={driverInfo.inn}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('snils')}`}
                                value={driverInfo.snils}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column>{t('wuData')}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={t('driverLicenseSeries')}
                                width={6}
                                value={driverInfo.driverLicenseSeries}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={t('expirationDateWu')}
                                width={10}
                                value={driverInfo.driverLicenseExpiryDate}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                isDisabled
                                name={`${t('phone')} *`}
                                value={driverInfo.phone}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            {isView && <FieldComponent
                                type={ENUM}
                                name='driverStatus'
                                value={form.driverStatus}
                                onChange={setValue}
                                gridName={securityServiceGridName}
                                isDisabled={!isStateEdit}
                                label='securityCheckState'
                                isRequired
                                fieldSettings={fieldSettings}
                            />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        columns="equal"
                        className="padding-bottom-0"
                    >
                        <Grid.Column className="driver-info__document" width={8}>
                            <DocsListViewCompact
                                documents={documents || []}
                                viewIds={form.driverDocuments}
                            />
                        </Grid.Column>
                        <Grid.Column className="driver-info__document" width={8}>
                            <FieldComponent
                                type={TEXTAREA}
                                isDisabled
                                name={t('driverNotes')}
                                value={form.driverNotes}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </LoadingSegment>
    );
};

export default DriverInfo;