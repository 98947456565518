import React, {useEffect} from 'react';
import {Checkbox} from "semantic-ui-react";
import {useGetBacklightsMutation} from "../../../store/api/gridApi";
import LoadingSegment from "../../LoadingSegment";
import {getContrastColor} from "../../../utils/styleForFields";
import {useTranslation} from "react-i18next";


const Backlight = ({label, color, checked, onClick}) => {
    return <div
        style={{
            backgroundColor: color,
            color: getContrastColor(color)
        }}
        className='backlights__checkbox'
        onClick={onClick}
    >
        <Checkbox
            checked={checked}
        />
        <div>{label}</div>
    </div>
}

const Backlights = ({gridName, backlights, setBacklights, backlightsIsFilter, setBacklightsIsFilter}) => {
    const {t} = useTranslation();
    const [getBacklights, {data, isLoading, isFetching}] = useGetBacklightsMutation();

    useEffect(() => {
        getBacklights({
            name: gridName
        })
    }, [gridName])


    const backlightIsOn = (backlight) => {
        return backlights.find(b => b.id === backlight.id);
    }

    const onClickBacklight = (backlight) => {
        setBacklights(backlightIsOn(backlight) ? backlights.filter(b => b.id !== backlight.id) : [...(backlights || []), backlight])
    }

    return (
        <LoadingSegment
            size='mini'
            className='backlights'
            isLoading={isLoading || isFetching}
        >
            {!!data?.length && <Checkbox
                className='backlights__toggle'
                checked={backlightsIsFilter}
                toggle
                label={t('backlightsFilter')}
                onChange={(e, {checked}) => setBacklightsIsFilter(checked)}
            />}
            {
                data?.map(backlight => {
                    return <Backlight
                        checked={!!backlightIsOn(backlight)}
                        onClick={() => onClickBacklight(backlight)}
                        key={backlight.id}
                        color={backlight.color}
                        label={backlight.name}
                    />
                })
            }
        </LoadingSegment>
    );
};

export default Backlights;