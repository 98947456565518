import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Form, Popup} from "semantic-ui-react";
import './style.scss';
import RepresentationModal from "./representationModal";

const Representation = ({columns, name, representation, changeRepresentation, representations, disabled, loadingRepresentation}) => {
    const {t} = useTranslation();

    const [modalData, setModalData] = useState(null)

    return (
        <Form className='representation'>
            <Form.Field inline>
                <label>{t('representation')}</label>
                <Dropdown
                    disabled={disabled}
                    selection
                    text={representation?.name || t('defaultRepresentation')}
                    fluid
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            text={t('defaultRepresentation')}
                            onClick={() => changeRepresentation(null)}
                        />
                        {representations?.map(repr => (
                            <Dropdown.Item
                                key={repr.id}
                                text={repr.name}
                                onClick={() => changeRepresentation(repr.id)}
                            />
                        ))}
                        <Dropdown.Divider className='margin-0'/>
                        <Dropdown.Item
                            icon="add"
                            text={t('create')}
                            onClick={() => setModalData({})}
                        />
                    </Dropdown.Menu>
                </Dropdown>
                <Popup
                    content={t('customizeRepresentation')}
                    position="bottom right"
                    trigger={
                        <Button
                            loading={loadingRepresentation}
                            icon="cogs"
                            disabled={!representation}
                            onClick={() => setModalData(representation)}
                        />
                    }
                />
            </Form.Field>
            <RepresentationModal
                modalOpen={!!modalData}
                representation={modalData}
                columns={columns}
                gridName={name}
                onClose={() => setModalData(null)}
                changeRepresentation={changeRepresentation}
            />
        </Form>
    );
};

export default Representation;