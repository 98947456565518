import React from 'react';
import './styles.scss';
import {Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";


const LegendItem = ({color, icon, name}) => {
    return <div className='legend__item'>
        <div className={`legend__circle background-color_${color}`}>
            {icon && <Icon
                name={icon}
            />}
        </div>
        — {name}
    </div>
}

const Legend = () => {
    const {t} = useTranslation();

    const legendItems = [
        {
            name: 'loading',
            color: '0'
        },
        {
            name: 'unloading',
            color: '1'
        },
        {
            name: 'slotForMovement',
            color: '2'
        },
        {
            name: 'slotWithDifferentTaskTypes',
            color: '3'
        },
        {
            name: 'slotMissingFromTheSchedule',
            color: '4'
        },
        {
            name: 'busySlot',
            color: '4',
            icon: 'delete'
        },
    ]

    return (
        <div className='legend'>
            <div className='legend__title'>{t('legend')}</div>
            {
                legendItems.map(item => <LegendItem
                    key={item.name}
                    name={t(item.name)}
                    icon={item.icon}
                    color={item.color}
                />)
            }
        </div>
    );
};

export default Legend;