import VisitTasksModal from "../components/grid/modals/VisitTasksModal";
import {AUTO_REFRESH_TIME} from "./options";

export const grids = [
    {
        name: 'visits',
        modalContentComponent: (props) => <VisitTasksModal {...props}/>,
        withoutAutoForm: true,
        errorsByGridNames: true,
        globalPreFilters: true,
        disableCreateWithoutPlatform: true,
        autoRefreshTime: AUTO_REFRESH_TIME
    },
    {
        name: 'visitTasks',
        modalIdKey: 'visitId',
        modalType: 'visits',
        globalPreFilters: true,
        autoRefreshTime: AUTO_REFRESH_TIME
    },
    {
        name: 'orders',
        selectedToTop: true,
        globalPreFilters: true,
        autoRefreshTime: AUTO_REFRESH_TIME
    },
]