import React, {useEffect, useState} from "react";
import {Button, Input, List, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {
    useConnectMutation,
    useDisconnectMutation,
    useLazyGetIsConnectedQuery
} from "../../store/api/surfaceApi";
import LoadingSegment from "../LoadingSegment";

const ConnectSurfaceModal = ({children}) => {
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState(null);
    const [isConnect, setIsConnect] = useState(false);
    const {t} = useTranslation();

    const [getIsConnected, {isLoading: getLoading, data: connectedData}] = useLazyGetIsConnectedQuery();
    const [connect, {isLoading: connectLoading, data: connectData}] = useConnectMutation();
    const [disconnect, {isLoading: disconnectLoading, data: disconnectData}] = useDisconnectMutation();

    const handleOpen = () => {
        getIsConnected();
        setOpen(true);
    };

    useEffect(() => {
        if (connectedData && connectedData.connected) {
            setIsConnect(connectedData.connected);
            setCode(connectedData.number);
        }
    }, [connectedData])

    const handleClose = () => {
        setOpen(false);
    };

    const handleConnect = () => {
        connect({code})
    };

    useEffect(() => {
        if (connectData && !connectData.error) {
            setIsConnect(true);
        }
    }, [connectData]);

    const handleDisconnect = () => {
        disconnect({code})
    };

    useEffect(() => {
        if (disconnectData && !disconnectData.error) {
            setCode(null);
            setIsConnect(false);
        }
    }, [disconnectData]);

    const currentLocation = window.location;

    return (
        <Modal
            closeIcon
            dimmer="blurring"
            open={open}
            closeOnEscape
            closeOnDimmerClick={false}
            trigger={children}
            onOpen={handleOpen}
            onClose={handleClose}
            size={'mini'}
        >
            <Modal.Header>{t("workWithTheConsentFormForTheProcessingOfPersonalData")}</Modal.Header>
            <Modal.Content>
                <LoadingSegment
                    isLoading={getLoading}
                >
                    <Modal.Description>
                        {
                            !isConnect
                                ? <>
                                <span className="margin-bottom-5">
                                    {t('tabletConnectInfoLabel')}
                                </span>
                                    <List bulleted>
                                        <List.Item className="margin-left-15">{t('tabletConnectInfoStep1')} <b>{currentLocation.origin + '/surface'}</b></List.Item>
                                        <List.Item className="margin-left-15">{t('tabletConnectInfoStep2')}</List.Item>
                                    </List>
                                    <div className="margin-top-20 d-flex">
                                        <Input
                                            style={{maxWidth: '154px'}}
                                            value={code}
                                            onChange={(e, {value}) => setCode(value)}
                                        />
                                        <Button
                                            primary
                                            className="margin-left-15"
                                            onClick={handleConnect}
                                            disabled={connectLoading}
                                            loading={connectLoading}
                                        >
                                            {t('connectBtn')}
                                        </Button>
                                    </div>
                                </>
                                : <>
                                <span className="margin-bottom-15">
                                    {t('tabletIsConnectedLabel')}
                                </span>
                                    <List bulleted>
                                        <List.Item className="margin-left-15">{t('tabletIsConnectedText1')} <b>{t('drivers')}</b></List.Item>
                                        <List.Item className="margin-left-15">{t('tabletIsConnectedText2')}</List.Item>
                                    </List>
                                    <Button
                                        color='orange'
                                        onClick={handleDisconnect}
                                        disabled={disconnectLoading}
                                        loading={disconnectLoading}
                                    >
                                        {t('closeTabletConnect')} {code}
                                    </Button>
                                </>
                        }

                    </Modal.Description>
                </LoadingSegment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t("closeButton")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConnectSurfaceModal;
