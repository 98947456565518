import React from "react";
import DatePicker from "react-datepicker";
import {formatDate, parseDate} from "../../utils/dateTime";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../utils/localeStorageHelper";
import {Popup} from "semantic-ui-react";

const Edit = ({
                  value,
                  name,
                  onChange,
                  isDisabled,
                  noLabel,
                  required,
                  placeholder,
                  className,
                  isRequired,
                  isTableStyle
              }) => {
    const {t} = useTranslation();

    const getClassNames = () => {
        const classNames = [];

        if (required && !value) {
            classNames.push("input-error");
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(" ");
    };

    const getDatePicker = (params = {}) => {
        return <DatePicker
            {...params}
            locale={getLanguage()}
            disabled={isDisabled || false}
            placeholderText={placeholder}
            className={getClassNames()}
            isClearable={!(isDisabled || false)}
            selected={parseDate((value || "").substring(0, 10))}
            dateFormat="dd.MM.yyyy"
            onChange={(date, e) => {
                onChange(e, {name: name, value: date ? formatDate(date) : null});
            }}
        />
    }

    return (
        isTableStyle
            ? <Popup
                on='click'
                trigger={
                    <span>
                        {getDatePicker({
                            portalId: 'portal-id-display-none'
                        })}
                    </span>
                }
                content={getDatePicker({
                    inline: true
                })}
                className='edit-date-popup'
            />
            : <>
                {!noLabel ?
                    <label className={isDisabled ? "label-disabled" : null}>{t(name)} {isRequired && '*'}</label> : null}
                {getDatePicker()}
            </>
    );
};
export default Edit;
