import React from 'react';
import {Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {securityServiceGridName} from "../../../constants/security";
import {ENUM, TEXT} from "../../../constants/fieldTypes";
import FieldComponent from "../../../components/fields";

const ClientInfo = ({isView, form, setValue, errors, fieldSettings}) => {
    const {t} = useTranslation();

    return (<>
        <Form.Field className="margin-bottom-0">
            <label className="margin-bottom-0">{t('customerInformation')}</label>
        </Form.Field>
        <Segment>
            <Grid className="margin-bottom-10">
                <Grid.Row className="padding-bottom-0">
                    <Grid.Column width={3}>
                        <FieldComponent
                            type={ENUM}
                            error={errors.serviceType}
                            name='serviceType'
                            value={form.serviceType}
                            onChange={setValue}
                            gridName={securityServiceGridName}
                            isDisabled={isView}
                            label='serviceType'
                            isRequired
                            fieldSettings={fieldSettings}
                        />
                    </Grid.Column>
                </Grid.Row>
                {
                    (form.serviceType && form.serviceType.name === 'fTL') && <Grid.Row columns="equal" className="padding-bottom-0">
                        <Grid.Column>
                            <FieldComponent
                                type={TEXT}
                                label='informationAboutCustomer'
                                isDisabled={isView}
                                value={form.clientInformation}
                                name='clientInformation'
                                onChange={setValue}
                                error={errors.clientInformation}
                                fieldSettings={fieldSettings}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
                <Grid.Row className="padding-bottom-0">
                    <Grid.Column>
                        <Checkbox
                            label={t('isExpensiveCargo')}
                            disabled={isView}
                            checked={form.isExpensive}
                            onChange={(e, {checked}) =>
                                setValue(e, {name: "isExpensive", value: checked})
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </>);
};

export default ClientInfo;