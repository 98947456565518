import React, {useEffect, useState} from "react";
import {Modal, Button, Message, Confirm} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LoadingSegment from "../LoadingSegment";

export default function ControlledModal({
                                            children,
                                            size,
                                            className,
                                            title,
                                            buttonSize,
                                            buttonName,
                                            buttonClassName,
                                            saveButtonName,
                                            saveButtonDisabled,
                                            withoutSave,
                                            withoutCancel,
                                            onOpen,
                                            onClose,
                                            closeOnEscape = true,
                                            closeOnDimmerClick = false,
                                            onSave,
                                            onSaveThen,
                                            onSaveCatch,
                                            error,
                                            dontCloseAfterSave,
                                            isEditActivity,
                                            isDisabled,
                                            isConfirmForCloseModal,
                                            textForCloseModal,
                                            textForCloseModalCancelButton,
                                            defaultOpen = false,
                                            isScrolling = false,
                                            isLoading = false,
                                            button: btn,
                                            actionsBtns,
                                            actionsBlock,
                                            withoutBtn
                                        }) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(defaultOpen);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [confirm, setConfirm] = useState(null);

    useEffect(() => {
        if (withoutBtn) {
            defaultOpen && handleOpen();
        }
    }, [defaultOpen, withoutBtn]);

    const handleOpen = () => {
        onOpen && onOpen();
        setOpen(true);
    };

    const closeModal = () => {
        onClose && onClose();
        setOpen(false);
    };

    const handleClose = () => {
        if (isEditActivity) return;

        if (isConfirmForCloseModal) {
            setIsOpenConfirm(true);
            return;
        }

        closeModal();
    };

    const handleSave = (confirm = false) => {
        if (isEditActivity) return;

        if (onSave) {
            onSave(confirm)
                .unwrap()
                .then(x => {
                    if (x?.needConfirmation) {
                        setConfirm({
                            text: x.message,
                            func: () => {
                                handleSave(true);
                                setConfirm(null)
                            }
                        })
                    } else {
                        if ((!x || !x.isError) && !dontCloseAfterSave) {
                            closeModal();
                        }
                        onSaveThen && onSaveThen(x)
                    }
                }).catch(e => {
                onSaveCatch && onSaveCatch(e)
            })
        } else {
            closeModal();
        }
    };

    const button = btn || <Button
        className={`add-btn ${buttonClassName || ""}`}
        size={buttonSize}
        disabled={isDisabled}
    >
        {buttonName}
    </Button>;

    const actionBtn = (btn) => <Button
        key={btn.content}
        positive={btn.positive}
        negative={btn.negative}
        color={btn.color}
        content={btn.content}
        onClick={btn.onClick}
        labelPosition={btn.icon && 'right'}
        icon={btn.icon}
        loading={btn.loading}
        disabled={isLoading || btn.loading}
    />;

    const content = typeof children === 'function'
        ? children(handleOpen, handleClose)
        : children;

    const params = !withoutBtn
        ? {
            trigger: button
        }
        : {};

    return (
        <Modal
            {...params}
            dimmer="blurring"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            closeIcon
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            className={className}
            size={size}
        >
            <Modal.Header>
                {title}
            </Modal.Header>

            <Modal.Content scrolling={isScrolling}>
                <LoadingSegment isLoading={isLoading}>
                    {content}
                    {error && <Message error content={error}/>}
                </LoadingSegment>
            </Modal.Content>

            <Modal.Actions>
                {
                    !withoutCancel && <Button
                        negative={!isEditActivity}
                        content={t("cancelButton")}
                        onClick={handleClose}
                    />
                }
                {
                    actionsBlock
                }
                {
                    actionsBtns?.map(btn => (
                        btn.isVisible && (
                            btn.modal
                                ? btn.modal(actionBtn(btn))
                                : actionBtn(btn)
                        )
                    ))
                }
                {!withoutSave && <Button
                    loading={isLoading}
                    positive={!isEditActivity}
                    icon='check'
                    labelPosition='right'
                    content={t(saveButtonName)}
                    disabled={saveButtonDisabled || isLoading}
                    onClick={() => handleSave()}
                />}
            </Modal.Actions>
            <Confirm
                open={!!confirm}
                onCancel={() => setConfirm(null)}
                onConfirm={() => confirm?.func()}
                content={confirm?.text}
                cancelButton={t('cancel')}
                confirmButton={t("yes")}
                disabled={isLoading}
                loading={isLoading}
            />
            <Confirm
                dimmer="blurring"
                open={isOpenConfirm}
                onCancel={() => {
                    closeModal();
                    setIsOpenConfirm(false);
                }}
                onConfirm={() => {
                    setIsOpenConfirm(false);
                }}
                content={textForCloseModal ? textForCloseModal : t("changesNotSavedQuestion")}
                cancelButton={textForCloseModalCancelButton ? textForCloseModalCancelButton : t("closeWithoutSaving")}
                confirmButton={t("cancelButton")}
            />
        </Modal>
    );
}