import React from 'react';
import CardsForm from "../../components/cardsForm/cardsForm";
import {useGetFieldSettingsMutation, useSaveMutation} from "../../store/api/dictionaryApi";
import {useActions} from "../../hooks/actions";

const GlobalSettingsPage = ({data, cols, refresh}) => {
    const {setTrigger} = useActions();

    return <CardsForm
        data={data}
        columns={cols}
        getFieldSettings={useGetFieldSettingsMutation}
        name='globalSettings'
        save={useSaveMutation}
        refresh={() => {
            refresh();
            setTrigger();
        }}
    />;
};

export default GlobalSettingsPage;