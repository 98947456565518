import React, {useRef, useState} from 'react';
import {Button, Confirm, Grid, Icon, Popup} from "semantic-ui-react";
import Representation from "../../representation/representation";
import Search from "../../inputs/Search";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useLoadOrderInfoMutation} from "../../../store/api/gridApi";
import {useSelector} from "react-redux";
import {
    dictionaryOrGridAdditionalActionsByName,
    getGlobalSettingByNameSelector,
    userInfoSelector
} from "../../../store/slices/userSlice";
import {MAX_FILE_SIZE_NAME} from "../../../constants/options";
import MassChangeModal from "../../modals/MassChangeModal";

const TableBlockHeader = ({
                              isModal,
                              name,
                              mode,
                              totalCount,

                              withoutHeader,
                              noLabel,

                              withoutSearch,
                              searchQuery,
                              onChangeSearchValue,

                              selectedItems,
                              withFilters,
                              clearFilters,
                              customFilters,
                              filters,
                              setFilters,
                              setFilterValue,
                              disabledClear,

                              withoutRepresentations,
                              getRepresentationsLoading,
                              representations,
                              representation,
                              changeRepresentation,
                              loadingRepresentation,

                              allColumns,
                              columns,

                              canImportFromExcel,
                              canExportToExcel,
                              canDelete,
                              canCreate,
                              canCopy,
                              canMassChange,

                              editBtn,
                              refresh,

                              onDelete,
                              loadList,
                              getRequestData,

                              changeFieldValue,

                              exportToExcelFunc,
                              importFromExcelFunc,

                              disableCreateWithoutPlatform
                          }) => {
    const {t} = useTranslation();
    const userInfo = useSelector(userInfoSelector);

    const [viewDeleteConfirm, setViewDeleteConfirm] = useState(false);
    const [openMassChangeModal, setOpenMassChangeModal] = useState(false);

    const [exportExcel, {isLoading: exportLoading}] = exportToExcelFunc();
    const [importExcel, {isLoading: importLoading}] = importFromExcelFunc();
    const [uploadingOrderInfo, {isLoading: uploadingOrderInfoLoading}] = useLoadOrderInfoMutation();

    const [maxSize, maxSizeBytes] = useSelector(state => getGlobalSettingByNameSelector(state, MAX_FILE_SIZE_NAME));

    const canUploadingOrderInfo = useSelector(state => !isModal && dictionaryOrGridAdditionalActionsByName(state, name, 'canUploadingOrderInfo'));

    const fileUploader = useRef(null);
    const fileOrderInfoUploader = useRef(null);

    const uploadExcel = () => {
        fileUploader && fileUploader.current.click();
    };
    const uploadOrderInfoFile = () => {
        fileOrderInfoUploader && fileOrderInfoUploader.current.click();
    };

    const fileUploadThen = (res) => {
        if (res.isError) {
            toast.error(t(res.message));
        } else {
            toast.info(t(res.message));
            refresh()
        }
    }

    const onCatch = (e) => {
        toast.error(e.error || t('error'));
        console.log(e)
    }

    const onFilePicked = e => {
        const file = e.target.files[0];
        if (file && file.size) {
            if (file.size > maxSizeBytes) {
                toast.error(t('errorMaxSize', {size: maxSize}))
            } else {
                const data = new FormData();
                data.append('file', file);

                importExcel({
                    name,
                    data
                })
                    .unwrap()
                    .then(fileUploadThen)
                    .catch(onCatch)
            }
        }

        fileUploader.current.value = null;
    };

    const onFileOrderInfoPicked = e => {
        const file = e.target.files[0];
        if (file && file.size) {
            if (file.size > maxSizeBytes) {
                toast.error(t('errorMaxSize', {size: maxSize}))
            } else {
                const data = new FormData();
                data.append('file', file);
                data.append('activityId', null); //todo когда будут активности - сделать

                uploadingOrderInfo({
                    name,
                    data
                })
                    .unwrap()
                    .then(fileUploadThen)
                    .catch(onCatch)
            }
        }

        fileOrderInfoUploader.current.value = null;
    };

    const exportToExcel = () => {
        exportExcel({
            ...getRequestData(0, true),
            columns: columns.map(c => c.name),
            ids: selectedItems
        })
            .unwrap()
            .catch(onCatch)
    }

    return (
        <Grid>
            {!withoutHeader && <Grid.Row className='table-block__header'>
                <Grid.Column width={7}>
                    {
                        !noLabel && <h2>{t(name)}</h2>
                    }
                    {
                        customFilters && customFilters(filters, setFilters, setFilterValue, mode)
                    }
                    {
                        !withoutRepresentations && <Representation
                            disabled={getRepresentationsLoading}
                            columns={allColumns}
                            name={name}
                            representations={representations}
                            representation={representation}
                            changeRepresentation={changeRepresentation}
                            loadingRepresentation={loadingRepresentation}
                        />
                    }
                </Grid.Column>
                <Grid.Column width={9} textAlign='right'>
                    <span className='count'>{t('itemsTotalCount')}: <b>{totalCount}</b></span>
                    {
                        canCreate && editBtn(<Button
                            color='blue'
                            disabled={disableCreateWithoutPlatform && !userInfo?.currentPlatform?.value}
                        >
                            <Icon name='plus'/> {t(`addElement`)}
                        </Button>, refresh, null, mode)
                    }
                    {
                        canCopy && <Popup
                            className='popup-fix'
                            disabled={selectedItems.length !== 1}
                            trigger={
                                <span className='span-btn'>
                                    {editBtn(<Button
                                        disabled={selectedItems.length !== 1}
                                        icon='copy'
                                    />, refresh, null, mode, null, selectedItems[0])}
                                </span>
                            }
                            on='hover'
                            content={t("copy")}
                            position="bottom right"
                        />
                    }
                    {
                        canUploadingOrderInfo && <>
                            <Popup
                                trigger={
                                    <Button
                                        icon="boxes"
                                        onClick={uploadOrderInfoFile}
                                        loading={uploadingOrderInfoLoading}
                                        disabled={uploadingOrderInfoLoading}
                                    />
                                }
                                content={t("uploadingOrderInfo")}
                                position="bottom right"
                            />
                            <input
                                type="file"
                                ref={fileOrderInfoUploader}
                                className='d-none'
                                onChange={onFileOrderInfoPicked}
                            />
                        </>
                    }
                    {
                        canImportFromExcel && <>
                            <Popup
                                trigger={
                                    <Button
                                        icon="upload"
                                        onClick={uploadExcel}
                                        loading={importLoading}
                                        disabled={importLoading}
                                    />
                                }
                                content={t("uploadExcel")}
                                position="bottom right"
                            />
                            <input
                                type="file"
                                ref={fileUploader}
                                className='d-none'
                                onChange={onFilePicked}
                            />
                        </>
                    }
                    {
                        canExportToExcel && <Popup
                            content={t("exportToExcel")}
                            position="bottom right"
                            trigger={
                                <Button
                                    loading={exportLoading}
                                    disabled={exportLoading}
                                    onClick={exportToExcel}
                                    icon="download"
                                />
                            }
                        />
                    }
                    {
                        canDelete && <Popup
                            content={t("delete")}
                            position="bottom right"
                            trigger={
                                <Button
                                    onClick={() => setViewDeleteConfirm(true)}
                                    icon="trash alternate"
                                    disabled={!selectedItems.length}
                                />
                            }
                        />
                    }
                    {
                        canMassChange && <>
                            <Popup
                                content={t("massParameterChange")}
                                position="bottom right"
                                trigger={
                                    <Button
                                        onClick={() => setOpenMassChangeModal(true)}
                                        icon="edit"
                                        disabled={!selectedItems.length}
                                    />
                                }
                            />
                            <MassChangeModal
                                name={name}
                                changeFieldValue={changeFieldValue}
                                ids={selectedItems}
                                columns={columns}
                                open={openMassChangeModal}
                                onClose={() => setOpenMassChangeModal(false)}
                                load={loadList}
                            />
                        </>
                    }
                    <Confirm
                        dimmer="blurring"
                        open={viewDeleteConfirm}
                        onCancel={() => {
                            setViewDeleteConfirm(false);
                        }}
                        onConfirm={() => {
                            onDelete(() => {
                                loadList();
                                setViewDeleteConfirm(false);
                            });
                        }}
                        content={t("areYouSureYouWantToDeleteTheSelectedRows")}
                        cancelButton={t("cancelButton")}
                        confirmButton={t("yes")}
                    />
                    {
                        withFilters && <Button
                            icon='filter'
                            className="icon-delete"
                            onClick={clearFilters}
                            disabled={disabledClear}
                        />
                    }
                    {
                        !withoutSearch && <Search
                            value={searchQuery}
                            onChange={onChangeSearchValue}
                            placeholder={t('searchByAllFields')}
                        />
                    }
                </Grid.Column>
            </Grid.Row>}
        </Grid>
    );
};

export default TableBlockHeader;