import React from "react";
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {DATE, MULTISELECT, PHONE_NUMBER, TEXT, TEXTAREA} from "../../../constants/fieldTypes";
import FieldComponent from "../../../components/fields";

export default function NewRequest({request, handleChange}) {
    const {t} = useTranslation();
    const handleVisitorNameChange = (e, index, value) => {
        const names = [...request.visitorNames];
        names[index] = value;
        handleChange(e, {name: "visitorNames", value: names});
    };

    const handleAddNewVisitorName = (e) => {
        const names = [...request.visitorNames, ""];
        handleChange(e, {name: "visitorNames", value: names});
    };

    const handleDeleteVisitorName = (e, index) => {
        const names = [...request.visitorNames];
        names.splice(index, 1);
        handleChange(e, {name: "visitorNames", value: names});
    };

    return (
        <Form>
            <Grid columns='equal' className="margin-bottom-10">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <FieldComponent
                            type={TEXT}
                            isDisabled
                            isRequired
                            name={'requestEmail'}
                            value={request.requesterEmail}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <FieldComponent
                            type={PHONE_NUMBER}
                            name={'requesterPhoneNumber'}
                            value={request.requesterPhoneNumber}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={8}>
                        <FieldComponent
                            type={TEXT}
                            name={'truckNumber'}
                            value={request.truckNumber}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <FieldComponent
                            type={TEXT}
                            isRequired
                            name={'visitorCompany'}
                            value={request.visitorCompany}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("requestFullName") + " *"}</label>
                            <Segment>
                                {request.visitorNames.map((name, index) => {
                                    return (<Form.Group key={"fullName" + index}>
                                        <Form.Input
                                            width={15}
                                            name={"name" + index}
                                            value={name}
                                            onChange={(e, {value}) => handleVisitorNameChange(e, index, value)}
                                        />

                                        {index > 0 &&
                                            <Button
                                                width={1}
                                                icon='delete'
                                                onClick={e => handleDeleteVisitorName(e, index)}
                                            />
                                        }
                                    </Form.Group>);
                                })}

                                <Button
                                    width={8}
                                    icon='add'
                                    labelPosition='left'
                                    content={t("requestAddVisitor")}
                                    onClick={handleAddNewVisitorName}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <FieldComponent
                            type={MULTISELECT}
                            label={'platforms'}
                            dictionaryName='passRequests/platforms'
                            isRequired
                            name="platforms"
                            value={request?.platforms}
                            onChange={handleChange}
                            placeholder={t("requestSelectPlatformsPlaceholder")}
                            filter={{
                                requesterEmail: request.requesterEmail
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("requestPeriodOfValidity")}</label>
                            <Segment>
                                <Form.Group>
                                    <Form.Field width={8}>
                                        <FieldComponent
                                            type={DATE}
                                            name='startDate'
                                            isRequired
                                            value={request.startDate}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>

                                    <Form.Field width={8}>
                                        <FieldComponent
                                            type={DATE}
                                            name='finishDate'
                                            isRequired
                                            value={request.finishDate}
                                            onChange={handleChange}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <FieldComponent
                            type={TEXTAREA}
                            isRequired
                            name={'purpose'}
                            value={request.purpose}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>);
};