const TOKEN_NAME = 'yms-token'
const LANGUAGE_NAME = 'i18nextLng'
const REPRESENTATIONS_NAME = 'representations'
export const setJwt = (token) => {
    localStorage.setItem(TOKEN_NAME, token)
}
export const removeJwt = () => {
    localStorage.removeItem(TOKEN_NAME)
}
export const getJwt = () => {
    return localStorage.getItem(TOKEN_NAME)
}
export const setLanguage = (language) => {
    localStorage.setItem(LANGUAGE_NAME, language)
}
export const getLanguage = () => {
    return localStorage.getItem(LANGUAGE_NAME)
}

export const getRepresentation = (gridName) => {
    const representations = JSON.parse(localStorage.getItem(REPRESENTATIONS_NAME) || '{}')
    return representations && representations[gridName];
}

export const setRepresentationToLocalStorage = (gridName, id) => {
    return localStorage.setItem(REPRESENTATIONS_NAME, JSON.stringify({
        ...(JSON.parse(localStorage.getItem(REPRESENTATIONS_NAME) || '{}')),
        [gridName]: id
    }))
}