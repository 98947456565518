import React from 'react';
import '../TableBlock/styles.scss'
import TableBlock from "../TableBlock/TableBlock";
import {
    useClickToggleMutation,
    useDeleteMutation,
    useEditValueMutation,
    useExportMutation,
    useImportMutation,
    useLazyGetCountersQuery,
    useLazyGetIdsQuery,
    useLazyGetListQuery,
} from "../../store/api/dictionaryApi";
import {dictionarySettingsByName} from "../../constants/utils";
import {useSelector} from "react-redux";
import {dictionaryFieldsSelector, dictionarySelector} from "../../store/slices/userSlice";
import {displayModes} from "../../constants/options";
import {searchParametersFromUrl} from "../../utils/url";
import EditBtn from "./EditBtn";

const Dictionary = ({dictionaryName, isTab}) => {
    const dictionarySettings = dictionarySettingsByName(dictionaryName) || {};
    const dictionaryData = useSelector(state => dictionarySelector(state, dictionaryName)) || {};
    const dictionaryColumns = useSelector(state => dictionaryFieldsSelector(state, dictionaryName, displayModes.GRID));

    const [click, data] = useClickToggleMutation();

    const preFilters = searchParametersFromUrl();
    const disabledFilters = Object.keys(preFilters);

    const clickActivity = () => {
        return [
            (data) => {
                return click({
                    ...data,
                    name: dictionaryName
                })
            },
            data
        ]
    }

    const editBtn = (btn, refresh, id, mode, row, copyFromId) => {
        return <EditBtn
            dictionaryName={dictionaryName}
            key={id}
            id={id}
            onClose={refresh}
            copyFromId={copyFromId}
            readOnly={row?.isReadOnly}
            autoValues={(!id && preFilters.platformId) && {
                platformId: {
                    value: preFilters.platformId
                }
            }}
            preFieldSettings={(!id && preFilters.platformId) && [{
                fieldName: 'platformId',
                isReadOnly: true
            }]}
            btn={btn}
            fieldsToClear={dictionarySettings?.fieldsToClear}
        />
    }

    return <TableBlock
        isModesTab={!!dictionaryData.modes}
        key={dictionaryName}
        settings={{
            ...dictionarySettings,
            ...dictionaryData,
            columns: dictionaryColumns,
            defaultMode: dictionaryData.modes ? dictionaryData.modes[0]?.mode : undefined
        }}
        isTab={isTab}
        editBtn={editBtn}
        deleteRow={useDeleteMutation}
        getCountersQuery={useLazyGetCountersQuery}
        getListQuery={useLazyGetListQuery}
        getIdsQuery={useLazyGetIdsQuery}
        exportToExcelFunc={useExportMutation}
        importFromExcelFunc={useImportMutation}
        clickToggle={clickActivity}
        withoutRepresentations
        changeFieldValue={useEditValueMutation}
        preFilters={preFilters}
        disabledFilters={disabledFilters}
    />
}
export default Dictionary;