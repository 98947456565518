import React from 'react';
import FieldComponent from "../../fields";
import {MULTISELECT, TIME} from "../../../constants/fieldTypes";
import {weekDays} from "../../../constants/options";
import {compareTimes} from "../../../utils/dateTime";
import {useTranslation} from "react-i18next";

const Schedule = ({value, onChange, startEndCheck = false, isDisabled}) => {
    const {t} = useTranslation();

    const {dayOfWeek, timeFrom, timeTo} = (value || {});

    return (
        <div className='schedule-input'>
            {
                !isDisabled
                    ? <FieldComponent
                        type={MULTISELECT}
                        multiple
                        value={dayOfWeek}
                        noLabel
                        name='dayOfWeek'
                        onChange={onChange}
                        forSelectValues={weekDays}
                    />
                    : <div className='field'>
                        {
                            dayOfWeek?.length === 7
                                ? t('allDays')
                                : dayOfWeek?.map(d => t(d.value)).join(', ')
                        }
                    </div>
            }
            <div className='schedule-input__times'>
                <FieldComponent
                    isDisabled={isDisabled}
                    type={TIME}
                    value={timeFrom}
                    noLabel
                    name='timeFrom'
                    onChange={onChange}
                    min={timeTo}
                    error={(startEndCheck && timeFrom && timeTo) && compareTimes(timeFrom, timeTo) >= 0}
                />
                <FieldComponent
                    isDisabled={isDisabled}
                    type={TIME}
                    value={timeTo}
                    noLabel
                    name='timeTo'
                    onChange={onChange}
                    max={timeFrom}
                    error={(startEndCheck && timeFrom && timeTo) && compareTimes(timeFrom, timeTo) >= 0}
                />
            </div>
        </div>
    );
};

export default Schedule;