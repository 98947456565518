import React from 'react';
import CreateOrEditModal from "../../modals/Modal";
import {
    useLazyGetDefaultFormQuery,
    useLazyGetElementQuery,
    useSaveMutation,
    useGetFieldSettingsMutation
} from "../../../store/api/dictionaryApi";

const CreateOrEditDictionaryModal = ({dictionaryName, ...props}) => {
    return (
        <CreateOrEditModal
            {...props}
            dictionaryOrGridName={dictionaryName}
            getElement={useLazyGetElementQuery}
            getDefaultForm={useLazyGetDefaultFormQuery}
            saveData={useSaveMutation}
            getFieldSettings={useGetFieldSettingsMutation}
            getFieldSettingsWithId
        />
    );
};

export default CreateOrEditDictionaryModal;