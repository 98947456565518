import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const surfaceApi = createApi({
    reducerPath: 'surfaceApi',
    baseQuery: baseQuery('/Surface'),
    endpoints: (build) => ({
        connect: build.mutation({
            query: ({code}) => {
                return {
                    url: `/Connect?number=${code}`,
                    method: 'POST',
                }
            }
        }),
        disconnect: build.mutation({
            query: ({code}) => {
                return {
                    url: `/Disconnect?number=${code}`,
                    method: 'POST',
                }
            }
        }),
        getIsConnected: build.query({
            query: () => {
                return {
                    url: '/IsConnected',
                }
            }
        }),
        getContent: build.query({
            query: ({id}) => {
                return {
                    url: `/GetContent?id=${id}`,
                }
            }
        }),
        accept: build.mutation({
            query: ({driverId}) => {
                return {
                    url: `/Accept?driverId=${driverId}`,
                    method: 'POST',
                }
            }
        }),
        create: build.mutation({
            query: () => {
                return {
                    url: `/Create`,
                }
            }
        }),
        clear: build.mutation({
            query: ({id}) => {
                return {
                    url: `/Clear?id=${id}`,
                    method: 'POST',
                }
            }
        }),
        viewForDriver: build.mutation({
            query: ({id}) => {
                return {
                    url: `/ViewForDriver?driverId=${id}`,
                }
            }
        }),
        stopViewForDriver: build.mutation({
            query: ({id}) => {
                return {
                    url: `/StopViewForDriver?driverId=${id}`,
                    method: 'POST',
                }
            }
        }),
    })
});

export const {
    useConnectMutation,
    useDisconnectMutation,
    useAcceptMutation,
    useCreateMutation,
    useClearMutation,
    useViewForDriverMutation,
    useStopViewForDriverMutation,
    useLazyGetContentQuery,
    useLazyGetIsConnectedQuery,
} = surfaceApi;