import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    useLazyGetElementQuery,
    useSaveMutation,
    useLazyGetDefaultFormQuery,
    useDeleteMutation,
    useChangeStatusMutation,
    useGetColumnsMutation
} from "../../store/api/gridApi";
import FormModal from "../../components/modals/FormModal";
import {Confirm, Form, Icon, Segment, Tab} from "semantic-ui-react";
import SecurityCheckInfo from "./SecurityCheckInfo";
import History from "../../components/history/History";
import HistoryByDriver from "./components/HistoryByDriver";
import {toast} from "react-toastify";
import Messages from "./components/Messages";
import SecurityDecisionModal from "./SecurityDecisionModal";
import {caseStates} from "../../constants/options";
import {useLazyGetDocsQuery} from "../../store/api/documentApi";
import {securityServiceGridName} from "../../constants/security";


const GetStatusMap = ({driverState, trailerState, truckState}) => {
    const {t} = useTranslation();
    const getIcon = (status) => {
        switch (status?.value) {
            case 'approved':
                return <Icon name="check" color="green"/>;
            case 'blocked':
                return <Icon name="close" color="red"/>;
            case 'notChecked':
            case 'declined':
            default:
                return <Icon name="minus"/>;
        }
    };

    return <div>
        <span>{t('driver')} {getIcon(driverState)}</span>
        <span className="pl-5">{t('truck')} {getIcon(truckState)}</span>
        <span className="pl-5">{t('trailer')} {getIcon(trailerState)}</span>
    </div>;
};

const SecurityModal = ({children, id, onClose, isService}) => {
    const gridName = securityServiceGridName;
    const {t} = useTranslation();
    const [getData, {data = {}, isLoading, isFetching}] = useLazyGetElementQuery();
    const [getDefaultData, {data: defaultData, isLoading: defaultLoading}] = useLazyGetDefaultFormQuery();
    const [saveForm, {isLoading: isLoadingSave}] = useSaveMutation();
    const [deleteCase, {isLoading: deleteLoading}] = useDeleteMutation();
    const [changeStatus, {isLoading: changeStatusLoading}] = useChangeStatusMutation();
    const [getColumns, {data: columnsData, isLoading: getColumnsLoading}] = useGetColumnsMutation();
    const [errorText, setErrorText] = useState('');
    const [errors, setErrors] = useState({});
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [getDocsById, {data: documents = [], isLoading: docsIsLoading}] = useLazyGetDocsQuery();

    const isView = (!!data?.status && data.status?.value !== caseStates.RETURNED);

    const getDocs = () => {
        getDocsById({id: id || defaultData.id})
    }

    const refreshData = () => {
        getData({name: gridName, id});
        getDocs();
    };

    const onOpen = () => {
        id
            ? refreshData()
            : getDefaultData({name: gridName})
                .unwrap()
                .then(({id}) => getDocsById({id}))

        isService && getColumns({name: gridName});

        setErrorText('')
        setErrors({})
    }

    const onSave = (form) => {
        return saveForm({name: gridName, form})
    }

    const onSaveError = (data) => {
        if (data?.errors) {
            let errs = {};
            data.errors.forEach(err => errs[err.name] = err.message);
            setErrors(errs);
        }
        setErrorText(typeof data?.message === 'string' ? data.message : 'anErrorHasOccurred')
    }


    const onSaveThen = (data) => {
        if (data.isError) {
            onSaveError(data)
        } else {
            toast.info(`${t('case')} ${t('createdAndSentToSB')}`)
            onClose();
        }
    }

    const onSaveCatch = ({data}) => {
        onSaveError(data);
    }

    const servicePanes = (form, setValue) => [
        {
            menuItem: t('information'),
            key: "1",
            render: () => (
                <Tab.Pane className='init-scroll'>
                    <SecurityCheckInfo
                        errors={errors}
                        docTypesKey={gridName}
                        form={form}
                        setValue={setValue}
                        isStateEdit={form.status?.value === caseStates.IN_WORK}
                        isView={isView}
                        docs={documents}
                        onSaveDocs={getDocs}
                    />
                </Tab.Pane>
            )
        },
        {
            menuItem: t('caseHistory'),
            key: "2",
            render: () => {
                return (<Tab.Pane className='init-scroll'>
                    <Segment>
                        <History id={id} isLocalDate/>
                    </Segment>
                </Tab.Pane>);
            }
        },
        {
            menuItem: t('driverStory'),
            key: "3",
            render: () => {
                return (<Tab.Pane className='init-scroll'>
                    <HistoryByDriver id={id}/>
                </Tab.Pane>);
            }
        },
    ]

    const inWork = () => {
        changeStatus({
            name: gridName,
            status: caseStates.IN_WORK,
            id
        }).unwrap()
            .then(data => {
                data.error && setErrorText(data.error);
                refreshData();
                setIsChanged(true)
            })
            .catch(() => {
                setErrorText(t('error'));
            })
    }

    const actionBtnsCheck = (form) => [
        {
            isVisible: form.isInitiator && form.status?.value === caseStates.NEW,
            negative: true,
            icon: 'delete',
            content: t('delete'),
            onClick: () => {
                !deleteLoading && setIsOpenConfirm(true)
            },
            loading: deleteLoading,
        }
    ]

    const btnModal = (form, title, isConfirm, isReject, isReturn, isReturnToWork) => {
        return (children) => <SecurityDecisionModal
            key={title + id}
            isConfirm={isConfirm}
            isReject={isReject}
            isReturn={isReturn}
            isReturnToWork={isReturnToWork}
            title={t(title)}
            caseId={id}
            driverId={form.driverId?.value}
            columns={columnsData?.columns || []}
            onClose={(isRefresh) => {
                refreshData();
                setIsChanged(isRefresh)
            }}
            driverState={form.driverStatus?.value}
            truckState={form.truckStatus?.value}
            trailerState={form.trailerStatus?.value}
        >
            {children}
        </SecurityDecisionModal>
    }

    const actionBtnsService = (form) => {
        const takeOverBtnView = form.status?.value === caseStates.NEW || (!form.isResponsible && form.status?.value === caseStates.IN_WORK);
        const inWorkBtnView = !takeOverBtnView && form.status?.value === caseStates.IN_WORK;
        const btnView = inWorkBtnView && (form.driverState !== 2 || form.truckState !== 2 || form.trailerState !== 2);

        return [
            {
                isVisible: takeOverBtnView,
                positive: true,
                content: t('takeOver'),
                onClick: () => {
                    !changeStatusLoading && inWork()
                },
                loading: changeStatusLoading,
            },
            {
                isVisible: inWorkBtnView,
                positive: true,
                content: `${t('ok')} (${t('confirm')})`,
                modal: btnModal(form, 'theConfirmation', true)
            },
            {
                isVisible: btnView,
                negative: true,
                content: t('rejected'),
                modal: btnModal(form, 'rejectionReason', false, true)
            },
            {
                isVisible: btnView,
                color: 'yellow',
                content: t('returnToInitiator'),
                modal: btnModal(form, 'reasonForReturn', false, false, true)
            },
            {
                isVisible: !btnView && form.status?.value === caseStates.PROCESSING_COMPLETED,
                positive: true,
                content: t('returnToWork'),
                modal: btnModal(form, 'theReturnToWork', false, false, false, true)
            }
        ]
    }

    const onDelete = () => {
        deleteCase({
            name: gridName,
            ids: [id]
        })
            .unwrap()
            .then(data => {
                data.error ? setErrorText(data.error) : onClose();
            })
            .catch(() => {
                onSaveCatch()
            })

        setIsOpenConfirm(false);
    }

    const title = () => {
        return <div className="modal-headers-status">
            <div>
                {
                    id
                        ? (!data.id ? `${t('loading')}...` : `${t('viewCase')} №${data.number || ''} (${t(data.status.value) || ''})`)
                        : t(`${gridName}Create`)
                }
            </div>
            {isService && <div>
                <GetStatusMap
                    driverState={data.driverStatus}
                    trailerState={data.trailerStatus}
                    truckState={data.truckStatus}
                />
            </div>}
        </div>
    }

    const setValueWithError = (setValue) => {
        return (e, {name, value}) => {
            setValue(null, {name, value});
            setErrors(errors => {
                return {...errors, [name]: null}
            })
        }
    }

    const btnHidden = (id && !data.id) || isView;

    return (
        <FormModal
            size='large'
            button={children}
            title={title()}
            onOpen={onOpen}
            onSave={onSave}
            onSaveThen={onSaveThen}
            onSaveCatch={onSaveCatch}
            loading={isLoading || isFetching || isLoadingSave || defaultLoading || getColumnsLoading || docsIsLoading}
            initialForm={(id ? data : defaultData) || {}}
            onClose={isChanged && onClose}
            saveButtonName={t(data.status?.value === caseStates.RETURNED ? 'resend' : `${gridName}${id ? 'Save' : 'Create'}`)}
            actionBtns={isService ? actionBtnsService : actionBtnsCheck}
            withoutSave={btnHidden}
            withoutCancel={btnHidden}
        >
            {
                ({form, setValue}) => (
                    isService
                        ? <Tab panes={servicePanes(form, setValueWithError(setValue))}/>
                        : <>
                            <Messages
                                form={form}
                                errorText={errorText}
                            />
                            <Form className='init-scroll padding-right-15'>
                                <SecurityCheckInfo
                                    errors={errors}
                                    docTypesKey={gridName}
                                    form={form}
                                    setValue={setValueWithError(setValue)}
                                    isStateEdit={form.status?.value === caseStates.IN_WORK}
                                    isView={isView}
                                    docs={documents}
                                    onSaveDocs={getDocs}
                                />
                            </Form>
                            <Confirm
                                dimmer="blurring"
                                open={isOpenConfirm}
                                onCancel={() => {
                                    setIsOpenConfirm(false)
                                }}
                                onConfirm={() => {
                                    onDelete()
                                }}
                                content={t('areYouSureYouWantToDeleteTheCase?')}
                                cancelButton={t('cancelButton')}
                                confirmButton={t('delete')}
                            />
                        </>
                )
            }
        </FormModal>
    );
};

export default SecurityModal;