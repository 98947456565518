import React from 'react';
import {Button} from "semantic-ui-react";
import {useLazyCreateOpenApiTokenQuery} from "../../../../store/api/dictionaryApi";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {copyToClipboard} from "../../../../utils/copy";
import {useSelector} from "react-redux";
import {userInfoSelector} from "../../../../store/slices/userSlice";
import {CREATE_OPEN_API_TOKEN_PERMISSION} from "../../../../constants/permissionNames";

const CreateOpenApiTokenButton = ({form}) => {
    const userInfo = useSelector(userInfoSelector);
    const {t} = useTranslation();
    const [createOpenApiToken, {isLoading, isFetching}] = useLazyCreateOpenApiTokenQuery();
    const {permissions = []} = userInfo;
    const result = (res) => {
        if (res.isError) {
            toast.error(res.message)
        } else {
            copyToClipboard(res.token)
        }
    }

    const generateOpenApiKeyHandler = () => {
        createOpenApiToken({
            id: form.id
        })
            .unwrap()
            .then(result)
            .catch(data => result(data?.data))
    }

    return (
        (form.id && permissions.includes(CREATE_OPEN_API_TOKEN_PERMISSION)) && <div className='margin-left-5'>
            <Button
                loading={isLoading || isFetching}
                disabled={isLoading || isFetching}
                onClick={generateOpenApiKeyHandler}
            >
                {t('generateKeyOpenApi')}
            </Button>
        </div>
    );
};

export default CreateOpenApiTokenButton;