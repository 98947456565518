import React, {useEffect, useState} from 'react';
import TableHeader from "./components/tableHeader";
import TableActions from "./components/tableActions";
import InfiniteScrollTable from "./InfiniteScrollTable";
import {PAGE_SIZE} from "../../constants/options";
import VirtualizationTable from "./virtualizationTable";

const EditTable = ({
                       className,
                       columns,
                       rows,
                       rowComponent,
                       footerBtns,
                       pageSize = PAGE_SIZE,
                       isViewStyle,
                       component,
                       virtualization,
                       actions,
                       setCustomField,
                       setFieldValue,
                       getCustomValue,
                       platformId,
                       activityId,
                       fieldSettings
                   }) => {
    const [page, setPage] = useState(1);
    const count = pageSize * page;

    const nextPage = () => {
        if (rows?.length > count) {
            setPage(page => page + 1);
        }
    }

    const viewRows = (rows) => {
        return rows?.slice(0, count)
    }

    const header = <TableHeader
        columns={columns}
        className='sticky-header-tb'
        noWrap={!isViewStyle}
    />;

    const [sizeData, setSizeData] = useState({});

    useEffect(() => {
        component && setSizeData(component?.getBoundingClientRect())
    }, [component])

    return (
        <div>
            {
                virtualization
                    ? <VirtualizationTable
                        className={`form ui ${className}`}
                        rows={rows}
                        columns={columns}
                        width={sizeData.width}
                        height={sizeData.height - 50}
                        actions={actions}
                        setFieldValue={setFieldValue}
                        setCustomField={setCustomField}
                        getCustomValue={getCustomValue}
                        platformId={platformId}
                        activityId={activityId}
                        fieldSettings={fieldSettings}
                    />
                    : <InfiniteScrollTable
                        className={`form ui ${className}`}
                        id={'edit-table'}
                        loadNext={nextPage}
                        header={header}
                        scrollOff={isViewStyle}
                    >
                        {
                            viewRows(rows)?.map((row, index) => rowComponent({index, row}))
                        }
                    </InfiniteScrollTable>
            }
            <TableActions
                footerBtns={footerBtns}
            />
        </div>
    );
};

export default EditTable;