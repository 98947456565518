export const downloadFile = async result => {
    const {headers} = result;
    let withQuotes = false;
    let headerLine = headers.get('content-disposition') || '';
    let startFileNameIndex = headerLine.indexOf('filename=') + 9;
    if (headerLine[startFileNameIndex] === '"') {
        withQuotes = true;
        startFileNameIndex++;
    }
    let endFileNameIndex = headerLine.lastIndexOf(';') - (withQuotes ? 1 : 0);
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(await result.blob());
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}