import { ACTIONS, STATE, TEXT, SELECT } from "./fieldTypes";

export const requestsTable = {
    name: 'passRequests',
    nameElement: 'request',
    noLabel: true,
    openModalOnClick: false,
    withoutHeader: true,
    filters: false,
    columns: [
        {
            name: 'status',
            displayNameKey: 'requestState',
            type: STATE
        },
        {
            name: 'visitorName',
            displayNameKey: 'requestVisitor',
            type: TEXT
        },
        {
            name: 'requesterPhone',
            displayNameKey: 'requestPhoneNumber',
            type: TEXT
        },
        {
            name: 'truckNumber',
            displayNameKey: 'requestTruckNumber',
            type: TEXT
        },
        {
            name: 'platformId',
            displayNameKey: 'requestPlatforms',
            type: SELECT
        },
        {
            name: 'requestDate',
            displayNameKey: 'requestDate',
            type: TEXT
        },
        {
            name: 'purpose',
            displayNameKey: 'purpose',
            type: TEXT
        },
        {
            name: 'actions',
            displayNameKey: ' ',
            type: ACTIONS
        },
    ]
}