import React, {useState} from 'react';
import ObjectViewer from "./ObjectViewer";
import {Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import EditBtn from "../../dictionary/EditBtn";
import {eventStopPropagation} from "../../../constants/utils";

const ValuePopup = ({value, col, load}) => {
    const {t} = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const ignoreClose = 'ignoreClose';

    return <div>
        <Popup
            on='hover'
            open={open}
            className='value-popup'
            trigger={
                <div>{t(value.name)}</div>
            }
            content={<div onClick={eventStopPropagation}>
                <ObjectViewer
                    onOpen={() => setOpenModal(true)}
                    value={value}
                    source={col.source}
                    onClick={() => setOpen(ignoreClose)}
                    onClose={() => setOpen(false)}
                />
            </div>}
            hoverable
            onOpen={() => setOpen(true)}
            onClose={() => open.toString() !== ignoreClose && setOpen(false)}
        />
        <div onClick={eventStopPropagation}>
            <EditBtn
                defaultOpen={openModal}
                withoutBtn
                dictionaryName={col.source}
                id={value?.value}
                onClose={() => setOpenModal(false)}
                onSave={load}
            />
        </div>
    </div>
};

export default ValuePopup;