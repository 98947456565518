import React from 'react';
import CreateOrEditModal from "../../modals/Modal";
import {
    useGetCustomColumnsMutation,
    useLazyGetDefaultFormQuery,
    useLazyGetElementQuery,
    useSaveMutation,
    useGetFieldSettingsMutation,
    useGetCustomFieldSettingsMutation
} from "../../../store/api/gridApi";

const CreateOrEditGridModal = ({gridName, colsCounts, ...props}) => {
    return (
        <CreateOrEditModal
            {...props}
            getCustomCols={useGetCustomColumnsMutation}
            dictionaryOrGridName={gridName}
            getElement={useLazyGetElementQuery}
            getDefaultForm={useLazyGetDefaultFormQuery}
            saveData={useSaveMutation}
            getFieldSettings={useGetFieldSettingsMutation}
            getCustomFieldSettings={useGetCustomFieldSettingsMutation}
            size='fullscreen'
            colsCounts={colsCounts || 4}
        />
    );
};

export default CreateOrEditGridModal;