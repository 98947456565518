import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import {userApi} from "./api/userApi";
import {surfaceApi} from "./api/surfaceApi";
import {gridApi} from "./api/gridApi";
import {dictionaryApi} from "./api/dictionaryApi";
import {documentApi} from "./api/documentApi";
import {fileApi} from "./api/fileApi";
import {historyApi} from "./api/historyApi";
import {ftpFileUploaderApi} from "./api/ftpFileUploaderApi";
import {fieldMatrixApi} from "./api/fieldMatrixApi";
import {avisationApi} from "./api/avisation";
import {dictionariesSettingsApi} from "./api/dictionariesSettingsApi";
import {gateReviewsApi} from "./api/gateReviews";

export const store = configureStore({
    reducer: {
        user: userReducer,
        [userApi.reducerPath]: userApi.reducer,
        [surfaceApi.reducerPath]: surfaceApi.reducer,
        [gridApi.reducerPath]: gridApi.reducer,
        [dictionaryApi.reducerPath]: dictionaryApi.reducer,
        [documentApi.reducerPath]: documentApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [historyApi.reducerPath]: historyApi.reducer,
        [ftpFileUploaderApi.reducerPath]: ftpFileUploaderApi.reducer,
        [fieldMatrixApi.reducerPath]: fieldMatrixApi.reducer,
        [avisationApi.reducerPath]: avisationApi.reducer,
        [dictionariesSettingsApi.reducerPath]: dictionariesSettingsApi.reducer,
        [gateReviewsApi.reducerPath]: gateReviewsApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
        userApi.middleware,
        surfaceApi.middleware,
        gridApi.middleware,
        dictionaryApi.middleware,
        documentApi.middleware,
        fileApi.middleware,
        historyApi.middleware,
        ftpFileUploaderApi.middleware,
        fieldMatrixApi.middleware,
        avisationApi.middleware,
        dictionariesSettingsApi.middleware,
        gateReviewsApi.middleware,
    )
})

setupListeners(store.dispatch);