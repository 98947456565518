import React from 'react';
import {useTranslation} from "react-i18next";
import Sketch from "@uiw/react-color-sketch";
import {Icon, Input, Popup} from "semantic-ui-react";

const Color = ({value, name, onChange, isDisabled, label, isRequired, noLabel}) => {
    const {t} = useTranslation();

    return (
        <>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(label || name)} {isRequired ? '*' : ''}</label> : null}
            <Popup
                on='click'
                trigger={
                    <Input
                        className='color-input'
                        disabled={isDisabled}
                        icon={
                            <Icon
                                style={{
                                    backgroundColor: value || 'white',
                                    opacity: 1
                                }}
                            />
                        }
                        value={value}
                        readOnly
                    />
                }
                content={
                    <Sketch
                        style={{marginLeft: 20}}
                        color={value || '#fff'}
                        disableAlpha
                        onChange={(color) => {
                            onChange(null, {
                                name,
                                value: color.hex
                            })
                        }}
                    />
                }
                style={{
                    padding: 0,
                    margin: 0,
                    marginTop: '10px'
                }}
                position='bottom center'
            />
        </>
    );
};

export default Color;