import React from 'react';
import {Button, Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const RowActions = ({actions, row}) => {
    const {t} = useTranslation();

    return (
        <div className='d-flex j-c-center'>
            {
                actions && actions(row).map(action => (
                    action.visible && <Popup
                        key={action.name}
                        trigger={
                            <Button
                                color={action.color}
                                size='mini'
                                icon={action.icon}
                                onClick={action.onClick}
                            />
                        }
                        content={t(action.name)}
                    />
                ))
            }
        </div>
    );
};

export default RowActions;