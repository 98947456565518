import React from 'react';
import {useSelector} from "react-redux";
import {gridOrDictionaryFieldsSectionsSelector} from "../../store/slices/userSlice";
import SectionBlock from "./SectionBlock";
import {styleForFields} from "../../utils/styleForFields";

const SectionsForm = (props) => {
    const colsCounts = props.sectionColCounts || 2;
    const sections = useSelector(state => gridOrDictionaryFieldsSectionsSelector(state, props.name));
    const style = styleForFields(colsCounts);

    return (
        <div
            className='form-fields form-fields_custom sections-block'
            style={{
                maxHeight: `${props.maxHeight || 400}px`
            }}
        >
            {
                sections?.map(section => {
                    const customBlock = props.customObjects?.[section];
                    return <SectionBlock
                        {...props}
                        colsCounts={props.customColsCounts?.[section] || props.colsCounts}
                        style={style}
                        section={section}
                        customBlock={customBlock}
                    />
                })
            }
        </div>
    );
};

export default SectionsForm;