import React from 'react';
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const GateButton = ({form}) => {
    const {t} = useTranslation();

    const onClick = () => {
        window.open(`/gates?platformId=${form?.id}`, '_blank')
    }

    return (
        form.id && <div className='margin-top-15'>
            <Button
                size='mini'
                color='blue'
                onClick={onClick}
            >
                {t('openGates')}
            </Button>
        </div>
    );
};

export default GateButton;