import React, {useEffect} from 'react';
import DocListEdit from "./DocListEdit";
import {useTranslation} from "react-i18next";
import {useLazyGetDocsQuery} from "../../store/api/documentApi";
import LoadingSegment from "../LoadingSegment";

const DocsById = ({id, label, type, readOnly}) => {
    const {t} = useTranslation();

    const [getDocsById, {data: documents = [], isLoading, isFetching}] = useLazyGetDocsQuery();

    const getDocs = () => {
        getDocsById({id})
    }

    useEffect(() => {
        id && getDocs()
    }, [id])

    return (
        <LoadingSegment isLoading={isLoading || isFetching}>
            {label && <div>
                <label>
                    <b>{t(label)}</b>
                </label>
            </div>}
            <DocListEdit
                documents={documents || []}
                onChange={getDocs}
                type={type}
                objectId={id}
                readOnly={readOnly}
            />
        </LoadingSegment>
    );
};

export default DocsById;