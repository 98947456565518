import React from 'react';
import {Checkbox, Form} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";

const Facet = ({onChange, value}) => {
    const {t} = useTranslation();

    return (
        <div className="facet-input">
            <Form>
                <div style={{position: 'relative'}}>
                    <Form.Field>
                        <Checkbox
                            checked={!value}
                            onChange={() => onChange(null, {value: null})}
                            label={t('showAll')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            checked={value === 'true'}
                            onChange={() => onChange(null, {value: value === 'true' ? null : 'true'})}
                            label={t('yes')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            checked={value === 'false'}
                            onChange={() => onChange(null, {value: value === 'false' ? null : 'false'})}
                            label={t('no')}
                        />
                    </Form.Field>
                </div>
            </Form>
        </div>
    );
};

export default Facet;
