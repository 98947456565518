import React, {useEffect} from 'react';
import {Button, Form, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FieldComponent from "../fields";
import {SELECT} from "../../constants/fieldTypes";
import {useForm} from "../../hooks/useForm";
import {toast} from "react-toastify";

const MassChangeModal = ({name, open, onClose, columns, ids, changeFieldValue, load}) => {
    const {t} = useTranslation();

    const [form, setValue, setForm] = useForm({});
    const [change, {isLoading, isFetching}] = changeFieldValue();

    useEffect(() => {
        !open && setForm({});
    }, [open])

    const onSave = () => {
        change({
            name,
            value: form.value,
            fieldName: form.fieldForChange?.name,
            ids,
            customFieldId: form.fieldForChange?.customFieldId || null
        })
            .unwrap()
            .then((res) => {
                if (res.isError) {
                    toast.error(t(res.message || 'error'))
                } else {
                    res.message && toast.info(t(res.message));
                    onClose && onClose();
                    load && load();
                }
            })
    }

    const options = columns
        .filter(c => c.canMassChange)
        .map(c => ({
            ...c,
            value: c.customFieldId || c.name
        }))

    const fieldSettings = (name) => {
        return options?.find(o => o.name === name) || {};
    }

    return (
        <Modal
            size='small'
            dimmer="blurring"
            id="massParameterChange"
            open={open}
            onClose={onClose}
            closeOnEscape
            closeOnDimmerClick={false}
            closeIcon
        >
            <Modal.Header>{t("massParameterChange")}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FieldComponent
                        type={SELECT}
                        forSelectValues={options}
                        name='fieldForChange'
                        value={form?.fieldForChange}
                        onChange={(e, {name, value}) => {
                            setValue(e, {
                                name: 'value',
                                value: null
                            })
                            setValue(e, {
                                name,
                                value
                            })
                        }}
                        isDisabled={!options?.length}
                    />
                    <FieldComponent
                        {...fieldSettings(form?.fieldForChange?.value)}
                        value={form?.value}
                        name='value'
                        label='value'
                        placeholder={t('newValue')}
                        isDisabled={!form?.fieldForChange}
                        isRequired={false}
                        onChange={setValue}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onSave}
                    color='green'
                    loading={isLoading || isFetching}
                    disabled={!form.fieldForChange || isLoading || isFetching}
                >
                    {t('saveBtn')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default MassChangeModal;