import React, {useState} from 'react';
import FormModal from "./FormModal";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Form from "../form/Form";
import {Tab} from "semantic-ui-react";
import History from "../history/History";
import {defaultFunc} from "../../utils/stateHelper";
import {useActionMutation} from "../../store/api/gridApi";
import Actions from "../actions/Actions";
import {removeNonUUIDIds, transformArrayToObject, updateValue} from "../../utils/array";
import {useSelector} from "react-redux";
import {gridOrDictionaryFieldsSelector} from "../../store/slices/userSlice";
import DocsById from "../documents/DocsById";

const CreateOrEditModal = ({
                               children,
                               id,
                               dictionaryOrGridName,
                               onClose,
                               onOpen: handleOpen,
                               modalContent,
                               onSave,
                               isDisabled,
                               withHistory,
                               withDocuments,
                               withoutAutoForm,
                               getElement = defaultFunc,
                               getDefaultForm = defaultFunc,
                               saveData = defaultFunc,
                               getFieldSettings = defaultFunc,
                               getCustomFieldSettings = defaultFunc,
                               getFieldSettingsWithId,
                               colsCounts,
                               size,
                               loading,
                               objectId,
                               copyFromId,
                               errorsByGridNames,
                               readOnly,
                               getCustomCols,
                               customFormSections = [],
                               autoValues,
                               preFieldSettings,
                               defaultOpen,
                               withoutBtn,
                               fieldsToClear,
                               ...params
                           }) => {
    const {t} = useTranslation();
    const [getData, {data, isLoading, isFetching}] = getElement();
    const [getDefaultData, {
        data: defaultData,
        isLoading: defaultLoading,
        isFetching: defaultFetching
    }] = getDefaultForm();
    const [saveForm, {isLoading: isLoadingSave}] = saveData();
    const [errors, setErrors] = useState({});
    const [copyData, setCopyData] = useState({});
    const cols = useSelector(state => gridOrDictionaryFieldsSelector(state, dictionaryOrGridName))

    const onOpen = () => {
        handleOpen && handleOpen()
        setErrors({});
        if (id) {
            getData({name: dictionaryOrGridName, id})
        } else {
            getDefaultData({name: dictionaryOrGridName, autoValues})
                .unwrap()
                .then(res => {
                    copyFromId && getData({name: dictionaryOrGridName, id: copyFromId})
                        .unwrap()
                        .then(data => {
                            const copyFields = cols?.filter(c => c.canMassChange).map(c => c.name);
                            const obj = {};

                            copyFields?.forEach(f => {
                                obj[f] = data[f]
                            });

                            setCopyData({
                                ...obj,
                                id: res.id
                            })
                        })
                })
        }
    }

    const save = (form, confirmation) => {
        return saveForm({
            name: dictionaryOrGridName,
            form: removeNonUUIDIds(form),
            confirmation
        })
    }

    const onSaveError = (data) => {
        if (data?.errors) {
            let errs = {};

            errorsByGridNames
                ? errs = transformArrayToObject(data.errors)
                : data.errors.forEach(err => errs[err.name] = err.message);

            setErrors(errs);
        }
        toast.error(data?.message || t('error'))
    }

    const onSaveThen = (data) => {
        if (data.isError) {
            onSaveError(data)
        } else {
            data.message && toast.info(data.message)
        }
        onSave && onSave(data)
    }

    const onSaveCatch = ({data}) => {
        onSaveError(data)
    }

    const setValueWithError = (setValue) => {
        return (e, {name, value, checked}, params = {}) => {
            setValue(null, {name, value, checked});

            const {index, objectName, fieldName} = params;

            const fieldsNamesToClear = fieldsToClear?.[name];
            fieldsNamesToClear?.length && fieldsNamesToClear.forEach(f => {
                setValue(null, {name: f, value: null});
            })

            setErrors(errors => {
                if (errorsByGridNames) {
                    return updateValue(errors, objectName, index, fieldName || name, null)
                } else {
                    return (!!errors[name]) ? {...errors, [name]: null} : errors
                }
            })
        }
    }
    const content = ({form, setValue, ...props}) => (
        !withoutAutoForm
            ? <Form
                getCustomCols={getCustomCols}
                errors={errors}
                form={form}
                setValue={setValueWithError(setValue)}
                name={dictionaryOrGridName}
                modalContent={modalContent}
                colsCounts={colsCounts}
                getFieldSettings={getFieldSettings}
                getCustomFieldSettings={getCustomFieldSettings}
                getFieldSettingsWithId={getFieldSettingsWithId}
                readOnly={readOnly}
                preFieldSettings={preFieldSettings}
                getData={onOpen}
            />
            : modalContent({
                getData: onOpen,
                isCreate: !id,
                getCustomCols,
                getFieldSettings,
                getFieldSettingsWithId,
                getCustomFieldSettings,
                form,
                setValue: setValueWithError(setValue),
                errors,
                name: dictionaryOrGridName,
                colsCounts,
                objectId: id !== objectId && objectId,
                readOnly,
                ...props,
                ...params
            })
    )

    const panes = ({form, setValue}) => {
        const tabs = [];
        if (!withoutAutoForm) {
            tabs.push({
                menuItem: t('information'),
                render: () => <Tab.Pane className='info'>
                    {content({form, setValue})}
                </Tab.Pane>
            })
        }
        if (customFormSections?.length) {
            customFormSections.forEach(customSection => {
                tabs.push({
                    menuItem: t(customSection.name),
                    render: () => <Tab.Pane className='info'>
                        {
                            customSection.render({form, setValue})
                        }
                    </Tab.Pane>
                })
            })
        }
        if (withDocuments) {
            tabs.push({
                menuItem: t('documents'),
                render: () => <Tab.Pane>
                    <DocsById
                        id={form?.id}
                        type={dictionaryOrGridName}
                    />
                </Tab.Pane>
            })
        }
        if (withHistory) {
            tabs.push({
                menuItem: t('history'),
                render: () => <Tab.Pane>
                    <History id={form.id} isLocalDate/>
                </Tab.Pane>
            })
        }
        return tabs;
    }

    const actionsBlock = <Actions
        size='medium'
        name={dictionaryOrGridName}
        actions={data?.actionBtns || []}
        actionFunc={useActionMutation}
        refresh={onClose}
    />

    const contentFunc = (props) => {
        return (withHistory || withDocuments || customFormSections?.length)
            ? <Tab
                className='modal-tabs'
                panes={panes(props)}
            />
            : content(props)
    }

    const initialForm = (copyFromId ? copyData : (id ? data : defaultData)) || {};
    const loadingFlag = isLoading || isLoadingSave || defaultLoading || loading || isFetching || defaultFetching;

    return (
        <FormModal
            defaultOpen={defaultOpen}
            withoutBtn={withoutBtn}
            button={children}
            title={t(`${id ? 'edit' : 'create'}Element`)}
            onOpen={onOpen}
            onSave={save}
            onSaveThen={onSaveThen}
            loading={loadingFlag}
            initialForm={initialForm}
            onSaveCatch={onSaveCatch}
            onClose={onClose}
            isDisabled={isDisabled}
            size={size}
            actionsBlock={actionsBlock}
        >
            {
                contentFunc
            }
        </FormModal>
    );
};

export default CreateOrEditModal;