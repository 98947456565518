import React, {useEffect, useMemo, useState} from 'react';
import {
    useGetCustomColumnsMutation,
    useLazyGetDefaultFormQuery,
    useLazyGetElementsByIdsQuery,
    useLazyGetOrdersQuery
} from "../../../../store/api/gridApi";
import {useSelector} from "react-redux";
import {gridOrDictionaryFieldsSelector, userInfoSelector} from "../../../../store/slices/userSlice";
import {displayModes} from "../../../../constants/options";
import LoadingSegment from "../../../LoadingSegment";
import FormTable from "../../../table/formTable/formTable";
import GridModal from "../GridModal";
import {checkValuesInFields} from "../../../../utils/array";
import {ACTIONS} from "../../../../constants/fieldTypes";

const Orders = ({
                    task,
                    form,
                    setValue,
                    setIgnoreChanges,
                    getFieldSettings,
                    orderIds,
                    readOnly,
                    platformId,
                    getCustomFieldSettings
                }) => {
    const userInfo = useSelector(userInfoSelector);

    const gridName = 'orders';
    const fields = useSelector(state => gridOrDictionaryFieldsSelector(state, gridName, displayModes.CARD));

    const [getOrders, {isLoading, isFetching}] = useLazyGetOrdersQuery();
    const [getByIds, {isLoading: getIsLoading, getIsFetching}] = useLazyGetElementsByIdsQuery();
    const [getCustomCols, {
        data: customCols,
        isLoading: customFieldsIsLoading,
        isFetching: customFieldsIsFetching
    }] = useGetCustomColumnsMutation();

    const [getDefaultData, {isLoading: defaultIsLoading, isFetching: defaultIsFetching}] = useLazyGetDefaultFormQuery();

    useEffect(() => {
        (task.id && !task[gridName]?.length) && getOrders({id: task.id})
            .unwrap()
            .then(res => {
                setValue(null, {
                    name: gridName,
                    value: res
                })

                // нужно для того, чтобы не считало подгрузку заказов за изменение
                // и не выводило подтверждение при закрытии, если пользователь ничего не менял:
                setIgnoreChanges(task.id, res);
            })
    }, [task.id])

    useEffect(() => {
        //добавление заказов из списка orderIds
        if (orderIds?.length && task[gridName] && !checkValuesInFields(task[gridName], 'id', orderIds)) {
            addFromModule(orderIds);
        }
    }, [orderIds, task]);

    useEffect(() => {
        getCustomCols({
            activityId: task?.activityId?.value,
            platformId: task?.platformId?.value || (userInfo?.currentPlatform?.value || null),
            name: gridName
        })
    }, [task?.activityId, task?.platformId]);

    const setFlagDelete = (i, name) => {
        setValue(null, {
            name: gridName,
            value: (task[gridName][i]?.id && !Number.isInteger(task[gridName][i].id))
                ? task[gridName].map((t, index) => ({...t, [name]: (i === index) ? true : t[name]}))
                : task[gridName].filter((v, index) => index !== i)
        })
    }
    const add = () => {
        getDefaultData({
            name: gridName
        })
            .unwrap()
            .then((res) => {
                setValue(null, {
                    name: gridName,
                    value: [...(task[gridName] || []), {...res, id: res.id || Date.now()}]
                })
            })
    }

    const hiddenFlags = ['isDeleted', 'returnedToModule']

    const actions = (row) => {
        return [
            {
                name: 'delete',
                visible: true,
                icon: 'trash',
                onClick: () => setFlagDelete(row.i, hiddenFlags[0])
            },
            {
                name: 'returnToOrdersModule',
                visible: !!row.id && !Number.isInteger(row.id),
                icon: 'external',
                onClick: () => setFlagDelete(row.i, hiddenFlags[1])
            },
        ]
    }

    const [gridModalOpen, setGridOpenModal] = useState(false);

    const footerBtns = [
        {
            name: 'addOrder',
            onClick: add
        },
        {
            name: 'pickFromOrdersModule',
            onClick: () => setGridOpenModal(true)
        }
    ]

    const addFromModule = (rowIds) => {
        getByIds({name: gridName, ids: rowIds})
            .unwrap()
            .then(res => {
                (res && res.length) && setValue(null, {
                    name: gridName,
                    value: [...(task[gridName] || []), ...res]
                })
            })

    }

    const cols = [...(fields || []), ...(customCols || []).map(c => ({...c, isCustom: true}))]

    const loading = isLoading || isFetching || customFieldsIsLoading || customFieldsIsFetching || getIsLoading || getIsFetching || defaultIsLoading || defaultIsFetching;

    const columnsForTable = readOnly ? cols : cols.concat({type: ACTIONS, name: ' '});
    const actionsForTable = !readOnly && actions;
    const activityId = task.activityId?.value;

    const preFilters = useMemo(() => ({
        activityId: task.activityId?.value,
        status: 'new'
    }), [task.activityId?.value]);

    return (
        <LoadingSegment
            isLoading={loading}
        >
            <FormTable
                className='modal-table'
                rows={task[gridName] || []}
                name={gridName}
                setValue={setValue}
                columns={columnsForTable}
                actions={actionsForTable}
                footerBtns={footerBtns}
                hiddenFlags={hiddenFlags}
                getFieldSettings={getFieldSettings}
                getCustomFieldSettings={getCustomFieldSettings}
                readOnly={readOnly}
                platformId={platformId}
                activityId={activityId}
                virtualization
            />
            <GridModal
                name={gridName}
                onClose={() => setGridOpenModal(false)}
                open={gridModalOpen}
                form={task}
                preFilters={preFilters}
                disabledFilters={['activityId', 'status']}
                onSave={addFromModule}
            />
        </LoadingSegment>
    );
};

export default Orders;