import React, {useEffect, useRef, useState} from 'react';
import {MultiGrid, ScrollSync} from 'react-virtualized';
import {useTranslation} from "react-i18next";
import {
    DATE_TIME,
    MULTISELECT,
    MULTISELECT_WITH_ALL,
    MULTISELECT_WITH_ALL_PLUS_EMPTY,
    SCHEDULE,
    SELECT,
    STATE, TEXTAREA
} from "../../constants/fieldTypes";
import {getFiltersForField} from "../../constants/utils";
import FieldComponent from "../fields";

const VirtualizationTable = ({
                                 columns,
                                 className = '',
                                 rows,
                                 height = 400,
                                 width = 400,
                                 actions,
                                 setCustomField,
                                 setFieldValue,
                                 getCustomValue,
                                 platformId,
                                 fieldSettings
                             }) => {
    const {t} = useTranslation();
    const ref = useRef();

    const isFirefox = navigator.userAgent.includes('Firefox');

    const cellRenderer = ({columnIndex, key, rowIndex, style, isLast}) => {
        const isFirstRow = rowIndex === 0;
        const isPhantom = !isLast && columnIndex === columnCount - 1;
        if (isPhantom) {
            return <div style={{
                ...style,
                width: style.width - 10
            }}/>
        }
        return isFirstRow
            ? topRow({
                key, style, columnIndex
            })
            : cell({
                key, style, columnIndex, rowIndex, isLast
            })
    }

    const topRow = ({key, style, columnIndex}) => {
        const isFirstCol = columnIndex === 0;
        const text = columns[columnIndex].name

        return <span
            key={key}
            style={style}
            className={`virtualization-table__col ${isFirstCol ? 'virtualization-table__col_first' : ''} virtualization-table__row_first`}
        >
            {t(text)}
        </span>
    }

    const stateName = columns?.find(f => f.type === STATE)?.name;
    const onChange = (e, o, field, row) => {
        field.isCustom ? setCustomField(field, o, row.id) : setFieldValue(e, o, row.id)
    }

    const cell = ({key, style, rowIndex, columnIndex, isLast}) => {
        const isFirstCol = columnIndex === 0;

        const field = columns[columnIndex];
        const row = rows[rowIndex - 1];
        const isOneOfLast = rowIndex > (rows.length - (rows.length > 4 ? 3 : 1)) && rows.length > 2;
        const actionsCol = () => actions(row);
        const isPhantom = rowIndex > rows.length;

        return (isPhantom
            ? <span style={style}/>
            : <span
                key={key}
                style={style}
                className={`virtualization-table__col ${isFirstCol ? 'virtualization-table__col_first' : ''} ${isLast ? 'virtualization-table__col_last' : ''}`}
            >
            <FieldComponent
                {...field}
                upward={isOneOfLast}
                isTableStyle
                actions={actionsCol}
                noLabel
                forSelectValues={field.refValuesByField ? row[field.refValuesByField] : field.forSelectValues}
                key={field.name}
                onChange={(e, o) => onChange(e, o, field, row)}
                value={field.type === SCHEDULE ? row : (field.isCustom ? getCustomValue(row, field.customFieldId) : row[field.name])}
                label={field.displayNameKey}
                disabledAll={field.type === MULTISELECT_WITH_ALL && !row[`${field.name}AllAvailable`]}
                fieldSettings={fieldSettings}
                stateValue={stateName && !Number.isInteger(row?.id) && row[stateName]?.value}
                filter={getFiltersForField(field, {}, platformId)}
            />
        </span>)
    }

    const cellRendererLast = ({columnIndex, key, rowIndex, style, ...r}) => {
        return cellRenderer({
            key, columnIndex: columnCount - 1, rowIndex, style, isLast: true
        })
    }

    const columnCount = columns?.length;
    const rowCount = rows?.length + 1;

    const colWidth = ({index}) => {
        if (
            index === columnCount - 1
        ) {
            // правка отображения ширины для браузеров кроме Firefox,
            // в котором скролл отображается иначе и не занимает доп пиксели
            return !isFirefox ? 90 : 100;
        }
        const field = columns[index];
        if ([STATE, SELECT, MULTISELECT, MULTISELECT_WITH_ALL, MULTISELECT_WITH_ALL_PLUS_EMPTY, DATE_TIME].includes(field.type)) {
            return 200;
        }
        return 150;
    };

    const withTextArea = columns.find(c => c.type === TEXTAREA);

    const [plusHeightRow, setPlusHeightRow] = useState(0);

    const rowHeight = ({index}) => {
        const plusRow = plusHeightRow > 0;
        if (plusRow && index === rowCount) return plusHeightRow;
        if (index === 0) return 50;
        return withTextArea ? 100 : 50;
    };

    const plusHeight = () => {
        return height - 10 - rowHeight({index: 0}) - ((rowCount - 1) * rowHeight({index: 1}));
    }

    useEffect(() => setPlusHeightRow(plusHeight()), [height, rowCount, rowHeight])

    useEffect(() => {
        ref.current && ref.current.recomputeGridSize();
    }, [plusHeightRow]);

    return (
        <div
            className={`virtualization-table ${className}`}
            style={{
                width: `${width}px`,
                height: `${height}px`,
            }}
        >
            <ScrollSync>
                {({onScroll, scrollTop}) => {

                    return (
                        <>
                            <MultiGrid
                                onScroll={onScroll}
                                scrollTop={scrollTop}
                                ref={ref}
                                cellRenderer={cellRenderer}
                                columnCount={columnCount}
                                rowCount={plusHeightRow > 0 ? rowCount + 1 : rowCount}
                                columnWidth={colWidth}
                                height={height}
                                rowHeight={rowHeight}
                                width={width}
                                fixedRowCount={1}
                            />

                            <MultiGrid
                                onScroll={onScroll}
                                scrollTop={scrollTop}
                                cellRenderer={cellRendererLast}
                                columnCount={1}
                                rowCount={rowCount}
                                columnWidth={100}
                                height={height - 10}
                                rowHeight={rowHeight}
                                width={100}
                                fixedRowCount={1}
                            />
                        </>
                    )
                }}
            </ScrollSync>
        </div>
    );
};

export default VirtualizationTable;
