import React, {useEffect} from 'react';
import './styles.scss';
import Form from "../../components/form/Form";
import {useForm} from "../../hooks/useForm";
import {gateReviewsFilters, requestFiltersForEnabled} from "../../constants/additional";
import {GATE_REVIEWS_NAME} from "../../constants/options";
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useExportMutation, useShowMutation} from "../../store/api/gateReviews";
import DataBlock from "../../components/dataBlock/dataBlock";
import {useSelector} from "react-redux";
import {additionalItemByNameSelector, userInfoSelector} from "../../store/slices/userSlice";
import GateReviewsTable from "./GateReviewsTable";
import {formatDate} from "../../utils/dateTime";
import Legend from "./legend";
import HiddenCorridors from "./HiddenCorridors";

const GateReviews = () => {
    const {t} = useTranslation();
    const userInfo = useSelector(userInfoSelector);
    const idKey = 'gates-table';
    const gateReviewsData = useSelector(state => additionalItemByNameSelector(state, GATE_REVIEWS_NAME));
    const [form, setValue, setForm] = useForm({});
    const [getData, {data, isLoading, isFetching}] = useShowMutation();

    const loading = isLoading || isFetching;

    const showData = () => {
        getData(form);
    }

    useEffect(() => {
        setValue(null, {
            name: gateReviewsFilters[3].name,
            value: formatDate(new Date())
        })
    }, []);

    useEffect(() => {
        userInfo?.currentPlatform && setValue(null, {
            name: gateReviewsFilters[0].name,
            value: userInfo.currentPlatform?.value
        })
        setValue(null, {
            name: gateReviewsFilters[1].name,
            value: userInfo.currentActivity?.value
        })
    }, [userInfo?.currentPlatform, userInfo?.currentActivity]);

    useEffect(() => {
        setValue(null, {
            name: gateReviewsFilters[2].name,
            value: ''
        })
    }, [form[gateReviewsFilters[0].name]]);

    const disabledByName = (name) => {
        return !(requestFiltersForEnabled[name] || [])?.every(n => !!form[n])
    }

    const ro = new ResizeObserver(entries => {
        for (let entry of entries) {
            document.getElementById(idKey).style.height = `${entry.contentBoxSize?.[0].blockSize - 75}px`
        }
    })

    useEffect(() => {
        ro.observe(document.querySelector('.data-block'))
        return () => ro.disconnect();
    }, [])

    const autoComplete = (values, name) => {
        if (
            [
                gateReviewsFilters[0].name,
                gateReviewsFilters[1].name,
            ].includes(name)
            && (
                name === gateReviewsFilters[1].name || (!userInfo.currentPlatform?.value && !form[name])
            )
        ) {
            const isActivity = name === gateReviewsFilters[1].name;
            const activityValue = userInfo.currentPlatform?.value === form[gateReviewsFilters[0].name] && userInfo.currentActivity?.value;
            const value = (isActivity && activityValue) ? activityValue : values?.length && values[0].value;

            setValue(null, {
                name: name,
                value
            })
        }
    }

    const hiddenCorridorsBtn = data?.hiddenCorridors?.length
        ? <HiddenCorridors
            array={data.hiddenCorridors}
        >
            <div className='gate-reviews__hidden-corridors-btn'>
                {t('hiddenCorridorsBtnLabel')}
            </div>
        </HiddenCorridors>
        : null;

    return (
        <div className='gate-reviews'>
            <div className='gate-reviews__top-block'>
                <Legend/>
                <div className='gate-reviews__filters'>
                    <Form
                        form={form}
                        fields={gateReviewsFilters.map(f => ({
                            ...f,
                            isDisabled: disabledByName(f.name),
                            requiredFilters: requestFiltersForEnabled[f.name]
                        }))}
                        setValue={setValue}
                        colsCounts={3}
                        readOnly={false}
                        parentForm={{pageName: gateReviewsData.name}}
                        onLoadValues={autoComplete}
                    />
                    <div className='gate-reviews__btn'>
                        <Button
                            color='blue'
                            onClick={showData}
                            loading={loading}
                            disabled={disabledByName('button') || loading}
                        >
                            {t('showGateOccupancy')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className='gate-reviews__main'>
                <DataBlock
                    filters={form}
                    canExport={gateReviewsData.canExportToExcel && !disabledByName('button')}
                    label={gateReviewsData.name}
                    excelFunc={useExportMutation}
                    content={<GateReviewsTable
                        idKey={idKey}
                        gates={data?.gates || []}
                    />}
                    headerBlock={hiddenCorridorsBtn}
                />
            </div>
        </div>
    );
};

export default GateReviews;