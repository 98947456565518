import React, {useEffect, useState} from 'react';
import LoadingSegment from "../LoadingSegment";
import ControlledModal from "./ControlledModal";
import {useForm} from "../../hooks/useForm";

const FormModal = ({
                       defaultOpen,
                       button,
                       title,
                       loading,
                       error,
                       children,
                       saveButtonName,
                       initialForm,
                       onSave,
                       onSaveThen,
                       onSaveCatch,
                       withoutSave,
                       withoutCancel,
                       onClose,
                       onOpen,
                       size,
                       isScrolling,
                       isDisabled,
                       actionBtns,
                       actionsBlock,
                       withoutBtn
                   }) => {
    const [ignoreChanges, setIgnoreChanges] = useState({});
    const [form, setValue, setForm, {isChanged}] = useForm(initialForm, ignoreChanges);

    useEffect(() => {
        initialForm && setForm(initialForm);
    }, [initialForm])

    const handleClose = () => {
        setForm(initialForm);
        onClose && onClose();
    };

    const handleSave = (confirmation) => {
        return onSave(form, confirmation);
    };

    const handleOpen = () => {
        onOpen && onOpen();
    };

    const content = (onOpen, onClose) => typeof children === 'function'
        ? children({form, setValue, onOpen, onClose, setIgnoreChanges})
        : children;

    return (
        <ControlledModal
            withoutBtn={withoutBtn}
            title={title}
            error={error}
            defaultOpen={defaultOpen}
            saveButtonName={saveButtonName || 'saveBtn'}
            button={button}
            isConfirmForCloseModal={isChanged}
            onSave={handleSave}
            onSaveThen={onSaveThen}
            onSaveCatch={onSaveCatch}
            onClose={handleClose}
            onOpen={handleOpen}
            size={size}
            isScrolling={isScrolling}
            isDisabled={isDisabled}
            actionsBtns={actionBtns && actionBtns(form)}
            withoutSave={withoutSave}
            withoutCancel={withoutCancel}
            isLoading={loading}
            actionsBlock={actionsBlock}
            saveButtonDisabled={!isChanged}
        >
            {
                (onOpen, onClose) => <LoadingSegment isLoading={loading}>
                    {content(onOpen, onClose)}
                </LoadingSegment>
            }
        </ControlledModal>
    );
};

export default FormModal;