export const searchParametersFromUrl = () => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);

    const paramsObject = {};
    for (const [key, value] of searchParams.entries()) {
        paramsObject[key] = value;
    }

    return paramsObject;
}