import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {gridsSelector, userInfoSelector} from "../../store/slices/userSlice";
import {securityServiceGridName} from "../../constants/security";
import {Menu} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import './style.scss';
import FieldMatrixTable from "./fieldMatrixTable";
import FieldComponent from "../../components/fields";
import {SELECT} from "../../constants/fieldTypes";
import {useForm} from "../../hooks/useForm";
import {grids} from "../../constants/grids";

const FieldMatrixPage = () => {
    const {t} = useTranslation();
    const allGridsArray = useSelector(gridsSelector)
    const gridsArray = allGridsArray.map(v => v.name).filter(v => v !== securityServiceGridName);
    const userInfo = useSelector(userInfoSelector);

    const [activeItem, setActiveItem] = useState(null);

    const [formData, setValue, setForm] = useForm({
        platformId: null,
        activityId: null,
        roleId: null
    })

    const gridsWithoutActivity = [grids[0].name];

    const form = useMemo(() => ({
        ...formData,
        activityId: gridsWithoutActivity.includes(activeItem) ? null : formData.activityId
    }), [formData, activeItem])

    const setPlatformId = (...data) => {
        setValue(...data)
        setValue(null, {name: 'activityId', value: null})
    }

    useEffect(() => {
        if (userInfo) {
            const {fieldMatrixDefault, currentPlatform, currentActivity} = userInfo;
            setForm({
                platformId: fieldMatrixDefault?.platform || currentPlatform || null,
                activityId: fieldMatrixDefault?.activity || currentActivity || null,
                roleId: fieldMatrixDefault?.role || null
            })
        }
    }, [userInfo])

    useEffect(() => {
        (gridsArray && gridsArray.length && !activeItem) && setActiveItem(gridsArray[0])
    }, [gridsArray])

    const {fieldMatrixDefault} = (userInfo || {});

    return (
        <div className='table-block field-matrix'>
            <Menu>
                {
                    gridsArray.map(gridName => (
                        <Menu.Item
                            active={gridName === activeItem}
                            key={gridName}
                            onClick={() => setActiveItem(gridName)}
                        >
                            {t(gridName)}
                        </Menu.Item>
                    ))
                }
                <Menu.Item className='field-matrix__menu-item'>
                    <FieldComponent
                        noLabel
                        type={SELECT}
                        value={form.roleId}
                        name='roleId'
                        dictionaryName='roles'
                        onChange={setValue}
                        clearable={!fieldMatrixDefault?.role}
                        nullIsAll
                    />
                </Menu.Item>
                <Menu.Item className='field-matrix__menu-item'>
                    <FieldComponent
                        noLabel
                        type={SELECT}
                        value={form.platformId}
                        name='platformId'
                        dictionaryName='platforms'
                        onChange={setPlatformId}
                        clearable={!fieldMatrixDefault?.platform}
                        nullIsAll
                    />
                </Menu.Item>
                {!gridsWithoutActivity.includes(activeItem) && <Menu.Item className='field-matrix__menu-item'>
                    <FieldComponent
                        isDisabled={!form.platformId}
                        noLabel
                        type={SELECT}
                        value={form.activityId}
                        name='activityId'
                        dictionaryName='activities'
                        onChange={setValue}
                        clearable={!fieldMatrixDefault?.activity}
                        nullIsAll
                        filter={{
                            platformId: form.platformId?.value
                        }}
                    />
                </Menu.Item>}
            </Menu>
            <FieldMatrixTable
                gridName={activeItem}
                entityType={allGridsArray.find(v => v.name === activeItem)?.entityType}
                form={form}
            />
        </div>
    );
};

export default FieldMatrixPage;