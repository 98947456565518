import React from 'react';
import {Button, Form, Icon, Input, Message, Modal} from "semantic-ui-react";
import {useTranslation} from 'react-i18next';
import {useForm} from "../../../hooks/useForm";
import {useForgotPasswordMutation} from "../../../store/api/userApi";
import {toast} from "react-toastify";

const ForgotPasswordModal = ({open, toggleModal}) => {
    const {t} = useTranslation();

    const initialForm = {
        email: "",
        isSended: false
    };
    const [form, setValue, setForm] = useForm(initialForm);

    const onClose = () => {
        setForm(initialForm);
        toggleModal();
    }

    const {email, isSended} = form;

    const validateEmail = (email) => {
        if (email === '' || email === null)
            return false;

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    const [forgotPassword, {data, isLoading, isError}] = useForgotPasswordMutation();

    const send = () => {
        forgotPassword(email)
            .unwrap()
            .then(res => {
                if (res) setValue(null, {name: 'isSended', value: true})
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.error)
            })
    }

    return (
        <Modal
            dimmer="blurring"
            size={"small"}
            open={open}
            onClose={onClose}
            closeIcon
        >
            <Modal.Header>{t("passRecover")}</Modal.Header>
            <Modal.Content>
                {isSended ?
                    <Message positive>
                        {t("emailIsSent")}
                    </Message>
                    :
                    <Form>
                        <Form.Field>
                            <Message>
                                {t("forgotPassMsg")}
                            </Message>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={t('email')} iconPosition='left'>
                                <Icon name='mail'/>
                                <Input
                                    name={'email'}
                                    value={email}
                                    onChange={setValue}
                                    placeholder={'email@example.com'}
                                />
                            </Form.Input>
                        </Form.Field>
                    </Form>}

            </Modal.Content>
            <Modal.Actions>
                {isSended ?
                    <Button onClick={onClose}>{t('close')}</Button> :
                    <Button
                        disabled={!validateEmail(email) || isLoading}
                        onClick={send}>
                        {t('sendButton')}
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );
};

export default ForgotPasswordModal;