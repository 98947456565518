import React from 'react';
import {Button, Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const TableActions = ({footerBtns}) => {
    const {t} = useTranslation();

    return (
        <div className='margin-top-7'>
            {
                footerBtns && footerBtns.map(btn => <Button
                    key={btn.name}
                    onClick={btn.onClick}
                    size='mini'
                >
                    {btn.icon && <Icon name={btn.icon}/>} {t(btn.name)}
                </Button>)
            }
        </div>
    );
};

export default TableActions;