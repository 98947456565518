import React, {useState} from 'react';
import {Button, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useLazyGetProfileQuery, useSaveProfileMutation} from "../../store/api/userApi";
import ChangePasswordModal from "./ChangePasswordModal";
import FormModal from "./FormModal";
import FormComponent from "../form/Form";
import {PROFILE_NAME} from "../../constants/profile";
import Checkboxes from "../fields/Checkboxes";
import {useActions} from "../../hooks/actions";

const ProfileModal = ({children}) => {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [getProfile, {data, isLoading}] = useLazyGetProfileQuery();
    const [saveProfile, {isLoading: saveIsLoading}] = useSaveProfileMutation();
    const {setTrigger} = useActions();

    const onOpen = () => {
        setErrors({});
        getProfile();
    }
    
    const onSave = (form) => {
        return saveProfile({
            form
        })
    }

    const onSaveThen = (res) => {
        if (res.isError) {
            let errs = {};
            res.errors.forEach(err => errs[err.name] = err.message);
            setErrors(errs)
        } else {
            setTrigger();
        }
    }

    const setValueWithError = (setValue) => {
        return (e, {name, value, checked}) => {
            setValue(null, {name, value, checked});
            setErrors(errors => ({
                ...errors,
                [name]: null
            }))
        }
    }

    const forSelectValues = [
        //todo: где брать набор галочек для уведомлений?
    ]

    const panes = ({form, setValue}) => {
        const tabs = [
            {
                menuItem: t('information'),
                render: () => <Tab.Pane className='info'>
                    <FormComponent
                        errors={errors}
                        form={form}
                        setValue={setValueWithError(setValue)}
                        section={PROFILE_NAME}
                        colsCounts={2}
                    />
                    <div className='margin-left-5'>
                        <ChangePasswordModal>
                            <Button
                                icon='lock'
                                color='green'
                                content={t('changePassword')}
                            />
                        </ChangePasswordModal>
                    </div>
                </Tab.Pane>
            },
            {
                menuItem: t('notificationsSetting'),
                render: () => <Tab.Pane>
                    <Checkboxes
                        name={'notificationsSetting'}
                        value={form.notificationsSetting}
                        error={errors.notificationsSetting}
                        setValue={setValue}
                        forSelectValues={forSelectValues}
                    />
                </Tab.Pane>
            },
            {
                menuItem: t('backlightsSetting'),
                render: () => <Tab.Pane>

                </Tab.Pane>
            }
        ];
        return tabs;
    }

    return <FormModal
        onOpen={onOpen}
        onSave={onSave}
        onSaveThen={onSaveThen}
        button={children}
        title={t(PROFILE_NAME)}
        initialForm={data}
        loading={isLoading || saveIsLoading}
    >
        {
            (props) => <Tab
                className='modal-tabs'
                panes={panes(props)}
            />
        }
    </FormModal>
}

export default ProfileModal;