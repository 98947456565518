import React, {useEffect} from 'react';
import {useLazyGetElementQuery} from "../../../store/api/dictionaryApi";
import LoadingSegment from "../../LoadingSegment";
import {useSelector} from "react-redux";
import {dictionaryOrGridEditPermission} from "../../../store/slices/userSlice";
import {dictionarySettingsByName, eventStopPropagation} from "../../../constants/utils";
import {useTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";
import DocsViewer from "./DocsViewer";
import NoData from "./NoData";

const ObjectViewer = ({value, source, onOpen, onClick, onClose}) => {
    const {t} = useTranslation();
    const [getData, {data, isLoading, isFetching, error}] = useLazyGetElementQuery();

    useEffect(() => {
        getData({name: source, id: value.value})
    }, []);

    const fields = dictionarySettingsByName(source)?.fieldsForViewer || [];
    const canEdit = useSelector(state => dictionaryOrGridEditPermission(state, source));

    const getValueName = (value) => (typeof value === 'object' ? (value?.name) : value)
        || <NoData/>;

    const notObjValue = (field, label) => {
        if (field === 'documents') {
            return <DocsViewer
                onClick={onClick}
                onClose={onClose}
                className='object-viewer__docs'
                id={value.value}
                viewIds={data[`${source.slice(0, -1)}Documents`]}
            />
        }
        return <div className='object-viewer__value'>
            <div className='object-viewer__title'>
                {t(label || field)}
            </div>
            {getValueName(data[field])}
        </div>
    }

    const valueView = (field, label, i) => {
        return <div key={i} className='object-viewer__value-block'>
            {
                typeof field !== "object"
                    ? notObjValue(field, label)
                    : <div className='object-viewer__obj'>
                        <div className='object-viewer__title'>
                            {t(field.name)}
                        </div>
                        <div className='object-viewer__obj-values'>
                            {field.fields?.map((f, i) => valueView(...f, i))}
                        </div>
                    </div>
            }
        </div>
    }

    const editBtn = () => {
        return (
            <Button
                className='object-viewer__edit-btn'
                size='mini'
                icon='edit'
                onClick={e => {
                    eventStopPropagation(e)
                    onOpen();
                }}
            />
        )
    }

    return (
        <LoadingSegment
            className='object-viewer'
            isLoading={isLoading || isFetching}
        >

            {canEdit && onOpen && editBtn()}
            {
                data
                    ? fields.map((field, i) => {
                        return valueView(field, null, i)
                    })
                    : <NoData text={error?.data?.message}/>
            }
        </LoadingSegment>
    );
};

export default ObjectViewer;