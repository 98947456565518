export const DATE = 'Date'
export const DATE_TIME = 'DateTime'
export const TIME = 'Time'
export const BOOLEAN = 'Boolean'
export const PHONE_NUMBER = 'PhoneNumber'
export const TEXT = 'Text'
export const NUMBER = 'Number'
export const INTEGER = 'Integer'
export const PASSWORD = 'Password'
export const TEXTAREA = 'BigText'
export const EMAIL = 'Email'
export const KEY_TEXT = 'KeyText'
export const ENUM = 'Enum'
export const SELECT = 'Select'
export const STATE = 'State'
export const MULTISELECT = 'MultiSelect'
export const MULTISELECT_WITH_ALL = 'MultiSelectWithAll'
export const MULTISELECT_WITH_ALL_PLUS_EMPTY = 'MultiSelectWithAllPlusEmpty'
export const SECURITY_STATE = 'SecurityState'
export const IS_ACTIVE = 'IsActive'
export const TIMESLOT = 'Timeslot'
export const ACTIONS = 'Actions'
export const COLOR = 'Color'
export const HIDDEN_TYPE = 'Hidden'
export const FILLING_SELECT = 'FillingSelect'
export const SCHEDULE = 'Schedule'

export const customFieldsName = 'customFields'

export const arrayTypes = [
    MULTISELECT,
    MULTISELECT_WITH_ALL,
    FILLING_SELECT
]

export const disableRemoveSelectedFieldNames = ['selectValues']