import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Icon, Loader, Modal} from "semantic-ui-react";
import Viewer from "react-viewer";
import {useViewFileQuery} from "../../store/api/fileApi";

const DocView = ({document = {}, children, onClick, onClose}) => {
    const {t} = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const name = document.fileName || "";
    const extension = name.substring(name.lastIndexOf(".") + 1);
    const isImage = ["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase());

    const {data, isError, isLoading} = useViewFileQuery(
        {id: document.fileId},
        {skip: !document.fileId}
    );

    const handleOpen = () => {
        onClick && onClick();
        setModalOpen(true);
    }

    const handleClose = () => {
        onClose && onClose();
        setModalOpen(false);
    }

    const image = isImage && !isLoading && !isError
        ? <div
            className="image-container"
            style={{background: `url(${data}) no-repeat center center`}}
            onClick={handleOpen}
        />
        : (
            <div className="image-container">
                <a target="_blanc" href={data}>{
                    isLoading
                        ? <Loader size='mini' active/>
                        : <Icon
                            name={isError ? 'delete' : 'file outline'}
                        />
                }</a>
            </div>
        );

    const inner = (
        <div>
            {image}
            <div className="file-info">
                <u>{document.documentTypeId?.name?.length > 19 ? `${document.documentTypeId?.name.slice(0, 15)}...` : document.documentTypeId?.name}</u>
                <div>{document.fileName?.length > 15 ? `${document.fileName.slice(0, 12)}..${document.fileName.slice(-5, -1)}` : document.fileName}</div>
                {document.isExpired
                    ? <div className="file-info-red">{document.date}<p>{t("Has expired")}</p></div>
                    : <div>{document.date}</div>}
            </div>
        </div>
    );

    return document && document.fileId
        ? <div className="file-item">
            <Modal
                className="top-layer"
                trigger={inner}
                open={modalOpen}
                onClose={handleClose}
                closeOnEscape
                closeOnDimmerClick={false}
                basic
            >
                <Modal.Content>
                    <Viewer
                        visible={true}
                        loop={false}
                        showTotal={false}
                        changeable={false}
                        scalable={false}
                        zoomSpeed={1}
                        onClose={handleClose}
                        images={[{src: data}]}
                    />
                </Modal.Content>
            </Modal>
            {children}
        </div>
        : <div className="file-item file-item-add">
            {children}
        </div>
};

export default DocView;