import React, {useEffect, useState} from 'react';
import EditTable from "../table";
import TableRow from "../components/tableRow";

const EditAndCreateTable = ({columns, footerBtns: initFooterBtns, rows: initRows, setValue, mergeFieldName, autoValues}) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows((initRows || []).map((r, i) => ({
            ...r,
            id: r.id || i
        })));
    }, [initRows]);

    const addRow = () => {
        setRows(rows => [...(rows || []), {id: Date.now(), ...(autoValues || {})}]);
    }

    const footerBtns = (initFooterBtns || []);

    footerBtns.unshift({
        name: 'add',
        icon: 'plus',
        onClick: addRow
    })

    const [dataForAll, setDataForAll] = useState({})

    const setRow = (i, row) => {
        let newRows = [...rows];
        const index = newRows.findIndex(r => r.id === i)

        const data = getMergeFieldData(newRows[index]);
        const dataForAllValue = dataForAll[data?.all[0]?.id];

        newRows[index] = row;

        if (dataForAllValue) {
            newRows = newRows.map(r => {
                if (data?.all?.map(d => d.id).includes(r.id)) {
                    return {
                        ...r,
                        [dataForAllValue.name]: dataForAllValue.value
                    }
                } else return r
            });

            setDataForAll(dataForAll => ({...dataForAll, [data?.all[0].id]: null}));
        }

        setValue(newRows)
    }

    const deleteRow = (i) => {
        const newRows = [...rows];
        setValue(newRows.filter((r) => (r.id !== i)))
    }

    const [editList, setEditList] = useState([])

    const getMergeFieldData = (row) => {
        const all = rows.filter(r => r[mergeFieldName]?.value === row[mergeFieldName]?.value);
        const count = all?.length
        const isEditId = editList.filter(id => all.find(r => r.id === id));

        return {
            fieldEdit: !!isEditId?.length,
            count,
            isFirst: (all?.length && all[0].id === row.id),
            name: mergeFieldName,
            all,
            setForAll: (data) => all && all[0] && setDataForAll(dataForAll => {
                return {
                    ...dataForAll,
                    [all[0]?.id]: data
                }
            })
        }
    }

    function groupRows(arr) {
        let result = {};
        arr.forEach(item => {
            let val = item[mergeFieldName]?.value;
            if (!result[val]) {
                result[val] = [];
            }
            result[val].push(item);
        });
        return Object.values(result).flat();
    }

    const sortRows = mergeFieldName ? groupRows(rows) : rows;

    return (
        <EditTable
            isViewStyle
            className='edit-create-table min-size-table'
            rowComponent={({index, row}) => (
                <TableRow
                    setEditList={setEditList}
                    mergeFieldData={mergeFieldName && row[mergeFieldName] && getMergeFieldData(row)}
                    key={row.id}
                    columns={columns}
                    row={{...row}}
                    setRow={setRow}
                    deleteRow={deleteRow}
                    initIsEdit={(index === (rows.length - 1)) && !Object.keys(row).find(k => ![...Object.keys(autoValues || {}), 'id'].includes(k) && row[k])}
                />
            )}
            footerBtns={footerBtns}
            rows={sortRows}
            columns={columns}
        />
    );
};

export default EditAndCreateTable;