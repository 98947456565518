import React from "react";
import Roles from "../components/dictionary/modals/createOrEditModals/Roles";
import GlobalSettingsPage from "../pages/globalSettings/globalSettingsPage";
import CompanySchedule from "../components/dictionary/modals/createOrEditModals/CompanySchedule";
import ActivityHandlerSpecialFields from "../components/dictionary/modals/createOrEditModals/ActivityHandlerSpecialFields";
import {ACTIONS, BOOLEAN, DATE_TIME, NUMBER, SCHEDULE, SELECT} from "./fieldTypes";
import GateButton from "../components/dictionary/modals/createOrEditModals/GateButton";
import Gate from "../components/dictionary/modals/createOrEditModals/Gate";
import CreateOpenApiTokenButton from "../components/dictionary/modals/createOrEditModals/CreateOpenApiTokenButton";
import Driver from "../components/dictionary/modals/createOrEditModals/Driver";
import Backlights from "../components/dictionary/modals/createOrEditModals/Backlights";

export const dictionaries = [
    {
        name: 'passRequests',
        main: true,
        openModalOnClick: false,
    },
    {
        name: 'globalSettings',
        withoutSearch: true,
        customComponent: (props) => <GlobalSettingsPage {...props}/>,
        filters: false
    },
    {
        name: 'users',
        modalContentComponent: (props) => <CreateOpenApiTokenButton {...props}/>,
    },
    {
        name: 'companies',
        size: 'large',
        customTabs: [
            {
                name: 'companySchedule',
                render: (props) => <CompanySchedule {...props}/>
            }
        ]
    },
    {
        name: 'platforms',
        modalContentComponent: (props) => <GateButton {...props}/>
    },
    {
        name: 'activities',
        modalContentComponent: (data) => <ActivityHandlerSpecialFields {...data}/>
    },
    {
        name: 'roles',
        modalContentComponent: (data) => <Roles {...data}/>
    },
    {
        name: 'gates',
        size: 'large',
        colsCounts: 3,
        customTabs: [
            {
                name: 'schedule',
                render: (props) => <Gate {...props}/>
            }
        ]
    },
    {
        name: 'backlightConstructor',
        colsCounts: 3,
        customTabs: [
            {
                name: 'displayConditions',
                render: (props) => <Backlights {...props}/>
            }
        ],
        fieldsToClear: {
            applicableToObject: ['backlightConstructor']
        }
    },
    {
        name: 'drivers',
        modalContentComponent: (data) => <Driver {...data}/>,
        fieldsForViewer: [
            'phone',
            {
                name: 'passport',
                fields: [
                    ['passportSeriesAndNumber', 'passSeriesAndNumber'],
                    ['passportDate', 'passDate'],
                    ['passportDepartmentCode', 'passDepartmentCode'],
                    ['passportDepartment', 'passDepartment']
                ]
            },
            'documents'
        ]
    },
    {
        name: 'trailers',
        fieldsForViewer: [
            'description',
            'vehicleTypeId'
        ]
    },
    {
        name: 'trucks',
        fieldsForViewer: [
            'description',
            'vehicleTypeId'
        ]
    }
]

export const transportDictionaryNames = ['trailers', 'trucks'];

export const schedulesCols = [
    {
        displayNameKey: 'activityId',
        name: 'activityId',
        source: 'activities',
        type: SELECT,
        isRequired: true
    },
    {
        displayNameKey: 'type',
        name: 'type',
        source: 'companies/visitTaskType',
        type: SELECT,
        isRequired: true
    },
    {
        displayNameKey: 'schedule',
        name: 'schedule',
        type: SCHEDULE,
        isRequired: true
    },
    {
        displayNameKey: 'isGreenCorridor',
        name: 'isGreenCorridor',
        type: BOOLEAN
    },
    {
        displayNameKey: 'maxTaskQty',
        name: 'maxTaskQty',
        type: NUMBER
    },
    {
        displayNameKey: ' ',
        name: 'actions',
        type: ACTIONS
    }
]

export const standardSchedulesCols = schedulesCols.filter(col => !['activityId', 'isGreenCorridor'].includes(col.name));

export const exceptionSchedulesCols = [
    {
        displayNameKey: 'type',
        name: 'type',
        source: 'companies/visitTaskType',
        type: SELECT,
        isRequired: true
    },
    {
        displayNameKey: 'dateTimeFrom',
        name: 'dateTimeFrom',
        type: DATE_TIME,
        isRequired: true
    },
    {
        displayNameKey: 'dateTimeTo',
        name: 'dateTimeTo',
        type: DATE_TIME,
        isRequired: true
    },
    {
        displayNameKey: ' ',
        name: 'actions',
        type: ACTIONS
    }
]

export const settingNames = [
    'isHidden',
    'isRequired',
    'isUnique',
    'isReadOnly'
];