import React, {useEffect, useRef} from 'react';
import {customFieldsName, STATE} from "../../../constants/fieldTypes";
import '../styles.scss'
import LoadingSegment from "../../LoadingSegment";
import EditTable from "../table";
import TableRowEdit from "../components/tableRowEdit";

const FormTable = ({
                       columns,
                       rows,
                       setValue,
                       className = '',
                       name,
                       actions,
                       footerBtns,
                       hiddenFlags,
                       getFieldSettings,
                       platformId,
                       activityId,
                       virtualization
                   }) => {

    const [getFieldSettingsRequest, {
        data: fieldSettings,
        isLoading: fieldSettingsLoading,
        isFetching: fieldSettingsFetching
    }] = getFieldSettings();

    useEffect(() => {
        getFieldSettingsRequest({
            name,
            platformId,
            activityId
        })
    }, [name])

    const setRow = (i, row) => {
        const newRows = [...rows];
        newRows[i] = row;
        setValue(null, {
            name,
            value: newRows
        })
    }

    const setFieldValue = (e, {name, value}, id) => {
        const i = rows?.findIndex(r => r.id === id);
        const row = {...rows[i]};
        row[name] = value;

        setRow(i, row);
    }

    const setCustomField = (col, {value, name}, id) => {
        const i = rows?.findIndex(r => r.id === id);
        const val = {
            id: col.customFieldId,
            type: col.customFieldType,
            code: name,
            value
        };

        const row = rows[i];

        setFieldValue(null, {
            name: customFieldsName,
            value: row[customFieldsName] ? [
                ...row[customFieldsName].filter(v => v.id !== col.customFieldId),
                val
            ] : [val]
        }, row.id)
    }

    const getCustomValue = (form, id) => {
        return form[customFieldsName] && form[customFieldsName].find(f => f.id === id)?.value
    }

    const visibleRows = rows?.map((o, i) => ({...o, i})).filter(o => {
        let isHidden = false;
        hiddenFlags.forEach(f => {
            if (o[f]) isHidden = true
        })
        return !isHidden;
    })

    const stateName = columns?.find(f => f.type === STATE)?.name;

    const countOfLasts = 2;

    const rowComponent = ({row, index: i}) => {
        const actionsCol = () => actions(row);
        return <TableRowEdit
            className={visibleRows.length === (i + 1) && 'last-row-td'}
            key={i}
            isOneOfLast={((i + 1) > (visibleRows.length - countOfLasts)) && ((i + 1) > countOfLasts)}
            row={row}
            columns={columns}
            actions={actionsCol}
            getCustomValue={getCustomValue}
            platformId={platformId}
            setCustomField={setCustomField}
            stateName={stateName}
            fieldSettings={fieldSettings}
            setFieldValue={setFieldValue}
        />
    }

    const ref = useRef();

    return (
        <div ref={ref}>
            <LoadingSegment isLoading={fieldSettingsLoading || fieldSettingsFetching}>
                <EditTable
                    className={className}
                    columns={columns}
                    rows={visibleRows}
                    footerBtns={footerBtns}
                    rowComponent={rowComponent}
                    component={ref.current}
                    actions={actions}
                    setCustomField={setCustomField}
                    setFieldValue={setFieldValue}
                    platformId={platformId}
                    activityId={activityId}
                    getCustomValue={getCustomValue}
                    fieldSettings={fieldSettings}
                    virtualization={virtualization}
                />
            </LoadingSegment>
        </div>
    );
};

export default FormTable;