import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getJwt, removeJwt, setJwt} from "../../utils/localeStorageHelper";
import {HIDDEN_TYPE} from "../../constants/fieldTypes";
import {PROFILE_NAME, profileFields} from "../../constants/profile";
import {
    BACKLIGHT_CONSTRUCTOR_NAME,
    DICTIONARIES_SETTINGS_NAME,
    displayModes,
    FIELD_MATRIX_NAME
} from "../../constants/options";

const initialState = {
    isAuth: Boolean(getJwt()),
    userData: null,
    userInfo: null,
    appConfig: null,
    trigger: false
};

const SLICE_NAME = 'user';
const ALL_SECTIONS = 'ALL'

const userSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setUserInfo(state, action) {
            state.userInfo = action.payload;
            state.isAuth = !!action.payload.isLogged;
        },
        setUserInfoValue(state, action) {
            state.userInfo = {
                ...state.userInfo,
                ...action.payload
            };
        },
        setUser(state, action) {
            state.isAuth = !!action.payload.accessToken;
            state.userData = action.payload.allowedRoles;
            state.trigger = !state.trigger;
            setJwt(action.payload.accessToken)
        },
        removeUser(state) {
            state.isAuth = false;
            state.userData = null;
            state.userInfo = null;
            removeJwt();
        },
        setTrigger(state) {
            state.trigger = !state.trigger;
        },
        setAppConfig(state, action) {
            state.appConfig = action.payload;
        }
    }
})

export const selectorState = state => state[SLICE_NAME];
export const isAuthSelector = createSelector([selectorState], state => state.isAuth);
export const triggerSelector = createSelector([selectorState], state => state.trigger);
export const userInfoSelector = createSelector([selectorState], state => state.userInfo || {});
export const gridsSelector = createSelector([selectorState], state => state.appConfig?.grids || []);
export const getGlobalSettingByNameSelector = createSelector([
    selectorState,
    (state, name) => name
], (state, name) => {
    const maxSize = (state.appConfig?.systemGlobalSettings || {})[name] || 100;
    return [maxSize, maxSize * 1048576];
});
export const gridNamesSelector = createSelector(
    gridsSelector,
    (grids) => {
        return grids.map(d => d.name);
    }
)
export const dictionariesSelector = createSelector([
        selectorState,
        (state, showOnHeader) => showOnHeader
    ],
    (state, showOnHeader) => {
        return state.appConfig?.dictionaries?.filter(d => showOnHeader ? d.showOnHeader : true) || []
    }
);

export const additionalItemsSelector = createSelector([
        selectorState,
    ],
    (state) => {
        return state.appConfig?.additionalItems
    }
);

export const additionalItemByNameSelector = createSelector([
        additionalItemsSelector,
        (state, name) => name
    ],
    (items, name) => {
        return items?.find(item => item.name === name)
    }
);

export const additionalItemsNamesSelector = createSelector([
        selectorState,
    ],
    (state) => {
        return state.appConfig?.additionalItems?.map(item => item.name)
    }
);

export const dictionaryNamesSelector = createSelector(
    dictionariesSelector,
    (dictionaries) => {
        return dictionaries.map(d => d.name);
    }
)

export const settingsSelector = createSelector([selectorState], state => {
    const items = [];
    state.appConfig?.showFieldMatrix && items.push({
        name: FIELD_MATRIX_NAME
    });
    state.appConfig?.showDictionariesSettings && items.push({
        name: DICTIONARIES_SETTINGS_NAME
    });
    state.appConfig?.dictionaries?.find(d => d.name === BACKLIGHT_CONSTRUCTOR_NAME) && items.push({
        name: BACKLIGHT_CONSTRUCTOR_NAME
    });
    return items;
});

export const settingsNameSelector = createSelector([settingsSelector], items => {
    return items?.map(i => i.name);
});

export const gridSelector = createSelector([
    selectorState,
    (state, name) => name
], (state, name) => {
    const gridData = state.appConfig?.grids.find(grid => grid.name === name);

    if (!gridData) {
        return null;
    }

    let grid = {...gridData};

    grid.columns = grid.columns?.filter(col => col.type !== HIDDEN_TYPE)

    return grid;
});


export const dictionarySelector = createSelector([
    selectorState,
    (state, name) => name,
    (state, name, withHidden) => withHidden,
], (state, name, withHidden) => {
    const dictionaryData = state.appConfig?.dictionaries.find(grid => grid.name === name);

    if (!dictionaryData) {
        return null;
    }

    let dictionary = {...dictionaryData};

    dictionary.columns = dictionary.columns?.filter(col => (withHidden ? true : col.type !== HIDDEN_TYPE))

    return dictionary;
});

export const dictionaryFieldsSelector = createSelector([
        (state, name, displayMode, withHidden) => dictionarySelector(state, name, withHidden),
        (state, name, displayMode) => displayMode
    ], (dictionary, displayMode) => {
        return dictionary && [
            ...(dictionary.columns || []),
            ...(dictionary.additionalFormColumns || [])
        ].filter(col => (displayMode !== undefined) ? [displayModes.CARD_AND_GRID, displayMode].includes(col.displayMode) : true);
    }
)

export const gridFieldsSelector = createSelector([
        gridSelector,
        (state, name, displayMode) => displayMode
    ], (grid, displayMode) => {
        return grid && [
            ...(grid.columns || []),
            ...(grid.additionalFormColumns || [])
        ].filter(col => (displayMode !== undefined) ? [displayModes.CARD_AND_GRID, displayMode].includes(col.displayMode) : true);
    }
)

export const gridOrDictionaryFieldsSelector = createSelector([
    (state, name, displayMode) => dictionaryFieldsSelector(state, name, displayMode),
    gridFieldsSelector,
    (state, name, displayMode, section) => section
], (gridFields, dictionaryFields, section) => {
    if (section === PROFILE_NAME) {
        return profileFields;
    }
    const fields = gridFields ? gridFields : dictionaryFields;
    return fields?.filter(f => section ? (section === ALL_SECTIONS ? f.section : f.section === section) : !f.section) || []
})

export const gridOrDictionaryAdditionalFormSectionColumnsSelector = createSelector([
    dictionarySelector,
    gridSelector,
], (dictionary, grid) => {
    const obj = grid ? grid : dictionary;
    return obj?.additionalFormSectionColumns || []
})

export const gridOrDictionaryFieldsSectionsSelector = createSelector([
    (state, name) => gridOrDictionaryFieldsSelector(state, name, displayModes.CARD, ALL_SECTIONS)
], (fields) => {
    return [...new Set((fields || []).map(f => f.section))]
})

export const dictionaryOrGridSelector = createSelector([
    dictionarySelector,
    gridSelector
], (dictionary, grid) => {
    return dictionary || grid
})

export const enumItemsSelector = createSelector([
    dictionaryOrGridSelector,
    (s, grid, name) => name
], (grid, name) => {
    if (grid) {
        const column = grid.columns.find(col => col.name === name);
        return column.forSelectValues || [];
    } else return null;
})

export const dictionaryOrGridAdditionalActionsByName = createSelector(
    [
        dictionaryOrGridSelector,
        (state, name, actionName) => actionName
    ], (dictionaryOrGrid, actionName) => {
        return dictionaryOrGrid?.additionalActions?.includes(actionName);
    }
)

export const dictionaryOrGridEditPermission = createSelector([
        dictionaryOrGridSelector,
        (state, name) => dictionaryOrGridAdditionalActionsByName(state, name, 'canCreateByForm')
    ], (dictionaryOrGrid, canCreateByForm) => {
        return dictionaryOrGrid?.canCreateByForm || canCreateByForm;
    }
)

export const userAuthActions = userSlice.actions;
export default userSlice.reducer;