import React, {useState} from 'react';
import {Button, Icon} from "semantic-ui-react";
import DocView from "./DocView";

const DocsListViewCompact = ({documents: docs, onClick, countPerPage = 2, viewIds, onClose}) => {
    const [page, setPage] = useState(1);

    const documents = (viewIds?.length ? docs?.filter(d => viewIds.includes(d.id)) : docs).slice((page - 1) * countPerPage, page * countPerPage);

    const goLeft = () => {
        setPage(page => page > 1 ? --page : 1)
    }

    const goRight = () => {
        setPage(page => page * countPerPage < docs.length ? ++page : page)
    }

    return documents.length > 0 ? (
        <div className="flex-container flex-container-justify">
            <Button
                icon
                className="document-list-button"
                onClick={goLeft}
                disabled={page === 1}
            >
                <Icon name="chevron left" />
            </Button>
            {documents.map((document, index) => (
                <DocView
                    key={index}
                    document={document}
                    onClick={onClick}
                    onClose={onClose}
                />
            ))}
            <Button
                icon
                className="document-list-button"
                onClick={goRight}
                disabled={!(page * countPerPage < docs.length)}
            >
                <Icon name="chevron right" />
            </Button>
        </div>
    ) : null;
};

export default DocsListViewCompact;