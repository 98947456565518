import React from 'react';
import Form from "../../../form/Form";

const Information = ({ form, errors, name, setValue, customFields, colsCounts, getFieldSettings, getCustomCols, platformId, getCustomFieldSettings, readOnly }) => {
    const withCreateFor = ['driverId', 'coDriverId', 'truckId', 'trailerId'];

    return (
        <Form
            errors={errors}
            form={form}
            setValue={setValue}
            name={name}
            customFields={customFields}
            colsCounts={colsCounts}
            withCreateFor={withCreateFor}
            getFieldSettings={getFieldSettings}
            getCustomFieldSettings={getCustomFieldSettings}
            getCustomCols={getCustomCols}
            platformId={platformId}
            readOnly={readOnly}
        />
    );
};

export default Information;