import React from 'react';
import CreateOrEditGridModal from "./modals/CreateOrEditModal";
import {gridSettingsByName} from "../../constants/utils";

const EditBtn = ({btn, refresh, id, gridName, ...props}) => {
    const gridSettings = gridSettingsByName(gridName);
    const {modalContentComponent, withHistory, colsCounts, withoutAutoForm, errorsByGridNames} = gridSettings;

    return (
        <CreateOrEditGridModal
            {...props}
            errorsByGridNames={errorsByGridNames}
            key={id}
            id={id}
            gridName={gridName}
            onClose={refresh}
            modalContent={modalContentComponent}
            withHistory={withHistory}
            colsCounts={colsCounts}
            withoutAutoForm={withoutAutoForm}
        >
            {btn}
        </CreateOrEditGridModal>
    );
};

export default EditBtn;