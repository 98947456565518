import React from 'react';
import {Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const HiddenCorridors = ({children, array}) => {
    const {t} = useTranslation();

    return (
        <Modal
            trigger={children}
            closeIcon
            size='tiny'
        >
            <Modal.Header>
                {t('hiddenCorridorsModalHeader')}
            </Modal.Header>
            <Modal.Content scrolling>
                {(array || [])?.map(el => <div className='hidden-corridors__item' key={el}>
                    {el}
                </div>)}
            </Modal.Content>
        </Modal>
    );
};

export default HiddenCorridors;