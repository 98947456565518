export const defaultFunc = () => [() => {}, {data: [], isLoading: null, isFetching: null}];

export const viewPanes = (tabPanes) => tabPanes.filter(p => p.view);

export function securityServiceStatusColor() {
    return [
        { value: "returned", color: "yellow" },
        { value: "confirmed", color: "green" },
        { value: "rejected", color: "orange" },
        { value: "fullRejected", color: "orange" },
        { value: "partialRejected", color: "orange" },
        { value: "temporaryRejected", color: "orange" }
    ];
}