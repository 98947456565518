import React from 'react';
import {Segment} from "semantic-ui-react";
import './style.scss'
import {useTranslation} from "react-i18next";
import {BOOLEAN, MULTISELECT} from "../../constants/fieldTypes";
import FieldComponent from "./index";

const DropdownWithAllCheckbox = ({dictionaryName, label, noLabel, disabledAll, showWithEmpty, ...props}) => {
    const {t} = useTranslation();
    const {value, onChange, name, isDisabled, isRequired, form} = props;

    const showWithEmptyCheckboxName = `${name}ShowEmpty`;

    return (
        <>
            {!noLabel && <label className={isDisabled && 'label-disabled'}>{t(label)} {isRequired && '*'}</label>}
            <Segment className={`dropdown-with-all ${isDisabled && 'disabled'}`}>
                <div className='dropdown-with-all__checkboxes'>
                    <FieldComponent
                        type={BOOLEAN}
                        isDisabled={isDisabled || disabledAll}
                        label={t(`${label}All`)}
                        value={!value}
                        onChange={(e, {checked}) => onChange(null, {name, value: checked ? null : []})}
                    />
                    {(value !== null && showWithEmpty) && <FieldComponent
                        type={BOOLEAN}
                        label={t('showEmpty')}
                        isDisabled={isDisabled}
                        name={showWithEmptyCheckboxName}
                        value={form[showWithEmptyCheckboxName]}
                        onChange={onChange}
                    />}
                </div>
                {value && <FieldComponent
                    {...props}
                    type={MULTISELECT}
                    dictionaryName={dictionaryName}
                    noLabel
                    multiple
                    error={null}
                    style={null}
                />}
            </Segment>
        </>
    );
};

export default DropdownWithAllCheckbox;