import React from 'react';
import {Grid, Icon, Input, Message, Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useGetTimeslotsMutation} from "../../store/api/avisation";
import LoadingSegment from "../LoadingSegment";
import {dateFormat} from "../../utils/dateTime";

const Timeslot = ({name, label, noLabel, value, onChange, isRequired, isDisabled, placeholder, filter, className}) => {
    const {t} = useTranslation();
    const [getList, {data: dates, isLoading, isFetching}] = useGetTimeslotsMutation();
    const valueInput = (value?.from && value?.to) ? `${value.from.slice(0, 5)} - ${value.to.slice(0, 5)}` : '';

    const onOpenTimeSlot = () => {
        getList(filter);
    }

    const data = Object.keys(dates?.result || {}).map(k => ({date: k, timeSlots: dates?.result[k]}))

    const LabelText = ({children}) => {
        return <div className="avization-label">
            {children}
        </div>;
    }

    const Content = () => {
        const isSelected = (item) => {
            if (!value) return;
            let selected;
            let valueRes = value;
            delete valueRes.visitDate;

            if (valueInput === null) {
                return false;
            }

            selected = JSON.stringify({...(valueRes || {}), gateIds: null}) === JSON.stringify({
                ...(item || {}),
                gateIds: null
            });
            return selected;
        };

        const itemTimeClassName = (item) => {
            const selected = isSelected(item);
            const className = ["c-time-slot-list__item_time"];
            if (selected) {
                className.push("c-time-slot-list__item_time_selected");
            }
            return className.join(" ");
        };

        if (isLoading || isFetching) {
            return <LoadingSegment className='padding-20' isLoading/>
        }

        if (dates?.message) {
            return <Message
                className='padding-l5-r5 padding-t3-b3'
                error
            >
                <LabelText>{dates?.message}</LabelText>
            </Message>
        }

        if (!filter.visitDate) {
            return <LabelText>{t('noVisitDateSelected')}</LabelText>
        }

        if (!data?.length) {
            return <LabelText>{t('noDataTemporarily')}</LabelText>
        } else {
            return (
                <Grid centered divided columns={data.length} className='c-time-slot-list'>
                    {data.map((item, key) => (
                        <Grid.Column key={key} textAlign='center' className='c-time-slot-list__item'>
                            <div className='c-time-slot-list__item_date'>
                                {dateFormat(item.date, "DD.MM.YYYY")}
                            </div>
                            {item.timeSlots.length === 0 ?
                                <Grid className='avization-empty' stretched centered>
                                    <LabelText>{t('noData')}</LabelText>
                                </Grid>
                                :
                                item.timeSlots.map((time, index) => (
                                    <Grid.Row
                                        key={index}
                                        className={itemTimeClassName({
                                            date: item.date,
                                            from: time.from,
                                            to: time.to,
                                            rollingDate: time.rollingDate,
                                        })}
                                        onClick={(e) => onChange(e, {
                                            name,
                                            value: {
                                                date: item.date,
                                                from: time.from,
                                                to: time.to,
                                                gateIds: time.gateIds,
                                                rollingDate: time.rollingDate,
                                                visitDate: filter.visitDate
                                            }
                                        })}>
                                        <Grid.Column>
                                            {time?.from?.slice(0, 5)} - {time?.to?.slice(0, 5)}
                                        </Grid.Column>
                                    </Grid.Row>
                                ))
                            }
                        </Grid.Column>
                    ))}
                </Grid>
            );
        }
    };

    return (
        <>
            {!noLabel ? <label
                className={isDisabled ? "label-disabled" : null}>{t(label || name)} {isRequired ? '*' : ''}</label> : null}
            <Popup
                trigger={
                    <div className={`${className || ''} c-time-slot_input`}>
                        <Input
                            readOnly
                            disabled={isDisabled || isLoading || isFetching}
                            loading={isLoading || isFetching}
                            placeholder={(isLoading || isFetching) ? t('loading') : placeholder}
                            value={valueInput}
                        />
                        {valueInput && !isDisabled &&
                            <Icon
                                name='delete'
                                className={"c-time-slot__clear"}
                                onClick={(e) => {
                                    onChange(e, {name, value: null})
                                }}
                            />}
                    </div>
                }
                content={<Content/>}
                on="click"
                disabled={isDisabled}
                onOpen={onOpenTimeSlot}
            />
        </>
    );
};

export default Timeslot;