import {DATE_TIME, KEY_TEXT, SECURITY_STATE, TEXT} from "./fieldTypes";

export const securityGrids = [
    {
        name: 'securityService',
        nameElement: 'NewCase',
        withCounts: true,
        openModalOnClick: true,
        noLabel: true
    },
    {
        name: 'driverHistory',
        nameElement: 'driverHistory',
        noLabel: true,
        openModalOnClick: true,
        withoutHeader: true,
        filters: false,
        columns: [
            {
                name: 'creationDate',
                displayNameKey: 'creationDate',
                type: DATE_TIME
            },
            {
                name: 'state',
                displayNameKey: 'state',
                type: KEY_TEXT
            },
            {
                name: 'driverName',
                displayNameKey: 'driverName',
                type: TEXT
            },
            {
                name: 'initiatorName',
                displayNameKey: 'initiatorName',
                type: TEXT
            },
            {
                name: 'transportCompanyName',
                displayNameKey: 'transportCompanyName',
                type: TEXT
            },
            {
                name: 'result',
                displayNameKey: 'result',
                type: SECURITY_STATE
            },
        ]
    },
]

export const securityServiceGridName = 'cases';
