import React from 'react';
import {Checkbox, Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FilterComponent from "../../filters";
import {ACTIONS} from "../../../constants/fieldTypes";

const TableHeader = ({
                         columns,
                         withFilters,
                         setFilter,
                         filters,
                         sort,
                         setSort,
                         checkboxes,
                         onSelectAll,
                         selectedAll,
                         customFieldFilters,
                         setCustomFieldFilter,
                         disabledFilters = []
                     }) => {
    const {t} = useTranslation();
    const filterHeaderCell = (x) => {
        return x.filterType && <FilterComponent
            {...x}
            key={"filter-" + x.name}
            name={x.name}
            type={x.filterType}
            isFacet
            onChange={x.isCustom ? setCustomFieldFilter : setFilter}
            filters={x.isCustom ? customFieldFilters : filters}
            sort={sort}
            setSort={setSort}
            isSortingEnabled={!x.noSort && !disabledFilters.includes(x.name)}
            notFilter={disabledFilters.includes(x.name) || x.notFilter}
        />
    }

    return (
        <>
            <Table.Row>
                {
                    checkboxes && <Table.HeaderCell className="hc-checkbox">
                        <Checkbox
                            checked={selectedAll}
                            onChange={onSelectAll}
                        />
                    </Table.HeaderCell>
                }
                {
                    columns.map(col => (
                        <Table.HeaderCell
                            className={col.type === ACTIONS && 'hc-actions'}
                            key={col.name}
                        >
                            <div className='hc-filter'>
                                {t(col.displayNameKey || col.name)}
                                {
                                    withFilters && filterHeaderCell(col)
                                }
                            </div>
                        </Table.HeaderCell>
                    ))
                }
            </Table.Row>
        </>
    );
};

export default TableHeader;