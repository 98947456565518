import React, {useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import Grid from "../Grid";

const GridModal = ({open = false, form, onSave, onClose, name, preFilters, disabledFilters}) => {
    const {t} = useTranslation();

    const [checkedRows, setCheckedRows] = useState([]);

    const save = () => {
        onSave(checkedRows, form.id);
        onClose();
    };

    return (
        <Modal
            className='modal-grid'
            dimmer="blurring"
            open={open}
            closeOnEscape
            closeOnDimmerClick={false}
            closeIcon
            size="fullscreen"
            onClose={onClose}
        >
            <Modal.Header>
                {t(name)}
            </Modal.Header>
            <Grid
                preFilters={preFilters}
                gridName={name}
                isModal
                disabledFilters={disabledFilters}
                setCheckedRows={setCheckedRows}
            />
            <Modal.Actions>
                <Button
                    color='red'
                    onClick={() => onClose(false)}
                >
                    {t('cancelButton')}
                </Button>
                <Button
                    color='green'
                    disabled={!checkedRows.length}
                    onClick={save}
                >
                    {t('addButton')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default GridModal;