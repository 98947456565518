export const getValue = (value, multiple, options) => {
    const val = multiple
        ? options.filter(o => value.includes(o.value))
        : options.find(o => o.value === value);

    return multiple
        ? val?.map(v => ({value: v.value, name: v.name}))
        : (val && {
            value: val.value,
            name: val.name,
            description: val.description,
            data: val.data,
            hasExpiryDate: val.hasExpiryDate
        })
}