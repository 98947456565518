import React from 'react';
import DocsById from "../../../documents/DocsById";

const Docs = ({form, readOnly}) => {
    const docsBlocks = [
        'visit',
        'driver',
        ['coDriver', 'driver'],
        'truck',
        'trailer'
    ]

    return (
        docsBlocks.map((d, i) => {
            const name = Array.isArray(d) ? d[0] : d;
            const id = i ? form[`${name}Id`]?.value : form.id;

            return id && <div className='margin-bottom-5'>
                <DocsById
                    readOnly={readOnly}
                    label={`${name}Documents`}
                    id={id}
                    type={`${Array.isArray(d) ? d[1] : d}s`}
                />
            </div>
        })
    );
};

export default Docs;