import React, {useCallback, useEffect, useState} from 'react';
import {Label, Table} from "semantic-ui-react";
import Search from "../../components/inputs/Search";
import {useTranslation} from "react-i18next";
import LoadingSegment from "../../components/LoadingSegment";
import {useGetFieldsMutation, useGetMatrixMutation, useLazyGetStatesQuery} from "../../store/api/fieldMatrixApi";
import FieldsWithName from "./fieldsWithName";

const FieldMatrixTable = ({gridName, form, entityType}) => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const [getFields, {data: fields, isLoading, isFetching}] = useGetFieldsMutation();
    const [getMatrix, {data: matrix, isLoading: loadingMatrix, isFetching: fetchingMatrix}] = useGetMatrixMutation();
    const [getStates, {data: states, isLoading: loadingStates, isFetching: fetchingStates}] = useLazyGetStatesQuery();

    const getParams = form => {
        return {
            roleId: form?.roleId?.value,
            activityId: form?.activityId?.value,
            platformId: form?.platformId?.value,
            entityType
        }
    }
    
    useEffect(() => {
        const params = getParams(form);

        if (gridName) {
            getFields(params)
            refreshMatrix();
        }
    }, [gridName, form]);

    useEffect(() => {
        gridName && getStates({entityType});
    }, [gridName]);
    
    const refreshMatrix = useCallback(() => {
        const params = getParams(form);
        getMatrix(params);
    }, [form, getMatrix, getParams])

    const fieldsRows = (name) => (
        <FieldsWithName
            refresh={refreshMatrix}
            key={name}
            name={name}
            fields={(fields || {})[name]}
            searchValue={searchValue}
            states={states}
            form={form}
            gridName={gridName}
            isCustom={name !== 'mainFields'}
            entityType={entityType}
            matrix={matrix}
        />
    )

    return (
        <LoadingSegment
            className='field-matrix__table'
            isLoading={isLoading || isFetching || loadingMatrix || fetchingMatrix || loadingStates || fetchingStates}
        >
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className='field-matrix__first-col'>
                            <Search
                                size="mini"
                                isAuto
                                value={searchValue}
                                onChange={(e, {value}) => setSearchValue(value)}
                                placeholder={t('searchByFieldName')}
                            />
                        </Table.HeaderCell>
                        {
                            states?.map(state => <Table.HeaderCell
                                key={state.name}
                                textAlign='center'
                            >
                                <div className="status">
                                    <Label
                                        className="status-label-middle"
                                        color={state.color}
                                        horizontal
                                    >
                                        {t(state.name)}
                                    </Label>
                                </div>
                            </Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        [
                            'mainFields',
                            'globalFields',
                            'platformFields',
                            'activityFields'
                        ].map(f => fieldsRows(f))
                    }
                </Table.Body>
            </Table>
        </LoadingSegment>
    );
};

export default FieldMatrixTable;