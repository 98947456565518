import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Checkbox, Form, Icon} from 'semantic-ui-react';
import {PAGE_SIZE} from "../../constants/options";
import Search from "../inputs/Search";
import {useLazyGetListForDropdownQuery} from "../../store/api/dictionaryApi";
import {useTranslation} from "react-i18next";
import _debounce from 'lodash/debounce';
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import {removeDuplicates} from "../../utils/array";

const Facet = ({name, onChange, value, source, filters = {}, forSelectValues}) => {
    const {t} = useTranslation();
    const [filter, setFilter] = useState(null);
    const [items, setItems] = useState([]);

    const [valuesList, setValuesList] = useState([])

    const [getList, {data, isLoading: loading, isFetching}] = useLazyGetListForDropdownQuery()

    const context = useRef(null);
    const inputRef = useRef(null);
    const values = value ? value.split('|') : [];

    const getListData = (search, skip) => {
        getList({
            search: search || '',
            name: source,
            filter: filters,
            selectedIds: values,
            paging: {skip: skip || 0, take: PAGE_SIZE}
        })
    }

    const debounceGetList = useCallback(_debounce(getListData, 1000), []);

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 500)
    }, []);

    useEffect(() => {
        handleOpen();
        return clearFilter;
    }, []);

    useEffect(() => {
        if (data?.items) {
            !source
                ? setValuesList(data.items)
                : setItems(removeDuplicates([...data.items, ...(data.selectedItems || [])]));
        }
    }, [data])

    useEffect(
        () => {
            changeItems();
        },
        [valuesList, filter],
    );

    useEffect(
        () => {
            if (context?.current) context.current.scrollTop = 0;
            if (source) {
                filter
                    ? debounceGetList(filter)
                    : getListData();
            }
        },
        [filter],
    );

    const handleSetFilter = (e, {value}) => {
        setFilter(value);
    };

    const clearFilter = () => {
        setFilter(null);
    };

    const handleOpen = () => {
        !source && setValuesList(forSelectValues || [])
    };

    const handleRestClick = () => {
        onChange && onChange(null, {name, value: null});
    };

    const toggle = (e, {value: newValue}) => {
        let values = value ? value.split('|') : [];

        if (values.some(x => x === newValue)) {
            values.splice(values.indexOf(newValue), 1);
        } else {
            values.push(newValue);
        }
        onChange && onChange(e, {name, value: values.join('|')});
    };

    const changeItems = () => {
        !source && setItems(valuesList?.filter(v => t(v.name)?.toUpperCase().includes((filter || '').toUpperCase())) || []);
    };

    const scroll = () => {
        if (source && (data?.totalCount > items?.length)) {
            getListData(filter, items?.length)
        }
    };

    const onEnter = () => {
        onChange && onChange(null, {name, value: items?.map(v => v.value).join('|')});
    }

    return (
        <div className="facet-input">
            <Form>
                <div className="reset-selected">
                    <span onClick={handleRestClick}>{t('resetSelected')}</span>
                </div>
                <div>
                    <Search
                        innerRef={inputRef}
                        size="mini"
                        isAuto
                        value={filter}
                        onChange={handleSetFilter}
                        onEnter={onEnter}
                    />
                </div>
                <InfiniteScroll
                    id={name}
                    loadNext={scroll}
                    innerRef={context}
                    loading={loading || isFetching}
                    className='scroll-filter'
                >
                    <div style={{position: 'relative'}}>
                        {
                            items?.map((x, index) => {
                                let label = <label>{t(x.name)}</label>;
                                return (
                                    <Form.Field
                                        key={x.value + index}
                                        className={!x.isActive ? 'colorGrey' : ''}
                                    >
                                        <Checkbox
                                            value={x.value}
                                            checked={values.includes(x.value)}
                                            onChange={toggle}
                                            label={<label className='checkbox-label'>
                                                {x.color && <Icon color={x.color} name="circle"/>}
                                                {label}
                                            </label>}
                                        />
                                    </Form.Field>
                                );
                            })
                        }
                    </div>
                </InfiniteScroll>
            </Form>
        </div>
    );
};

export default Facet;
