import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";
import {toast} from "react-toastify";
import {downloadFile} from "../../utils/download";

export const gateReviewsApi = createApi({
    reducerPath: 'gateReviewsApi',
    baseQuery: baseQuery('/gateReview'),
    endpoints: (build) => ({
        show: build.mutation({
            query: (data) => {
                return {
                    url: `/show`,
                    method: 'POST',
                    body: data
                }
            }
        }),
        export: build.mutation({
            query: (data) => {
                return {
                    url: `/exportToExcel`,
                    method: 'POST',
                    body: data,
                    responseHandler: async (response) => {
                        const type = response?.headers.get('Content-Type')
                        if (type?.includes('text')) {
                            toast.info(await response?.text())
                        } else {
                            return response?.ok && downloadFile(response)
                        }
                    },
                    cache: "no-cache",
                }
            }
        }),
    })
});

export const {
    useShowMutation,
    useExportMutation,
} = gateReviewsApi;