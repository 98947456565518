import React from 'react';
import {Button, Modal} from "semantic-ui-react";

// компонент Confirm из semantic-ui-react не поддерживает
// лоадеры на кнопках и дизейбл кнопок, поэтому создан этот

const Confirm = ({
                     size = 'small',
                     open,
                     header,
                     content = 'Are you sure?',
                     onCancel,
                     cancelButton = 'Cancel',
                     onConfirm,
                     confirmButton = 'OK',
                     disabled,
                     loading,
                     ...props
                 }) => {
    return (
        <Modal
            {...props}
            open={open}
            size={size}
            onClose={onCancel}
        >
            {header && <Modal.Header>{header}</Modal.Header>}
            <Modal.Content>{content}</Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={disabled}
                    onClick={onCancel}
                >
                    {cancelButton}
                </Button>
                <Button
                    primary
                    disabled={disabled}
                    loading={loading}
                    onClick={onConfirm}
                >
                    {confirmButton}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default Confirm;
