import React, {useEffect, useState} from 'react';
import {Modal, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import VisitTab from "./components/VisitTab";
import {viewPanes} from "../../../utils/stateHelper";
import TasksTab from "./components/TasksTab";
import {useSelector} from "react-redux";
import {dictionaryOrGridSelector} from "../../../store/slices/userSlice";

const VisitTasksModal = ({ isCreate, form, setValue, errors, name, customFields, colsCounts, onClose, objectId, setIgnoreChanges, getFieldSettings, taskNumber, orderIds, readOnly, getCustomCols, platform, getCustomFieldSettings }) => {
    const {t} = useTranslation();

    const viewVisit = useSelector(state => dictionaryOrGridSelector(state, 'visits'));
    const viewTasks = useSelector(state => dictionaryOrGridSelector(state, 'visitTasks'));

    //чтобы не плодило задачи при переключениях по вкладкам, храним айди новосозданной задачи
    const [newTaskIdForOrders, setNewTaskIdForOrders] = useState(null);

    const visitName = 'Visit';
    const platformIdName = 'platformId'

    useEffect(() => {
        if (platform?.value && !form[platformIdName] && form.id) {
            setValue(null, {name: platformIdName, value: platform})
            setIgnoreChanges(i => ({...i, [platformIdName]: platform}))
        }
    }, [platform, form])

    const panes = [
        {
            view: viewVisit,
            menuItem: t('visit'),
            render: () => {
                return <Tab.Pane>
                    {form[platformIdName] && <VisitTab
                        isCreate={isCreate}
                        getCustomCols={getCustomCols}
                        id={form.id}
                        form={form}
                        setValue={(...r) => setValue(...r, {index: 0, objectName: visitName})}
                        errors={errors[visitName] && errors[visitName][0]}
                        name={name}
                        colsCounts={colsCounts}
                        getFieldSettings={getFieldSettings}
                        getCustomFieldSettings={getCustomFieldSettings}
                        readOnly={readOnly}
                    />}
                </Tab.Pane>
            }
        },
        {
            view: viewTasks,
            menuItem: t('tasks'),
            render: () => {
                return <Tab.Pane>
                    <TasksTab
                        isCreate={isCreate}
                        orderIds={orderIds}
                        taskId={objectId || taskNumber?.value}
                        errors={errors}
                        id={form.id}
                        form={form}
                        setValue={setValue}
                        closeModal={onClose}
                        setIgnoreChanges={setIgnoreChanges}
                        getFieldSettings={getFieldSettings}
                        getCustomFieldSettings={getCustomFieldSettings}
                        newTaskIdForOrders={newTaskIdForOrders}
                        setNewTaskIdForOrders={setNewTaskIdForOrders}
                        readOnly={readOnly}
                        getCustomCols={getCustomCols}
                    />
                </Tab.Pane>
            }
        }
    ]

    return (
        <Modal.Description>
            <Tab
                defaultActiveIndex={((objectId || taskNumber?.value || orderIds) && viewVisit) ? 1 : 0}
                panes={viewPanes(panes)}
            />
        </Modal.Description>
    );
};

export default VisitTasksModal;