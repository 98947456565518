import {downloadFile} from "../../utils/download";
import {toast} from "react-toastify";

export const endpoints = (build, typeTag) => ({
    //возможно, в будущем получится использовать providesTags/invalidatesTags в getList
    //в настоящий момент с реализацией бесконечной прокрутки (использование merge) нельзя добиться
    //поведения, чтобы вызывался getList с измененными аргументами
    //использование тэгов сейчас приведет к запросу с последними аргументами и срабатыванию merge
    //(изменить, если добавят нативную поддержку бесконечной прокрутки)

    getList: build.query({
        query: ({name, filter, sorting, paging}) => {
            return {
                url: `/${name}/search`,
                method: 'POST',
                body: {
                    filter,
                    sorting,
                    paging
                }
            }
        },
        serializeQueryArgs: ({endpointName, queryArgs}) => {
            return endpointName + queryArgs.name;
        },
        merge: (currentCache, newItems, otherArgs) => {
            if (otherArgs?.arg?.paging?.skip > 0) {
                currentCache.items.push(...newItems.items)
                currentCache.totalCount = newItems.totalCount
                return currentCache;
            } else {
                return newItems;
            }
        },
        forceRefetch({currentArg, previousArg}) {
            return currentArg !== previousArg
        },
    }),
    getIds: build.query({
        query: ({name, filter, sorting, paging}) => {
            return {
                url: `/${name}/ids`,
                method: 'POST',
                body: {
                    filter,
                    sorting,
                    paging
                }
            }
        }
    }),
    export: build.mutation({
        query: ({name, filter, sorting, paging, columns, ids}) => {
            return {
                url: `/${name}/exportToExcel`,
                method: 'POST',
                body: {
                    filter,
                    sorting,
                    paging,
                    columns,
                    ids
                },
                responseHandler: async (response) => {
                    const type = response?.headers.get('Content-Type')
                    if (type?.includes('text')) {
                        toast.info(await response?.text())
                    } else {
                        return response?.ok && downloadFile(response)
                    }
                },
                cache: "no-cache",
            }
        }
    }),
    import: build.mutation({
        query: ({name, data}) => {
            return {
                url: `/${name}/importFromExcel`,
                method: 'POST',
                body: data,
            }
        }
    }),
    getElement: build.query({
        query: ({name, id}) => {
            return {
                url: `/${name}/get/${id}`,
            }
        },
    }),
    getElementsByIds: build.query({
        query: ({name, ids}) => {
            return {
                url: `/${name}/getByIds`,
                method: 'POST',
                body: {ids}
            }
        },
        providesTags: (data) => data?.id ? [{type: typeTag, id: data.id}] : []
    }),
    save: build.mutation({
        query: ({name, form, confirmation}) => {
            if (confirmation) {
                form.confirmation = true;
            }

            return {
                url: `/${name}/save`,
                method: 'POST',
                body: form
            }
        },
        invalidatesTags: (result, error, {form}) => form?.id ? [{type: typeTag, id: form.id}] : []
    }),
    delete: build.mutation({
        query: ({name, ids}) => {
            return {
                url: `/${name}/delete`,
                method: 'DELETE',
                body: ids
            }
        },
        invalidatesTags: (result, error, {form}) => form?.id ? [{type: typeTag, id: form.id}] : []
    }),
    getCounters: build.query({
        query: ({name, filter, sorting, paging}) => {
            return {
                url: `/${name}/getcounters`,
                method: 'POST',
                body: {
                    filter,
                    sorting,
                    paging
                }
            }
        },
    }),
    getDefaultForm: build.query({
        query: ({name}) => {
            return {
                url: `/${name}/defaults`,
            }
        },
        transformResponse: (response, meta, {autoValues}) => {
            return autoValues ? {
                ...response,
                ...autoValues
            } : response
        }
    }),
    getFieldSettings: build.mutation({
        query: ({name, id, platformId, activityId}) => {
            return {
                url: `/${name}${id ? `/${id}` : ''}/getFieldProperties`,
                method: 'POST',
                body: {
                    platformId,
                    activityId
                }
            }
        },
        serializeQueryArgs: ({endpointName, queryArgs}) => {
            return endpointName + queryArgs.id;
        },
    }),
    getCustomFieldSettings: build.mutation({
        query: ({name, id, platformId, activityId}) => {
            return {
                url: `/${name}${id ? `/${id}` : ''}/getCustomFieldProperties`,
                method: 'POST',
                body: {
                    platformId,
                    activityId
                }
            }
        },
        serializeQueryArgs: ({endpointName, queryArgs}) => {
            return endpointName + queryArgs.id;
        },
    }),
    action: build.mutation({
        query: ({name, action, ids}) => {
            return {
                url: `/${name}/action`,
                method: 'POST',
                body: {
                    action,
                    ids
                }
            }
        },
    }),
    getListForDropdown: build.query({
        query: ({name, selectedIds, search, platformId, activityIds, filter, paging}) => {
            return {
                url: `/${name}/forSelect`,
                method: 'POST',
                body: {
                    selectedIds,
                    platformId,
                    activityIds,
                    filter: {
                        search,
                        ...filter
                    },
                    paging
                }
            }
        },
        merge: (currentCache, newItems, otherArgs) => {
            if (otherArgs?.arg?.paging?.skip > 0) {
                currentCache.items.push(...newItems.items)
                currentCache.totalCount = newItems.totalCount
                return currentCache;
            } else {
                return newItems;
            }
        },
        forceRefetch({currentArg, previousArg}) {
            return currentArg !== previousArg
        },
        serializeQueryArgs: ({endpointName, queryArgs}) => {
            return endpointName + queryArgs.name;
        },
    }),
    editValue: build.mutation({
        query: ({name, fieldName, ids, value, customFieldId}) => {
            return {
                url: `/${name}/invokeBulkUpdate/${fieldName}`,
                method: 'POST',
                body: {
                    ids,
                    value,
                    customFieldId
                }
            }
        },
    }),
})