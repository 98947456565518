import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Dropdown, Icon, Label} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {enumItemsSelector} from "../../store/slices/userSlice";
import {getValue} from "./utils";

const EnumDropdown = ({
                          onlyValue,
                          separator,
                          className,
                          gridName,
                          name,
                          label,
                          isDisabled,
                          isRequired,
                          value,
                          onChange,
                          placeholder,
                          forSelectValues,
                          multiple,
                          noLabel,
                          allowAdditions,
                          nullIsAll,
                          clearable = true,
                          upward,
                          disableRemoveSelected
                      }) => {
    const {t} = useTranslation();
    const id = `div-dropdown-${name}`;

    const items = useSelector(state => forSelectValues ? forSelectValues : enumItemsSelector(state, gridName, name)) || []

    const [editItem, setEditItem] = useState();
    const [searchQuery, setSearchQuery] = useState('');

    const handleOnChange = (e, {name, value: newValue, options}) => {
        let newOptions = [...(options || [])];

        if (allowAdditions) {
            const isEditAllValues = !(multiple ? newValue : [newValue]).every(v => options.map(v => v.value).includes(v));
            const isEdit = !!(isEditAllValues && editItem);
            const isAdd = isEditAllValues && !editItem;

            if (isAdd || isEdit) {
                const newText = newValue[newValue.length - 1];
                if (isAdd) {
                    newOptions.push({name: newText, value: newText});
                } else {
                    newOptions = newValues.map(v => v.value === editItem ? {...v, name: newText} : v);
                    setEditItem(null);
                }
                setNewValues(newOptions)
            }
        }

        if (disableRemoveSelected) {
            setSearchQuery('');
            const requiredSelected = (value || []).filter(v => v.value !== v.name).map(v => v.value);
            const isValid = requiredSelected.every(v => newValue.includes(v));
            if (!isValid) return null;
        }

        onChange && onChange(e, {
            name,
            value: onlyValue
                ? (separator ? newValue?.join(separator) : newValue)
                : getValue(newValue, multiple, newOptions)
        });
    }
    const itemsToOptions = (items = [], newValues = []) => {
        return [...items, ...newValues].map(i => ({
            ...i,
            key: i.value,
            value: i.value,
            text: t(i.name),
            description: i.description,
            disabled: i.disabled,
            label: i.color && {color: i.color, empty: true, circular: true}
        }))
    }

    const [newValues, setNewValues] = useState([]);

    useEffect(() => {
        if (allowAdditions) {
            setNewValues(newValues => ([
                ...(newValues || []).map(v => (value || []).find(val => val.value === v.value) || v),
                ...(value || [])
                    .filter(v => !newValues.map(v => v.value)
                        .includes(v.value))
            ]));
        }
    }, [value])

    const editOnClick = (isEdit, label) => {
        setEditItem(isEdit ? null : label.value)
        const el = document.getElementById(id)?.querySelector('input');
        setSearchQuery(label.text)
        el?.click();
    }

    const renderLabel = (label) => {
        const isSaved = label.value !== label.text;
        const isEdit = editItem === label.value;

        return isSaved ?
            <Label className={`dropdown-edit-label ${isEdit ? 'dropdown-edit-label_is-edit' : ''}`}>
                {label.text}
                <Icon
                    name='pencil'
                    onClick={() => editOnClick(isEdit, label)}
                />
            </Label>
            : {
                content: label.text,
            }
    }

    const props = disableRemoveSelected
        ? {
            renderLabel,
            searchQuery,
            onSearchChange: (e, {searchQuery}) => setSearchQuery(searchQuery),
            onBlur: () => setSearchQuery('')
        } : {}

    const options = useMemo(() => itemsToOptions(items, newValues), [items, newValues])

    return (
        <>
            {(!noLabel && label) && <label
                className={isDisabled ? "label-disabled" : ''}
            >
                {t(label)} {isRequired && '*'}
            </label>}
            <div
                id={id}
                className="dropbox-button"
            >
                <Dropdown
                    {...props}
                    upward={upward}
                    text={((!value || !value?.length) && nullIsAll) && t(`${name}IsAny`)}
                    clearable={clearable}
                    search
                    name={name}
                    value={
                        onlyValue
                            ? (separator ? value?.split(separator) : value)
                            : (multiple ? (value?.map(v => v?.value) || []) : (value?.value || ''))
                    }
                    onChange={handleOnChange}
                    disabled={isDisabled || false}
                    selection
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    className={className}
                    options={options}
                    placeholder={placeholder}
                    multiple={multiple}
                    noResultsMessage={t('noResultFound')}

                    allowAdditions={allowAdditions}
                    additionLabel={`${t(editItem ? 'editValue' : 'addValue')} `}
                />
            </div>
        </>
    );
};

export default EnumDropdown;