import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Icon, Modal, Table} from "semantic-ui-react";
import {
    useGetFileFromFtpMutation,
    useLazyGetFtpFileListQuery,
    useUploadFileFromFtpMutation
} from "../../store/api/ftpFileUploaderApi";
import LoadingSegment from "../LoadingSegment";

const FtpUploader = ({children, onChange}) => {
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    const [getList, {data: files = [], isLoading}] = useLazyGetFtpFileListQuery();
    const [uploadFile] = useUploadFileFromFtpMutation();
    const [getFile] = useGetFileFromFtpMutation();
    const handleOpen = () => {
        getList();
        setModalOpen(true)
    }
    const handleClose = () => {
        setModalOpen(false)
    }

    const select = (fileName) => {
        uploadFile({fileName})
            .unwrap()
            .then(fileDto => {
                getFile({id: fileDto.fileId})
                    .unwrap()
                    .then(() => {
                        onChange(fileDto);
                        handleClose()
                    })
            })
    }

    return (
        <Modal
            trigger={children}
            onOpen={handleOpen}
            open={modalOpen}
            closeOnEscape
            closeOnDimmerClick={false}
            onClose={handleClose}
        >
            <Modal.Header>{t('attachingAFileFromFTP')}</Modal.Header>
            <Modal.Content>
                <LoadingSegment isLoading={isLoading}>
                    <Table celled striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('fileName')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                files.map(file =>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            <Icon name='file'/> {file.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={() => {
                                                select(file.name)
                                            }}>
                                                {t('attach')}
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </LoadingSegment>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={handleClose}>
                    <Icon name='ban'/> {t('cancelButton')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default FtpUploader;