import React, {useState} from 'react';
import SectionForm from "../../../form/SectionForm";
import {Button, Message} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import './style.scss'

const ActivityHandlerSpecialFields = (props) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <div className='special-fields'>
            <Message
                success
                header={t('activityHandlerSpecialFields')}
                content={
                    <Button
                        color='white'
                        size='small'
                        className="ui button"
                        onClick={() => setOpen(open => !open)}>
                        {open ? t('hide') : t('open')}
                    </Button>
                }
            />
            {open && <SectionForm
                {...props}
                colsCounts={3}
                section='activityHandlerSpecialFields'
            />}
        </div>
    );
};

export default ActivityHandlerSpecialFields;