import React, {useEffect, useMemo, useState} from 'react';
import {gridSettingsByName} from "../../constants/utils";
import {useSelector} from "react-redux";
import {gridFieldsSelector, gridSelector, userInfoSelector} from "../../store/slices/userSlice";
import {displayModes} from "../../constants/options";
import {
    useActionMutation,
    useDeleteMutation,
    useEditValueMutation,
    useExportMutation,
    useGetActionsMutation,
    useGetCustomColumnsMutation,
    useImportMutation,
    useLazyGetCountersQuery,
    useLazyGetIdsQuery,
    useLazyGetListQuery
} from "../../store/api/gridApi";
import TableBlock from "../TableBlock/TableBlock";
import LoadingSegment from "../LoadingSegment";
import EditBtn from "./EditBtn";

const Grid = ({gridName, isModal, preFilters, disabledFilters, setCheckedRows}) => {
    const userInfo = useSelector(userInfoSelector);

    const gridSettings = gridSettingsByName(gridName);
    const gridData = useSelector(state => gridSelector(state, gridName)) || {};
    const gridColumns = useSelector(state => gridFieldsSelector(state, gridName, displayModes.GRID));

    const [getCustomCols, {data: customCols, isLoading}] = useGetCustomColumnsMutation();
    const [customFieldsLoad, setCustomFieldsLoad] = useState(false)

    const platformValue = userInfo?.currentPlatform?.value;
    const activityValue = userInfo?.currentActivity?.value;

    const [preFiltersValue, setPreFiltersValue] = useState(null);

    useEffect(() => {
        if (preFilters || gridSettings.globalPreFilters) {
            let pf = {};
            pf.platformId = platformValue || undefined;
            pf.activityId = activityValue || undefined;
            pf = {
                ...pf,
                ...preFilters
            }
            setPreFiltersValue(pf);
        }
    }, [platformValue, activityValue, preFilters])

    useEffect(() => {
        setCustomFieldsLoad(false)
        getCustomCols({
            name: gridName,
            platformId: platformValue,
            activityId: activityValue
        }).then(() => setCustomFieldsLoad(true))
    }, [gridName, platformValue, activityValue])

    const editBtn = (btn, refresh, id, mode, row, copyFromId) => {
        const {modalIdKey, modalType} = gridSettings;

        return <EditBtn
            key={id}
            gridName={modalType ? modalType : gridName}
            id={modalIdKey ? row[modalIdKey] : id}
            refresh={refresh}
            btn={btn}
            objectId={id}
            copyFromId={copyFromId}
            readOnly={row?.isReadOnly}
            platform={(!id && platformValue) && {value: platformValue}}
        />
    }

    const getDisabledGlobalPreFilters = () => {
        let array = [];
        if (gridSettings.globalPreFilters) {
            platformValue && array.push('platformId');
            activityValue && array.push('activityId');
        }
        return array;
    }

    const disabledFiltersValue = (disabledFilters || gridSettings.globalPreFilters) && [...(disabledFilters || []), ...getDisabledGlobalPreFilters()]

    const settings = useMemo(() => ({
        withActions: true,
        ...gridSettings,
        ...gridData,
        columns: [...gridColumns, ...(customCols || []).map(x => ({
            ...x,
            displayNameKey: x.displayNameKey,
            isCustom: true
        }))],
        noLabel: true
    }), [gridSettings, gridData, gridColumns, customCols]);

    return <LoadingSegment isLoading={isLoading || !customFieldsLoad}>
        {customFieldsLoad && <TableBlock
            key={gridName}
            settings={settings}
            isModal={isModal}
            editBtn={editBtn}
            deleteRow={useDeleteMutation}
            getCountersQuery={useLazyGetCountersQuery}
            getListQuery={useLazyGetListQuery}
            getIdsQuery={useLazyGetIdsQuery}
            exportToExcelFunc={useExportMutation}
            getActionsFunc={useGetActionsMutation}
            importFromExcelFunc={useImportMutation}
            actionFunc={useActionMutation}
            withFooter
            preFilters={preFiltersValue}
            disabledFilters={disabledFiltersValue}
            setCheckedRows={setCheckedRows}
            changeFieldValue={useEditValueMutation}
            withBacklights
        />}
    </LoadingSegment>
};

export default Grid;