import React from 'react';
import {Table} from "semantic-ui-react";
import FieldComponent from "../../fields";
import {ACTIONS, MULTISELECT_WITH_ALL, SCHEDULE} from "../../../constants/fieldTypes";
import {getFiltersForField} from "../../../constants/utils";
import {isFirstOfMergeField, view} from "../../../utils/mergeRows";
import TableCell from "../../TableBlock/components/TableCell";

const TableRowEdit = ({
                          columns,
                          row,
                          stateName,
                          setCustomField,
                          setFieldValue,
                          getCustomValue,
                          fieldSettings,
                          actions,
                          platformId,
                          mergeFieldData,
                          isView,
                          isOneOfLast,
                          className = ''
                      }) => {
    const onChange = (e, o, field, isMergeField) => {
        if (isMergeField) {
            setFieldValue(e, o, row.id, true)
        } else {
            field.isCustom ? setCustomField(field, o, row.id) : setFieldValue(e, o, row.id)
        }
    }

    return (
        <Table.Row>
            {
                columns.map(field => {
                    if (!view(field, mergeFieldData)) return null;
                    const isFirstOfMerge = isFirstOfMergeField(field, mergeFieldData);
                    const isMergeField = isFirstOfMerge && mergeFieldData.fieldEdit;

                    return (!isView || isMergeField) ? <Table.Cell
                        rowSpan={isFirstOfMerge && mergeFieldData?.count || 1}
                        key={field.name}
                        textAlign='center'
                        className={`${mergeFieldData && !isFirstOfMerge && 'border-left'} ${field.type === ACTIONS && 'ui form cell-field hc-actions hc-checkbox__row'} ${className}`}
                    >
                        <FieldComponent
                            {...field}
                            upward={isOneOfLast}
                            isTableStyle
                            actions={actions}
                            noLabel
                            forSelectValues={field.refValuesByField ? row[field.refValuesByField] : field.forSelectValues}
                            key={field.name}
                            onChange={(e, o) => onChange(e, o, field, isMergeField)}
                            value={field.type === SCHEDULE ? row : (field.isCustom ? getCustomValue(row, field.customFieldId) : row[field.name])}
                            label={field.displayNameKey}
                            disabledAll={field.type === MULTISELECT_WITH_ALL && !row[`${field.name}AllAvailable`]}
                            fieldSettings={fieldSettings}
                            stateValue={stateName && !Number.isInteger(row?.id) && row[stateName]?.value}
                            filter={getFiltersForField(field, {}, platformId)}
                        />
                    </Table.Cell> : <TableCell
                        rowSpan={isFirstOfMerge && mergeFieldData?.count}
                        className={`${mergeFieldData && !isFirstOfMerge && 'border-left'} ${field.type === ACTIONS && 'hc-actions hc-checkbox__row'}`}
                        key={field.name}
                        col={field}
                        row={{...row, actions}}
                        name={field.name}
                    />
                })
            }
        </Table.Row>
    );
};

export default TableRowEdit;