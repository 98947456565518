import React from 'react';
import {useTranslation} from "react-i18next";
import '../styles.scss'

const NoData = ({text = 'noData'}) => {
    const {t} = useTranslation();

    return <span className='no-data'>
        {t(text)}
    </span>;
};

export default NoData;