import React from 'react';
import {Loader} from "semantic-ui-react";

const TableLoader = () => {
    return (
        <div className='table-loader'>
            <Loader active/>
        </div>
    );
};

export default TableLoader;