import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import {formatDateTime, parseDateTime} from "../../utils/dateTime";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../utils/localeStorageHelper";
import {Popup} from "semantic-ui-react";

const DateTime = ({value, name, onChange, isDisabled, noLabel, popperPlacement, className, isRequired, error, isTableStyle, placeholder}) => {
    const {t} = useTranslation();
    const ref = useRef();

    const getDatePicker = (params = {}) => {
        return <DatePicker
            {...params}
            placeholderText={placeholder}
            fixedHeight
            locale={getLanguage()}
            disabled={isDisabled || false}
            isClearable={!(isDisabled || false)}
            selected={parseDateTime(value || "")}
            dateFormat="dd.MM.yyyy HH:mm:ss"
            className={className}
            showTimeSelect
            timeFormat="HH:mm:ss"
            timeIntervals={15}
            timeCaption={t("time")}
            onChange={(date, e) => {
                onChange(e, {
                    name: name,
                    value: date
                        ? formatDateTime(date)
                        : null
                });
            }}
            popperPlacement={popperPlacement}
            onChangeRaw={(e) => onChange(e, {name, value: e.target.value})}
        />
    }

    return (
        isTableStyle
            ? <Popup
                triggerRef={ref}
                on='click'
                trigger={
                    <span>
                        {getDatePicker({
                            portalId: 'portal-id-display-none'
                        })}
                    </span>
                }
                content={getDatePicker({
                    inline: true
                })}
                className='edit-date-popup'
            />
            :
        <div className='field' ref={ref}>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : ''}>{t(name)} {isRequired ? '*' : ''}</label> : null}
            {getDatePicker()}
        </div>
    );
};
export default DateTime;
