import React, {memo, useEffect, useState} from 'react';
import TableRowEdit from "./tableRowEdit";
import {SCHEDULE} from "../../../constants/fieldTypes";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useDeepEffect} from "../../../hooks/useDeepEffect";

const TableRow = ({columns, row, setRow, deleteRow, initIsEdit = false, mergeFieldData, setEditList}) => {
    const {t} = useTranslation();
    const [isEdit, setIsEdit] = useState(initIsEdit);
    const [rowData, setRowData] = useState(row);

    useDeepEffect(() => {
        if (!mergeFieldData?.fieldEdit && row) {
            setRowData(row);
        }
    }, [row])

    const setFieldValue = (e, {name, value, checked}, id, forAll) => {
        const newRow = {...rowData}
        newRow[name] = checked !== undefined ? checked : value;
        setRowData(newRow);

        forAll && mergeFieldData?.setForAll({name, value})
    }

    useEffect(() => {
        mergeFieldData?.name && setFieldValue(null, {
            name: mergeFieldData.name,
            value: row[mergeFieldData.name]
        });
    }, [row[mergeFieldData?.name]])

    useEffect(() => {
        setEditList && setEditList(list => !isEdit ? list?.filter(r => r !== row.id) : [...(list || []), row.id])
    }, [isEdit])

    const saveData = () => {
        const requiredColsData = columns?.filter(col => col.isRequired);
        const requiredCols = requiredColsData?.map(col => col.type !== SCHEDULE ? col.name : ['dayOfWeek', 'timeFrom', 'timeTo']).flat();


        const isValid = requiredCols?.every(col => Array.isArray(rowData[col]) ? rowData[col]?.length : rowData[col])

        if (isValid) {
            setIsEdit(false);
            setRow(row.id, rowData);
        } else {
            toast.error(`${t('fieldsIsRequired')} ${requiredCols.map(c => t(c)).join(', ')}`)
        }
    }

    const actions = () => [
        {
            visible: isEdit,
            color: 'green',
            icon: 'check',
            name: 'okButton',
            onClick: saveData
        },
        {
            visible: !isEdit,
            icon: 'edit',
            name: 'editRow',
            onClick: () => {
                setIsEdit(true);
            }
        },
        {
            visible: true,
            color: 'red',
            icon: 'trash',
            name: 'deleteRow',
            onClick: () => deleteRow(row.id)
        }
    ]

    return (
        <>
            {
                <TableRowEdit
                    isView={!isEdit}
                    mergeFieldData={mergeFieldData}
                    columns={columns}
                    row={rowData}
                    setFieldValue={setFieldValue}
                    actions={actions}
                />
            }
        </>
    );
};

export default memo(TableRow);