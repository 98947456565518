import React from 'react';
import {Dimmer, Loader, Segment} from "semantic-ui-react";

const LoadingSegment = ({isLoading, children, className, vertical = true, size, ...props}) => {
    return (
        <Segment
            {...props}
            className={`border-bottom-0 margin-0 padding-0 ${className || ''}`}
            vertical={vertical}
        >
            <Dimmer inverted active={isLoading}>
                <Loader size={size}/>
            </Dimmer>
            {children}
        </Segment>
    );
};

export default LoadingSegment;