import React, {useEffect} from 'react';
import {Table} from "semantic-ui-react";
import TableLoader from "./TableLoader";

const InfiniteScrollTable = ({
                                 children,
                                 header,
                                 className = '',
                                 loadNext,
                                 loading,
                                 id,
                                 style,
                                 scrollOff,
                             }) => {
    useEffect(() => {
        if (loadNext && id) {
            const lastElement = document.getElementById('last-element' + id);
            const el = document.getElementById('infinity-scroll-table_' + id);

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            loadNext();
                        }
                    })
                }, {
                    root: el,
                    threshold: 1,
                    rootMargin: '400px'
                });

            observer.observe(lastElement);

            return () => {
                observer.disconnect();
            };
        }
    }, [loadNext, id]);

    return (
        <div
            style={style}
            id={'infinity-scroll-table_' + id}
            className={`${!scrollOff && 'scroll-table'} ${className}`}
        >
            <Table
                celled
                selectable
            >
                <Table.Header
                    className='sticky-header-tb'
                >
                    {header}
                </Table.Header>
                <Table.Body>
                    {children}
                    <Table.Row id={'last-element' + id}/>
                </Table.Body>
            </Table>
            {
                loading && <TableLoader/>
            }
        </div>
    );
};

export default InfiniteScrollTable;