import React, {useEffect} from 'react';
import DriverInfo from "./components/DriverInfo";
import {useTranslation} from "react-i18next";
import TransportsInfo from "./components/TransportsInfo";
import CompanyInfo from "./components/CompanyInfo";
import ClientInfo from "./components/ClientInfo";
import RouteInfo from "./components/RouteInfo";
import {Form} from "semantic-ui-react";
import DocListView from "../../components/documents/DocListView";
import DocListEdit from "../../components/documents/DocListEdit";
import {SELECT, TEXTAREA} from "../../constants/fieldTypes";
import FieldComponent from "../../components/fields";
import {useGetFieldSettingsMutation} from "../../store/api/gridApi";
import {useSelector} from "react-redux";
import {userInfoSelector} from "../../store/slices/userSlice";
import LoadingSegment from "../../components/LoadingSegment";

const SecurityCheckInfo = ({form, setValue, isView, docTypesKey, errors, docs, onSaveDocs, ...props}) => {
    const {t} = useTranslation();
    const userInfo = useSelector(userInfoSelector);

    const [getFieldSettingsRequest, {
        data: fieldSettings,
        isLoading,
        isFetching
    }] = useGetFieldSettingsMutation();

    useEffect(() => {
        getFieldSettingsRequest({
            name: docTypesKey,
            activityId: form?.activityId?.value || null,
            platformId: form?.platformId?.value || (userInfo?.currentPlatform?.value || null),
        });
    }, [form?.activityId || null, form?.platformId || null])

    return (
        <LoadingSegment className="ui form security-check" isLoading={isLoading || isFetching}>
            <FieldComponent
                fieldSettings={fieldSettings}
                type={SELECT}
                error={errors?.driverId}
                value={form?.driverId}
                label={t('driver')}
                name='driverId'
                dictionaryName='drivers'
                onChange={setValue}
                isDisabled={isView}
                withCreate
                withEdit
            />
            <DriverInfo
                {...props}
                isView={isView}
                form={form}
                setValue={setValue}
                fieldSettings={fieldSettings}
            />
            <TransportsInfo
                {...props}
                errors={errors}
                isView={isView}
                form={form}
                setValue={setValue}
                fieldSettings={fieldSettings}
            />
            <CompanyInfo
                {...props}
                errors={errors}
                isView={isView}
                form={form}
                setValue={setValue}
                fieldSettings={fieldSettings}
            />
            <ClientInfo
                {...props}
                errors={errors}
                isView={isView}
                form={form}
                setValue={setValue}
                fieldSettings={fieldSettings}
            />
            <RouteInfo
                {...props}
                errors={errors}
                isView={isView}
                form={form}
                setValue={setValue}
                fieldSettings={fieldSettings}
            />

            <div className='margin-bottom-10'>
                <Form.Field className="margin-bottom-0">
                    <label className="margin-bottom-0">
                        {t(isView ? 'additionalDocuments' : 'attachAdditionalDocuments')}
                    </label>
                </Form.Field>
                {
                    isView
                        ? (docs?.length
                            ? <DocListView documents={docs || []}/>
                            : <label>{t('noStuckDocuments')}</label>)
                        : <DocListEdit
                            documents={docs || []}
                            onChange={onSaveDocs}
                            type={docTypesKey}
                            objectId={form.id}
                        />
                }
            </div>

            <FieldComponent
                type={TEXTAREA}
                error={errors.comment}
                className='d-block'
                label='customerInformation'
                rows={6}
                value={form.comment}
                name='comment'
                readOnly={isView}
                onChange={setValue}
                fieldSettings={fieldSettings}
            />
        </LoadingSegment>
    );
};

export default SecurityCheckInfo;