import React from 'react';
import {Modal, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import Form from "../../../form/Form";
import DocsById from "../../../documents/DocsById";
import Actions from "../../../actions/Actions";
import {useActionMutation} from "../../../../store/api/gridApi";
import Orders from "./Orders";
import SectionsForm from "../../../form/SectionsForm";

const Task = ({ task, gridName, customFields = [], errors, setValue, closeModal, setIgnoreChanges, getFieldSettings, orderIds, readOnly, form, getCustomCols, getCustomFieldSettings }) => {
    const {t} = useTranslation();

    const setTaskValue = (e, {name, value}) => {
        setValue(task.id, name, value)
    }

    const stateValue = task.status?.value;

    const sectionsForm = (data) => <SectionsForm
        {...data}
        stateValue={stateValue}
        maxHeight={800}
    />

    const panes = [
        {
            menuItem: t("information"),
            render: () => <Tab.Pane className='info info__task'>
                <Modal.Content>
                    <Form
                        stateValue={stateValue}
                        key={task.id}
                        errors={errors}
                        form={{...task, visitDate: form.visitDate}}
                        setValue={setTaskValue}
                        name={gridName}
                        colsCounts={4}
                        getFieldSettings={getFieldSettings}
                        getCustomFieldSettings={getCustomFieldSettings}
                        getCustomCols={getCustomCols}
                        platformId={form['platformId']?.value}
                        readOnly={readOnly}
                        modalContent={sectionsForm}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Actions
                        name={gridName}
                        actions={task?.actions || []}
                        actionFunc={useActionMutation}
                        refresh={closeModal}
                    />
                </Modal.Actions>
            </Tab.Pane>
        },
        {
            menuItem: t('orders'),
            render: () => <Tab.Pane>
                <Orders
                    orderIds={orderIds}
                    setIgnoreChanges={setIgnoreChanges}
                    task={task}
                    setValue={setTaskValue}
                    getFieldSettings={getFieldSettings}
                    getCustomFieldSettings={getCustomFieldSettings}
                    platformId={form['platformId']?.value}
                    readOnly={readOnly}
                />
            </Tab.Pane>
        },
        {
            menuItem: t('documents'),
            render: () => <Tab.Pane>
                <DocsById
                    id={task?.id}
                    type={gridName}
                />
            </Tab.Pane>
        }
    ]


    return (
        <Tab
            defaultActiveIndex={orderIds ? 1 : 0}
            panes={panes}
        />
    );
};

export default Task;