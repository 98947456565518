import React, {useState} from 'react';
import GroupTaskModal from "../grid/modals/GroupTaskModal";
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Confirm from "../modals/Confirm";
import {defaultFunc} from "../../utils/stateHelper";
import RowActions from "../table/components/rowActions";

const Actions = ({actions, actionFunc = defaultFunc, name, refresh, size = 'mini'}) => {
    const {t} = useTranslation();

    const [selectedAction, setSelectedAction] = useState(null);
    const [actionRequest, {isLoading}] = actionFunc();

    const btn = (action, clickOff) => {
        const additionalAttributes = {
            actionName: action.name,
            actionButtonName: action.buttonName,
            rowId: action.ids?.[0]
        }

        return <Button
            {...additionalAttributes}
            icon={action.icon}
            size={size}
            color={action.color}
            onClick={action.onClick ? action.onClick : () => !clickOff && setSelectedAction(action)}
            content={`${t(action.buttonName || action.name)} ${action.count > 1 ? `(${action.count})` : ''}`}
        />
    }

    const actionOnClick = () => {
        actionRequest({
            name,
            action: selectedAction?.name,
            ids: selectedAction?.ids
        })
            .unwrap()
            .then(res => {
                if (res.isError) {
                    res.message && toast.error(t(res.message || 'error'))
                } else {
                    res.message && toast.info(t(res.message || 'done'))
                }
                setSelectedAction(null);
                refresh && refresh();
            })
            .catch(e => {
                toast.error(t(e?.error || 'error'))
            })
    }

    return (
        <>
            {
                typeof actions !== 'function'
                    ? (actions || []).map(action => (
                            <span key={`actions-${action.name}`}>
                            {
                                action.name === 'addToTask'
                                    ? <GroupTaskModal
                                        ids={action.ids}
                                        refresh={refresh}
                                    >
                                        {btn(action, true)}
                                    </GroupTaskModal>
                                    : btn(action)
                            }
                        </span>
                        )
                    )
                    : <RowActions actions={actions}/>
            }
            <Confirm
                open={!!selectedAction}
                onCancel={() => setSelectedAction(null)}
                onConfirm={actionOnClick}
                content={`${t('areYouSureToComplete')} "${t(selectedAction?.buttonName || selectedAction?.name)}" ${selectedAction?.count > 1 ? `${t('for')} ` + selectedAction.count : ''}?`}
                cancelButton={t('cancel')}
                confirmButton={t("yes")}
                disabled={isLoading}
                loading={isLoading}
            />
        </>
    );
};

export default Actions;