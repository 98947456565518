import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const ftpFileUploaderApi = createApi({
    reducerPath: 'ftpFileUploaderApi',
    baseQuery: baseQuery('/ftpFileUploader'),
    endpoints: (build) => ({
        getFtpFileList: build.query({
            query: () => {
                return {
                    url: `/list`,
                }
            }
        }),
        uploadFileFromFtp: build.mutation({
            query: ({fileName}) => {
                return {
                    url: `/upload?fileName=${fileName}`,
                }
            }
        }),
        deleteFileFromFtp: build.mutation({
            query: ({fileName}) => {
                return {
                    url: `/delete?fileName=${fileName}`,
                }
            }
        }),
        getFileFromFtp: build.mutation({
            query: ({id}) => {
                return {
                    url: `/get?id=${id}`,
                }
            }
        }),
    })
});

export const {
    useLazyGetFtpFileListQuery,
    useDeleteFileFromFtpMutation,
    useGetFileFromFtpMutation,
    useUploadFileFromFtpMutation,
} = ftpFileUploaderApi;