import React from 'react';
import EditAndCreateTable from "../../../table/formTable/editAndCreateTable";
import {schedulesCols} from "../../../../constants/dictionaries";

const CompanySchedule = ({name = 'schedules', form, setValue}) => {
    const setRowsValue = (rows) => {
        setValue(null, {
            name,
            value: rows
        })
    }

    return (
        <div>
            <EditAndCreateTable
                columns={schedulesCols.map(s => s.name === 'activityId' ? {...s, forSelectValues: form.activityIds || null} : s)}
                rows={form && form[name]}
                setValue={setRowsValue}
                mergeFieldName='activityId'
            />
        </div>
    );
};

export default CompanySchedule;