import React from 'react';
import TableBlock from "../../../components/TableBlock/TableBlock";
import SecurityModal from "../SecurityModal";
import {securityGrids, securityServiceGridName} from "../../../constants/security";
import "../styles.scss"
import {useLazyGetHistoryTableQuery} from "../../../store/api/gridApi";

const HistoryByDriver = ({id}) => {
    const settings = securityGrids[1];

    const [getHistory, data] = useLazyGetHistoryTableQuery()

    const getList = () => {
        return [
            (data) => {
                getHistory(
                    {
                        data: data.paging,
                        id,
                        name: securityServiceGridName,
                        url: 'getDriverHistory'
                    })
            },
            data
        ]
    }

    const editBtn = (btn, refresh, id) => {
        return (
            <SecurityModal
                key={id}
                id={id}
                onClose={refresh}
                isService
            >
                {btn}
            </SecurityModal>
        );
    }

    return (
        <TableBlock
            key={`history-table_${id}`}
            idKey={id}
            className='history-table'
            settings={settings}
            editBtn={editBtn}
            getListQuery={getList}
        />
    );
};

export default HistoryByDriver;