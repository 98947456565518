import React from 'react';
import {Form} from "semantic-ui-react";
import './style.scss'
import {BOOLEAN} from "../../constants/fieldTypes";
import FieldComponent from "./index";

const Checkboxes = ({label, value, setValue, error, forSelectValues, name}) => {
    const setIsChecked = (val) => {
        const checked = !!value?.find(v => v.value === val.value)
        setValue(null, {
            name,
            value: checked
                ? value?.filter(v => v.value !== val.value)
                : [...(value || []), val]
        })
    }

    return (
        <Form.Field error={error}>
            {label && <label>{label}</label>}
            {
                forSelectValues.map(item => (
                    <FieldComponent
                        type={BOOLEAN}
                        key={item.name}
                        value={!!value?.find(v => v.value === item.value)}
                        name={item.value}
                        label={item.name}
                        onChange={() => setIsChecked(item)}
                    />
                ))
            }
        </Form.Field>
    );
};

export default Checkboxes;