import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Icon, Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {
    useLazyGetIsConnectedQuery,
    useStopViewForDriverMutation,
    useViewForDriverMutation
} from "../../../../store/api/surfaceApi";
import {useSelector} from "react-redux";
import {userInfoSelector} from "../../../../store/slices/userSlice";
import {AVAILABLE_OPEN_DATA_PROCESSING_AGREEMENT_FOR_DRIVER_PERMISSION} from "../../../../constants/permissionNames";

const Tools = ({style, driverId, onAccept}) => {
    const {t} = useTranslation();

    const userInfo = useSelector(userInfoSelector);
    const {permissions = []} = userInfo;

    const [id, setId] = useState(null);
    const [number, setNumber] = useState(null);
    const [isSendToSurface, setIsSendToSurface] = useState(false);
    const [isMeShow, setIsMeShow] = useState(false);
    const timer = useRef(null);

    const [viewForDriver] = useViewForDriverMutation();
    const [stopViewForDriver] = useStopViewForDriverMutation();
    const [getIsConnected] = useLazyGetIsConnectedQuery();

    const isSendToSurfaceRef = useRef();
    const driverIdRef = useRef();

    const toolsPermission = permissions.includes(AVAILABLE_OPEN_DATA_PROCESSING_AGREEMENT_FOR_DRIVER_PERMISSION);

    useEffect(() => {
        isSendToSurfaceRef.current = isSendToSurface;
        driverIdRef.current = driverId;
    }, [driverId, isSendToSurface])

    const load = () => {
        const isSendToSurface = isSendToSurfaceRef.current;
        const driverId = driverIdRef.current;

        getIsConnected().then(({data = {}})=> {
            if (isSendToSurface && driverId === null){
                onAccept && onAccept();
            }

            setId(data.id);
            setNumber(data.number || null)
            setIsSendToSurface(data.driverId !== null && data.driverId !== '')
            setIsMeShow(data.isMeShow)
        });
    }

    useEffect(() => {
        if (toolsPermission) {
            load();
            timer.current = setInterval(load, 3000);
        }

        return () => {
            if (timer.current !== null) {
                clearInterval(timer.current);
            }
            if (isSendToSurface) {
                stopViewOnSurface();
            }
        }
    }, [])

    const sendToSurface = () => {
        viewForDriver({id: driverId}).then(() => {
            setIsSendToSurface(true);
        });
    }

    const stopViewOnSurface = () => {
        stopViewForDriver({id: driverId}).then(() => {
            setIsSendToSurface(false);
        });
    }

    const onClickOpenDataProcessingAgreementForDriver = () => {
        window.open(window.location.origin + '/api/dataProcessingAgreementForDriver/Pdf?driverId=' + driverId);
    }

    return (
        <Form.Field style={{
            ...style,
            width: `${(style.width?.replace('%', '') * 2)}%`
        }}>
            <label>{t('tools')}</label>
            <div className='d-flex margin-bottom-2'>
                <Popup
                    content={t('informationAboutOpenDataProcessingAgreementForDriver')}
                    trigger={
                        <Button
                            icon='external'
                            onClick={(e) => {
                                e.preventDefault()
                                onClickOpenDataProcessingAgreementForDriver()
                            }}
                        />
                    }
                />
                {id !== null && number !== null && !isSendToSurface &&
                    <Popup
                        content={<span>{t('showFormOnTablet')} <b>{number}</b></span>}
                        trigger={
                            <Button
                                disabled={isSendToSurface}
                                icon='tablet alternate'
                                onClick={(e) => {
                                    e.preventDefault()
                                    sendToSurface()
                                }}
                            />
                        }
                    />
                }
                {id !== null && isSendToSurface && isMeShow &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            stopViewOnSurface()
                        }}
                        icon
                    >
                        <Icon name='stop'/> {t('stopShowing')}
                    </Button>
                }
                {id !== null && isSendToSurface && !isMeShow &&
                    <Popup
                        content={<span>{t('tabletIsOccupied')}</span>}
                        trigger={
                            <Button
                                icon='ban'
                                onClick={(e) => {
                                    e.preventDefault()
                                }}
                            />
                        }
                    />
                }
            </div>
        </Form.Field>
    );
};

export default Tools;