import React, {useState} from 'react';
import {Dimmer, Header, Icon, Loader, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useUploadFileMutation, useViewFileQuery} from "../../store/api/fileApi";
import {useSelector} from "react-redux";
import {getGlobalSettingByNameSelector} from "../../store/slices/userSlice";
import {MAX_FILE_SIZE_NAME} from "../../constants/options";
import {toast} from "react-toastify";

const FileUploader = ({onChange, document}) => {
    const {t} = useTranslation();

    const [active, setActive] = useState(false);

    const [uploadApi, {isLoading: loading}] = useUploadFileMutation();
    const [maxSize, maxSizeBytes] = useSelector(state => getGlobalSettingByNameSelector(state, MAX_FILE_SIZE_NAME));

    const {data, isLoading} = useViewFileQuery(
        {id: document.fileId},
        {skip: !document.fileId}
    );

    const onDragEnter = () => {
        setActive(true)
    }

    const onDragLeave = () => {
        setActive(false)
    }

    const onDragOver = (e) => {
        e.preventDefault();
    }

    const onDrop = (e) => {
        e.preventDefault();
        setActive(false);
        uploadFile(e, e.dataTransfer.files[0]);
    }

    const uploadFile = (e, file) => {
        onChange();
        let f = new FormData();
        file = file || e.target.files[0];

        if (file) {
            if (file.size > maxSizeBytes) {
                toast.error(t('errorMaxSize', {size: maxSize}))
            } else {
                f.append("formFile", file);
                uploadApi(f)
                    .unwrap()
                    .then((fileDto) => {
                        onChange(fileDto)
                    });
            }
        }
    }

    const loaded = !isLoading && document && document.fileId,
        name = document.fileName || "",
        extension = loaded ? name.substring(name.lastIndexOf(".") + 1) : "",
        isImage = ["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase()),
        src = loaded ? data : "",
        labelClass = `uploader ${loaded && "loaded"} ${active && "dragndrop"}`;

    let preview = (loading || isLoading) ? (
        <Dimmer active inverted>
            <Loader size='large'>{t('loading')}</Loader>
        </Dimmer>
    ) : (
        <Segment placeholder>
            <Header icon>
                <Icon name={loaded ? "file outline" : "upload"} />
            </Header>
            <p>{name}</p>
        </Segment>
    );

    if (loaded && isImage)
        preview = (
            <img src={src} className={loaded && "loaded"}  alt={'file'}/>
        );

    return (
        <label
            className={labelClass}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}>

            {preview}

            <input type="file" onChange={uploadFile} />
        </label>
    );
};

export default FileUploader;