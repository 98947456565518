import React from 'react';
import TransportInfo from "./TransportInfo";

const TransportsInfo = ({...props}) => {
    return (
        <>
            <TransportInfo
                {...props}
                withType
                name={'truck'}
            />
            <TransportInfo
                {...props}
                name={'trailer'}
            />
        </>
    );
};

export default TransportsInfo;