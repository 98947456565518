import React, {useEffect, useState} from 'react';
import './header.scss';
import {Dropdown, Icon, Menu} from "semantic-ui-react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    CREATE_INVITATION_LINK,
    CREATE_VISIT_FROM_BJ_LINK,
    HOME_LINK,
    LOGIN_LINK,
    SURFACE_LINK
} from "../../route/links";
import logo from '../../img/fmlogo2.png';
import {
    additionalItemsSelector,
    dictionariesSelector,
    gridsSelector,
    settingsSelector,
    userInfoSelector
} from "../../store/slices/userSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ProfileModal from "../modals/ProfileModal";
import {useActions} from "../../hooks/actions";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import ConnectSurfaceModal from "../modals/ConnectSurfaceModal";
import {transportDictionaryNames} from "../../constants/dictionaries";
import {securityGrids, securityServiceGridName} from "../../constants/security";
import {useChangeActivityMutation, useChangePlatformMutation, useChangeRoleMutation} from "../../store/api/userApi";
import {toast} from "react-toastify";
import { dictionarySettingsByName } from "../../constants/utils";
import {AVAILABLE_OPEN_DATA_PROCESSING_AGREEMENT_FOR_DRIVER_PERMISSION} from "../../constants/permissionNames";

const Header = () => {
    const [activeItem, setActiveItem] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const {setUser} = useActions();

    const {t} = useTranslation();

    const userInfo = useSelector(userInfoSelector);
    const grids = useSelector(gridsSelector);
    const dictionaries = useSelector(state => dictionariesSelector(state, true));
    const additionalItem = useSelector(state => additionalItemsSelector(state));
    const settingPages = useSelector(settingsSelector);

    const [changeRoleRequest, {isLoading}] = useChangeRoleMutation();
    const [changePlatformRequest, {isLoading: isLoadingPlatform}] = useChangePlatformMutation();
    const [changeActivityRequest, {isLoading: isLoadingActivity}] = useChangeActivityMutation();

    const {permissions = [], isLogged} = userInfo;

    const mainTabs = grids.map(g => ({
        ...g,
        name: g.name === securityServiceGridName ? securityGrids[0].name : g.name
    })).concat(dictionaries.filter(d => dictionarySettingsByName(d.name)?.main));

    const secTabs = dictionaries.filter(d => !dictionarySettingsByName(d.name)?.main && !transportDictionaryNames.includes(d.name));
    dictionaries.find(d => transportDictionaryNames.includes(d.name)) && secTabs.unshift({name: 'transports'})

    const changeTab = (tab) => {
        setActiveItem(tab);
    }

    useEffect(() => {
        const item = location.pathname.replace('/', '')
        setActiveItem(item)
    }, [location])

    const isTabSelected = (name) => {
        return activeItem.indexOf(name) > -1;
    }

    const {removeUser} = useActions();

    const logout = () => {
        removeUser();
        navigate(LOGIN_LINK);
    }

    const changeRole = (e, {value}) => {
        !isLoading && changeRoleRequest({roleId: value})
            .unwrap()
            .then((res) => {
                setUser(res)
                navigate(HOME_LINK)
            })
            .catch((error) => {
                toast.error(error.data)
            })
    }

    const allPlatforms = 'allPlatforms';
    const allActivities = 'allActivities';

    const platformOptions = [
        {
            key: allPlatforms,
            value: allPlatforms,
            text: t(allPlatforms),
            className: 'bottom-line'
        },
        ...(userInfo?.allowedPlatforms?.map(v => ({
            key: v.value,
            value: v.value,
            text: v.name
        })) || [])
    ]

    const activityOptions = [
        {
            key: allActivities,
            value: allActivities,
            text: t(allActivities),
            className: 'bottom-line'
        },
        ...(userInfo?.allowedActivities?.filter(a => a.platformId === userInfo?.currentPlatform?.value)?.map(v => ({
            key: v.value,
            value: v.value,
            text: v.name
        })) || [])
    ]

    const changePlatformOrActivity = (func) => {
        !isLoading && func()
            .unwrap()
            .then((res) => {
                setUser(res)
            })
            .catch((error) => {
                toast.error(error.data)
            })
    }

    const changePlatform = (e, {value}) => {
        const func = () => changePlatformRequest({platformId: value === allPlatforms ? null : value});
        changePlatformOrActivity(func, {value});
    }

    const changeActivity = (e, {value}) => {
        const func = () => changeActivityRequest({activityId: value === allActivities ? null : value});
        changePlatformOrActivity(func, {value});
    }

    return (
        !(
            location.pathname === SURFACE_LINK
            || location.pathname.indexOf(CREATE_VISIT_FROM_BJ_LINK) === 0
            || location.pathname === CREATE_INVITATION_LINK
            || !isLogged
        ) && <header>
            <Menu pointing secondary>
                <Menu.Item as={Link} to={HOME_LINK} header>
                    <img
                        src={logo}
                        title="FM Logistic"
                        alt="FM Logistic"
                    />
                </Menu.Item>
                {
                    mainTabs.map((tab, index) => {
                        return (
                            <Menu.Item
                                className="large"
                                key={index}
                                as={Link}
                                to={`/${tab.name}`}
                                name={tab.name}
                                active={isTabSelected(tab.name)}
                                onClick={() => changeTab(tab.name)}
                            >
                                {t(tab.name)}
                            </Menu.Item>
                        );
                    })
                }
                {
                    !!settingPages?.length && <Dropdown
                        text={t('settings')}
                        item
                        className={`${settingPages.some(x => isTabSelected(x.name)) && "superActive"}`}
                    >
                        <Dropdown.Menu className='z-index-max'>
                            {
                                settingPages?.map(page => <Dropdown.Item
                                    key={page.name}
                                    as={Link}
                                    to={page.name}
                                    name={page.name}
                                    active={isTabSelected(page.name)}
                                    onClick={() => changeTab(page.name)}
                                >
                                    {t(page.name)}
                                </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {
                    secTabs.length > 0 && <Dropdown
                        item
                        className={`icon ${secTabs.some(x => isTabSelected(x.name)) && "superActive"}`}
                        icon='cog'
                    >
                        <Dropdown.Menu className='z-index-max'>
                            {
                                secTabs.map((tab, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            as={Link}
                                            to={`/${tab.name}`}
                                            name={tab.name}
                                            active={isTabSelected(tab.name)}
                                            onClick={() => changeTab(tab.name)}
                                        >
                                            {t(tab.name)}
                                        </Dropdown.Item>
                                    );
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {
                    !!additionalItem.length && <Dropdown
                        item
                        className={`icon ${additionalItem.some(x => isTabSelected(x.name)) && "superActive"}`}
                        icon='th large fix-size-icon'
                    >
                        <Dropdown.Menu className='z-index-max'>
                            {
                                additionalItem.map((tab, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            as={Link}
                                            to={`/${tab.name}`}
                                            name={tab.name}
                                            active={isTabSelected(tab.name)}
                                            onClick={() => changeTab(tab.name)}
                                        >
                                            {t(tab.name)}
                                        </Dropdown.Item>
                                    );
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Menu.Menu position='right'>
                    <Dropdown
                        trigger={<div><Icon name='world'/> {platformOptions.find(p => p.value === (userInfo?.currentPlatform?.value || allPlatforms)).text}</div>}
                        value={userInfo?.currentPlatform?.value || allPlatforms}
                        options={platformOptions}
                        item
                        onChange={changePlatform}
                        loading={isLoadingPlatform}
                    />
                    <Dropdown
                        disabled={!userInfo?.currentPlatform?.value}
                        trigger={<div><Icon name='th'/> {activityOptions.find(p => p.value === (userInfo?.currentActivity?.value || allActivities)).text}</div>}
                        value={userInfo?.currentActivity?.value || allActivities}
                        options={activityOptions}
                        item
                        onChange={changeActivity}
                        loading={isLoadingActivity}
                    />
                    <Dropdown
                        value={userInfo?.currentRole.value}
                        options={userInfo?.allowedRoles.map(v => ({key: v.value, value: v.value, text: v.name}))}
                        item
                        onChange={changeRole}
                        loading={isLoading}
                    />
                    <Dropdown
                        text={`${userInfo.fullName || 'name'}`}
                        item
                    >
                        <Dropdown.Menu>
                            <ProfileModal>
                                <Dropdown.Item>{t('profile')}</Dropdown.Item>
                            </ProfileModal>
                            {permissions.includes(AVAILABLE_OPEN_DATA_PROCESSING_AGREEMENT_FOR_DRIVER_PERMISSION)
                                && <ConnectSurfaceModal>
                                    <Dropdown.Item>
                                        {t('workWithTheConsentFormForTheProcessingOfPersonalData')}
                                    </Dropdown.Item>
                                </ConnectSurfaceModal>
                            }
                            <Dropdown.Item
                                onClick={logout}
                            >
                                {t("exit")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
            <ChangePasswordModal open={!userInfo.passwordChanged}/>
        </header>
    );
};

export default Header;