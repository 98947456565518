import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";
import {endpoints} from "./gridAndDictionaryEndpoints";

export const dictionaryApi = createApi({
    reducerPath: 'dictionaryApi',
    baseQuery: baseQuery('/dictionary'),
    tagTypes: ['DictionaryElement'],
    endpoints: (build) => ({
        ...endpoints(build, 'DictionaryElement'),
        clickToggle: build.mutation({
            query: ({url, data, name, confirmation}) => {
                return {
                    url: `${name}/${data.id}/${url}`,
                    method: 'POST',
                    body: {
                        confirmation
                    }
                }
            },
        }),
        getFor: build.query({
            query: ({name, entityType, elementName}) => {
                return {
                    url: `/${name}/getFor`,
                    method: 'POST',
                    body: {
                        entityType,
                        name: elementName
                    }
                }
            },
            serializeQueryArgs: ({endpointName, queryArgs}) => {
                return endpointName + queryArgs.elementName;
            },
        }),
        googleApiKey: build.query({
            query: () => {
                return {
                    url: '/passRequests/getGoogleApiKey'
                }
            }
        }),
        createOpenApiToken: build.query({
            query: ({id}) => {
                return {
                    url: `/users/createOpenApiToken/${id}`,
                }
            },
        }),
        createViaGoogle: build.mutation({
            query: ({data, tokenId}) => {
                return {
                    url: '/passRequests/createViaGoogle',
                    method: 'POST',
                    body: {
                        data,
                        tokenId
                    }
                }
            }
        }),
        reloadPrevious: build.mutation({
            query: ({email, tokenId}) => {
                return {
                    url: '/passRequests/getByEmail',
                    method: 'POST',
                    body: {
                        data: email,
                        tokenId
                    }
                }
            }
        }),
        deleteRequest: build.mutation({
            query: ({data, tokenId}) => {
                return {
                    url: '/passRequests/deleteViaGoogle',
                    method: 'DELETE',
                    body: {
                        data,
                        tokenId
                    }
                }
            }
        }),
    })
});

export const {
    useClickToggleMutation,
    useLazyGetForQuery,

    useLazyGoogleApiKeyQuery,
    useLazyCreateOpenApiTokenQuery,
    useCreateViaGoogleMutation,
    useReloadPreviousMutation,
    useDeleteRequestMutation,

    //общие для справочников и гридов:
    useLazyGetListQuery,
    useLazyGetIdsQuery,
    useLazyGetDefaultFormQuery,
    useLazyGetCountersQuery,
    useLazyGetElementQuery,
    useSaveMutation,
    useDeleteMutation,
    useExportMutation,
    useImportMutation,
    useEditValueMutation,
    useActionMutation,
    useGetFieldSettingsMutation,
    useLazyGetListForDropdownQuery,
} = dictionaryApi;