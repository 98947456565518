import React, {useEffect, useState} from 'react';
import {Menu} from "semantic-ui-react";
import FieldComponent from "../../components/fields";
import {SELECT} from "../../constants/fieldTypes";
import {useForm} from "../../hooks/useForm";
import {useSelector} from "react-redux";
import {dictionariesSelector, userInfoSelector} from "../../store/slices/userSlice";
import {useActions} from "../../hooks/actions";
import DictionariesSettingTable from "./dictionariesSettingTable";

const DictionariesSettingPage = () => {
    const [form, setValue, setForm] = useForm({
        dictionaryId: null,
        roleId: null
    })

    const [isChanged, setIsChanged] = useState(false);

    const {setTrigger} = useActions();

    const userInfo = useSelector(userInfoSelector);
    const dictionaries = useSelector(dictionariesSelector);
    const dictionaryOptions = dictionaries?.filter(d => d.id)?.map(d => ({
        name: d.name,
        value: d.id,
        data: d.columns
    }))

    const {fieldMatrixDefault} = userInfo;

    useEffect(() => {
        if (userInfo) {
            const {fieldMatrixDefault} = userInfo;
            setValue(null, {
                name: 'roleId',
                value: fieldMatrixDefault?.role || null
            })
        }
    }, [userInfo]);

    useEffect(() => {
        if (dictionaries?.length) {
            setValue(null, {
                name: 'dictionaryId',
                value: dictionaryOptions[0]
            })
        }
    }, [dictionaries?.length]);

    useEffect(() => {
        return () => {
            isChanged && setTrigger();
        }
    }, [isChanged])

    const onChange = () => {
        setIsChanged(true);
    }

    return (
        <div className='table-block field-matrix'>
            <Menu>
                <Menu.Item className='field-matrix__menu-item'>
                    <FieldComponent
                        noLabel
                        type={SELECT}
                        value={form.dictionaryId}
                        name='dictionaryId'
                        onChange={setValue}
                        clearable={false}
                        forSelectValues={dictionaryOptions}
                    />
                </Menu.Item>
                <Menu.Item className='field-matrix__menu-item'>
                    <FieldComponent
                        noLabel
                        type={SELECT}
                        value={form.roleId}
                        name='roleId'
                        dictionaryName='roles'
                        onChange={setValue}
                        clearable={!fieldMatrixDefault?.role}
                        nullIsAll
                    />
                </Menu.Item>
            </Menu>
            <DictionariesSettingTable
                form={form}
                onChange={onChange}
            />
        </div>
    );
};

export default DictionariesSettingPage;