import React, {useEffect} from 'react';
import {Checkbox, Table} from "semantic-ui-react";
import TableCell from "./TableCell";
import {ACTIONS} from "../../../constants/fieldTypes";
import {isFirstOfMergeField, view} from "../../../utils/mergeRows";
import {convertColor, deleteStyle, hexToRgb, setStyle} from "../../../utils/styleForFields";

const TableRow = ({
                      columns,
                      row,
                      load,
                      checkboxes,
                      onSelect,
                      isSelected,
                      className,
                      onClick,
                      clickToggle,
                      name,
                      actionFunc,
                      changeFieldValue,
                      mergeFieldData,
                      backlights
                  }) => {
    const {backlights: rowBacklights = []} = row;

    const getBacklights = () => {
        const array = [];
        rowBacklights.forEach(b => {
            const backlight = backlights.find(backlight => backlight.id === b);
            backlight && array.push(backlight)
        })

        return array
    }

    const getBacklightStyle = (func) => {
        const bs = getBacklights();
        if (bs.length) {
            const {r, g, b} = hexToRgb(bs[bs.length - 1].color);
            return {
                background: func(r, g, b)
            };
        } else {
            return {}
        }
    }

    const id = `row-${row.id}`;
    const coefColor = 0.5;

    useEffect(() => {
        const styleId = `style-${id}`;

        const bs = getBacklights();
        const backgroundColor = bs[bs.length - 1]?.color;

        deleteStyle(styleId);
        backgroundColor && setStyle(`#${id}:hover, #${id}:hover .hc-checkbox__row {background: ${backgroundColor} !important}`, styleId)
    }, [backlights, row.id])

    const style = backlights?.length
        ? getBacklightStyle((r, g, b) => `rgba(${r}, ${g}, ${b}, ${coefColor})`)
        : {};

    const hcColor = getBacklightStyle((r, g, b) => {
        const color = convertColor(r, g, b, coefColor);
        const colorLine = `rgb(${r}, ${g}, ${b})`;
        return `linear-gradient(to right, ${colorLine} 15%, ${color} 0)`
    })

    return (
        <Table.Row
            id={id}
            className={`table-row ${className} ${rowBacklights?.join(' ')}`}
        >
            {
                checkboxes && <Table.Cell
                    className='hc-checkbox hc-checkbox__row'
                    style={hcColor}
                >
                    <Checkbox
                        checked={isSelected}
                        onChange={onSelect}/>
                </Table.Cell>
            }
            {
                columns.map((col, i) => {
                    if (!view(col, mergeFieldData)) return null;
                    const isFirstOfMerge = isFirstOfMergeField(col, mergeFieldData)

                    return <TableCell
                        style={style}
                        rowSpan={isFirstOfMerge && mergeFieldData?.count}
                        className={`${mergeFieldData && !isFirstOfMerge && 'border-left'} ${col.type === ACTIONS && 'hc-actions hc-checkbox__row'}`}
                        onClick={onClick}
                        key={i}
                        col={col}
                        row={row}
                        load={load}
                        clickToggle={clickToggle}
                        actionFunc={actionFunc}
                        name={name}
                        changeFieldValue={changeFieldValue}
                    />
                })
            }
        </Table.Row>
    );
};

export default TableRow;