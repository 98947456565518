import React, {useEffect, useState} from 'react';
import LoadingSegment from "../../../LoadingSegment";
import {Button, Icon, Popup, Tab} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import GateSchedule from "./GateSchedule";

const mergeSchedulesAndExceptions = (schedules = [], exceptions = []) => {
    const activities = new Map();

    schedules?.forEach((schedule) => {
        const value = schedule.activityId?.value;
        if (!activities.has(value)) {
            activities.set(value, {activityId: schedule.activityId, schedules: [], exceptions: []});
        }
        activities.get(value).schedules.push(schedule);
    });

    exceptions?.forEach((exception) => {
        const value = exception.activityId?.value;
        if (!activities.has(value)) {
            activities.set(value, {activityId: exception.activityId, schedules: [], exceptions: []});
        }
        activities.get(value).exceptions.push(exception);
    });

    return Array.from(activities.values());
}

const splitIntoSchedulesAndExceptions = (combinedArray) => {
    const schedules = [];
    const exceptions = [];

    combinedArray.forEach((item) => {
        if (item.schedules && item.schedules.length > 0) {
            schedules.push(...item.schedules);
        }
        if (item.exceptions && item.exceptions.length > 0) {
            exceptions.push(...item.exceptions);
        }
    });

    return {schedules, exceptions};
}

const Gate = ({form, setValue, name, readOnly}) => {
    const {t} = useTranslation();

    const [activeIndex, setActiveIndex] = useState(0);
    const loading = false;

    const [activities, setActivities] = useState(null);

    useEffect(() => {
        !activities && setActivities(mergeSchedulesAndExceptions(form?.schedules, form?.exceptions))
    }, [form?.schedules, form?.exceptions]);

    const save = (newActivities) => {
        const {schedules, exceptions} = splitIntoSchedulesAndExceptions(newActivities);

        setValue(null, {
            name: 'schedules',
            value: schedules
        });
        setValue(null, {
            name: 'exceptions',
            value: exceptions
        });
    }

    const setVal = (e, {value, name}, i) => {
        const newActivities = [...(activities || [])];
        if (name === 'activityId') {
            newActivities[i] = {
                [name]: value,
                schedules: newActivities[i].schedules?.map(s => ({
                    ...s,
                    [name]: value
                })),
                exceptions: newActivities[i].exceptions?.map(s => ({
                    ...s,
                    [name]: value
                }))
            }
        } else {
            newActivities[i] = {
                ...newActivities[i],
                [name]: value,
            }
        }

        setActivities(newActivities)
        save(newActivities)
    }

    const del = (e, i) => {
        e?.stopPropagation();

        if (i < activeIndex) {
            setActiveIndex(activeIndex - 1)
        } else if (i === activeIndex) {
            setActiveIndex(0)
        }

        setActivities(activities => {
            const newActivities = activities.filter((a, index) => index !== i);
            save(newActivities);
            return newActivities;
        })
    }

    const panes = activities?.map((val, i) => ({
        menuItem: {
            key: i,
            content: <div className='pane-name'>
                {val.activityId?.name || t('newGateSchedule')}
                {
                    !readOnly && <Popup
                        trigger={<Icon
                            name='delete'
                            onClick={(e) => del(e, i)}
                        />}
                        content={t('delete')}
                    />
                }
            </div>
        },
        render: () => {
            return <GateSchedule
                key={i}
                setValue={setVal}
                form={form}
                item={val}
                i={i}
                allActivitiesIds={activities?.map(v => v.activityId?.value)}
            />
        }
    }))

    const add = (e) => {
        e?.stopPropagation();
        setActivities(items => ([...items, {}]))
    }

    !readOnly && panes?.push({
        menuItem: {
            className: "add-button-tab-item",
            key: "new",
            content: <Button
                className="add-task-button"
                size='small'
                onClick={(e) => {
                    add(e);
                    setActiveIndex(panes.length - 1);
                }}
            >
                {t("add")}
            </Button>
        }
    })

    return (
        <LoadingSegment isLoading={loading}>
            <Tab
                activeIndex={activeIndex}
                onTabChange={(e, {activeIndex}) => setActiveIndex(activeIndex)}
                panes={panes}
                menu={{fluid: true, vertical: true}}
                menuPosition='left'
            />
        </LoadingSegment>
    );
};

export default Gate;