import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {
    CREATE_INVITATION_LINK,
    DICTIONARIES_SETTINGS_LINK,
    FIELD_MATRIX_LINK,
    GRID_LINK,
    HOME_LINK,
    LOGIN_LINK,
    SECURITY_SERVICE_LINK,
    SURFACE_LINK,
    TRANSPORTS_LINK
} from "./links";
import Login from "../pages/login/Login";
import {PrivateRoute} from "./privateRoute";
import Page from "../pages/page";
import {useSelector} from "react-redux";
import {
    additionalItemsNamesSelector,
    dictionaryNamesSelector,
    gridNamesSelector,
    isAuthSelector,
    settingsNameSelector,
    userInfoSelector
} from "../store/slices/userSlice";
import {UnauthRoute} from "./unauthRoute";
import Dictionary from "../components/dictionary/Dictionary";
import DictionariesTabs from "../components/dictionary/DictionariesTabs";
import SecurityPage from "../pages/security/SecurityPage";
import {transportDictionaryNames} from "../constants/dictionaries";
import {securityGrids, securityServiceGridName} from "../constants/security";
import Grid from "../components/grid/Grid";
import FieldMatrixPage from "../pages/fieldMatrix/fieldMatrixPage";
import {DICTIONARIES_SETTINGS_NAME, FIELD_MATRIX_NAME, GATE_REVIEWS_NAME} from "../constants/options";
import DictionariesSettingPage from "../pages/dictionariesSetting/dictionariesSettingPage";
import GateReviews from "../pages/additional/GateReviews";
import Surface from "../pages/surface/surface";

export const MainRoute = ({loading}) => {
    const userInfo = useSelector(userInfoSelector);

    const dictionariesArray = useSelector(dictionaryNamesSelector);
    const settingPages = useSelector(settingsNameSelector);
    const allGridsArray = useSelector(gridNamesSelector)
    const additionalItemsNames = useSelector(additionalItemsNamesSelector)
    const gridsArray = allGridsArray.filter(v => v !== securityServiceGridName);
    const isAuth = useSelector(isAuthSelector);

    const allPages = [
        ...allGridsArray.map(g => g === securityServiceGridName ? securityGrids[0].name : g),
        ...dictionariesArray
    ]

    const nextPage = () => {
        if (userInfo.isLogged) {
            const page = allPages[0];
            if (page) return "/" + page;
        }
        return '';
    }

    return (
        <Routes>
            <Route path={LOGIN_LINK} element={
                <UnauthRoute authPage={nextPage()}>
                    <Login/>
                </UnauthRoute>
            }/>
            <Route path={CREATE_INVITATION_LINK} element={<Login viewCreateInvitation/>}/>
            <Route path={SURFACE_LINK} element={<Surface/>}/>
            <Route index path={HOME_LINK} element={
                <PrivateRoute>
                    <Navigate to={nextPage()} replace/>
                </PrivateRoute>
            }/>
            <Route path={HOME_LINK} element={
                <PrivateRoute>
                    <Page loading={loading}/>
                </PrivateRoute>
            }>
                {
                    dictionariesArray.map(dictionary => (
                        <Route
                            key={dictionary}
                            path={GRID_LINK.replace(':gridName', dictionary)}
                            element={
                                <Dictionary
                                    key={dictionary}
                                    dictionaryName={dictionary}
                                />
                            }
                        />
                    ))
                }
                {
                    gridsArray.map(grid => (
                        <Route
                            key={grid}
                            path={GRID_LINK.replace(':gridName', grid)}
                            element={
                                <Grid
                                    key={grid}
                                    gridName={grid}
                                />
                            }
                        />
                    ))
                }
                {
                    dictionariesArray.find(v => transportDictionaryNames.includes(v)) && <Route
                        path={TRANSPORTS_LINK}
                        element={<DictionariesTabs
                            name='transports'
                            dictionaries={transportDictionaryNames}
                        />}
                    />
                }
                {
                    allGridsArray.includes(securityServiceGridName) && <Route
                        path={SECURITY_SERVICE_LINK}
                        element={<SecurityPage isService/>}
                    />
                }
                {
                    settingPages?.includes(FIELD_MATRIX_NAME) && <Route
                        path={FIELD_MATRIX_LINK}
                        element={<FieldMatrixPage/>}
                    />
                }
                {
                    settingPages?.includes(DICTIONARIES_SETTINGS_NAME) && <Route
                        path={DICTIONARIES_SETTINGS_LINK}
                        element={<DictionariesSettingPage/>}
                    />
                }
                {
                    additionalItemsNames?.includes(GATE_REVIEWS_NAME) && <Route
                        path={GATE_REVIEWS_NAME}
                        element={<GateReviews/>}
                    />
                }
                {
                    (!isAuth || (!loading && (dictionariesArray.length || allGridsArray.length))) && <Route
                        path={GRID_LINK}
                        element={<Navigate to={nextPage()} replace/>}
                    />
                }
            </Route>
        </Routes>
    )
}