import React, {useEffect, useState} from 'react';
import Form from "../../../form/Form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    gridOrDictionaryAdditionalFormSectionColumnsSelector,
    gridOrDictionaryFieldsSelector,
    userInfoSelector,
} from "../../../../store/slices/userSlice";
import {BACKLIGHT_CONSTRUCTOR_NAME} from "../../../../constants/options";
import {Button, Confirm, Divider, Icon, Popup, Segment} from "semantic-ui-react";
import TableActions from "../../../table/components/tableActions";
import {fieldsToValues, maxValueFromArray} from "../../../../utils/array";
import {MULTISELECT, SELECT} from "../../../../constants/fieldTypes";
import {useGetCustomColumnsMutation} from "../../../../store/api/gridApi";
import LoadingSegment from "../../../LoadingSegment";
import DeleteBlock from "../../../deleteBtn/deleteBlock";

const BlockSegment = ({children, logical, dividerClass, className}) => {
    return <div className='margin-bottom-10'>
        {logical && <Divider
            className={dividerClass}
            horizontal
        >
            {logical}
        </Divider>}
        <Segment className={className}>
            {children}
        </Segment>
    </div>
}

const ConditionForm = ({
                           form,
                           row,
                           i,
                           setValueInit,
                           fieldsInit,
                           errors,
                           onDelete,
                           fieldsByObjectName
                       }) => {
    const {t} = useTranslation();

    const fieldNameForSelectValues = fieldsToValues(fieldsByObjectName);

    const fieldName = row.fieldName?.value;
    const propertyFieldName = row.propertyFieldName?.value;
    const fieldData = fieldsByObjectName.find(f => f.name === fieldName) || {};

    const fieldsByFieldNameSource = useSelector(state => fieldData?.source ? gridOrDictionaryFieldsSelector(state, fieldData.source) : []);

    const propertyValue = 'property'

    const setValue = (id, d) => {
        const moreValues = {};
        const {name, value} = d;

        if (['fieldName', 'compareWithOtherField', 'propertyFieldName'].includes(name)
            || (name === 'ruleType' && value?.value === propertyValue)
        ) {
            moreValues.value = null;
        }

        if (['fieldName', 'ruleType'].includes(name)) {
            moreValues.propertyFieldName = null;
            moreValues.propertyRuleType = null;
        }

        if (['fieldName'].includes(name)) {
            moreValues.ruleType = null;
        }

        if (['propertyFieldName'].includes(name)) {
            moreValues.propertyRuleType = null;
        }

        setValueInit(id, d, moreValues)
    }

    const rowIsProperty = row.ruleType?.value === propertyValue
    let fields = !rowIsProperty
        ? fieldsInit.filter(f => !['propertyFieldName', 'propertyRuleType'].includes(f.name))
        : fieldsInit;

    fields = fields.map(f => {
        switch (f.name) {
            case 'fieldName': {
                return {
                    ...f,
                    forSelectValues: fieldNameForSelectValues
                }
            }
            case 'propertyFieldName': {
                return {
                    ...f,
                    forSelectValues: fieldsToValues(fieldsByFieldNameSource)
                }
            }
            case 'propertyRuleType': {
                return {
                    ...f,
                    forSelectValues: f.forSelectValues.filter(v => v.name !== propertyValue)
                }
            }
            default: {
                return f
            }
        }
    })

    if (fieldName) {
        const valueFieldData = (row.ruleType?.value !== propertyValue)
            ? fieldData
            : fieldsByFieldNameSource.find(f => f.name === propertyFieldName) || {};

        fields.push({
            ...valueFieldData,
            clearable: true,
            isReadOnly: false,
            isRequired: false,
            name: 'value',
            displayNameKey: 'value',
            onlyValue: true,
            separator: (valueFieldData.type === MULTISELECT) && '|',
            type: row.compareWithOtherField
                ? SELECT
                : valueFieldData.type,
            forSelectValues: row.compareWithOtherField
                ? fieldsToValues(fieldsByObjectName.filter(f => f.type === valueFieldData.type && f.name !== (propertyFieldName || fieldName)))
                : valueFieldData.forSelectValues
        })
    }

    return <BlockSegment
        logical={(i > 0) && t(row.logicalAnd?.name || '?')}
    >
        <DeleteBlock
            className='condition-group__delete'
            onDelete={() => onDelete(row.id)}
        />
        <Form
            parentForm={form}
            customWidths={{
                logicalAnd: '12',
                fieldName: '50',
                ruleType: '38',
                propertyFieldName: '50',
                propertyRuleType: '50',
                compareWithOtherField: '50',
                value: '50'
            }}
            form={row}
            errors={errors}
            fields={fields}
            setValue={(e, d) => setValue(row.id, d)}
            colsCounts={4}
            readOnly={false}
        />
    </BlockSegment>
}

const ConditionGroupBlock = ({
                                 form,
                                 fieldsByObjectName,
                                 rowsByConditionGroup,
                                 conditionGroup,
                                 fields: fieldsInit,
                                 addNewRow,
                                 logical,
                                 errors,
                                 setValue: setValueInit,
                                 onDelete
                             }) => {
    return <BlockSegment
        dividerClass='condition-group__divider'
        className='condition-group__block'
        logical={logical}
    >
        {
            rowsByConditionGroup(conditionGroup).map((row, i) => (
                <ConditionForm
                    form={form}
                    row={row}
                    i={i}
                    errors={errors}
                    fieldsInit={fieldsInit}
                    setValueInit={setValueInit}
                    onDelete={onDelete}
                    fieldsByObjectName={fieldsByObjectName}
                />)
            )
        }
        <TableActions
            footerBtns={[{
                name: 'addCondition',
                icon: 'plus',
                onClick: () => addNewRow(conditionGroup)
            }]}
        />
    </BlockSegment>
}

const Backlights = ({form, setValue, errors}) => {
    const {t} = useTranslation();
    const name = BACKLIGHT_CONSTRUCTOR_NAME;

    const objectName = form.applicableToObject?.value;
    const fieldsByObjectName = useSelector(state => gridOrDictionaryFieldsSelector(state, objectName));

    const [getCustomCols, {data: customCols, isLoading}] = useGetCustomColumnsMutation();

    const userInfo = useSelector(userInfoSelector);
    const platformValue = userInfo?.currentPlatform?.value;

    useEffect(() => {
        objectName && getCustomCols({
            name: objectName,
            platformId: platformValue
        })
    }, [objectName])

    const setRowsValue = (rows) => {
        setValue(null, {
            name,
            value: rows
        })
    }

    const fields = useSelector(state => gridOrDictionaryAdditionalFormSectionColumnsSelector(state, BACKLIGHT_CONSTRUCTOR_NAME));
    const rows = [...(form && form[name]) || []]
        .sort((a, b) => a.conditionGroup - b.conditionGroup)
        .map((r, i) => ({...r, id: r.id || i}));

    const addNewRow = (conditionGroup) => {
        const defaultLogical = 'and'
        setRowsValue([
            ...rows,
            {
                conditionGroup: conditionGroup || (maxValueFromArray(rows, 'conditionGroup') + 1),
                logicalAnd: {
                    value: defaultLogical,
                    name: defaultLogical
                },
                compareWithOtherField: false
            }
        ])
    }

    const conditionGroups = [...new Set(rows.map(r => r.conditionGroup))];

    const rowsByConditionGroup = (conditionGroup) => {
        return rows.filter(r => r.conditionGroup === conditionGroup)
    }

    const setValueById = (id, {name, value, checked}, moreValues = {}) => {
        const rowsNew = [...rows];
        const index = rows.findIndex(v => v.id === id);
        rowsNew[index] = {
            ...rowsNew[index],
            [name]: value || checked || null,
            ...moreValues
        }
        setRowsValue(rowsNew)
    }

    const onDelete = (id) => {
        setRowsValue(rows.filter(r => r.id !== id))
    }

    return (
        <LoadingSegment isLoading={isLoading}>
            <div className='margin-10'>
                <div className='margin-bottom-10 text-align-r'>
                    <Button
                        onClick={() => addNewRow()}
                        size='mini'
                    >
                        <Icon name='plus'/> {t('addConditionGroups')}
                    </Button>
                </div>

                {
                    conditionGroups.map((n, i) => <ConditionGroupBlock
                        form={form}
                        fieldsByObjectName={[
                            ...(fieldsByObjectName || []),
                            ...(customCols || [])
                        ]}
                        logical={(i > 0) && t(rowsByConditionGroup(n)[0].logicalAnd?.name || '?')}
                        rowsByConditionGroup={rowsByConditionGroup}
                        fields={fields}
                        addNewRow={addNewRow}
                        conditionGroup={n}
                        errors={errors}
                        setValue={setValueById}
                        onDelete={onDelete}
                    />)
                }
            </div>
        </LoadingSegment>
    );
};

export default Backlights;