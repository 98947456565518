import React, {memo} from 'react';
import {
    ACTIONS,
    BOOLEAN,
    COLOR,
    customFieldsName,
    ENUM,
    FILLING_SELECT,
    IS_ACTIVE,
    KEY_TEXT,
    MULTISELECT,
    MULTISELECT_WITH_ALL,
    MULTISELECT_WITH_ALL_PLUS_EMPTY,
    PHONE_NUMBER,
    SCHEDULE,
    SECURITY_STATE,
    SELECT,
    STATE,
    TEXT
} from "../../../constants/fieldTypes";
import {Icon, Label, Popup} from "semantic-ui-react";
import StatusValue from "./StatusValue";
import {securityServiceStatusColor} from "../../../utils/stateHelper";
import {useTranslation} from "react-i18next";
import Actions from "../../actions/Actions";
import Schedule from "../../table/components/schedule";
import IsActive from "./IsActive";
import ValuePopup from "./ValuePopup";
import {dictionaries} from "../../../constants/dictionaries";
import {eventStopPropagation} from "../../../constants/utils";

const Value = ({row, load, col, clickMutation, actionFunc, name}) => {
    const {t} = useTranslation();

    const getCustomFieldValue = () => {
        return row[customFieldsName]?.find(v => v.id === col.customFieldId)?.value
    }

    const value = (row, col) => {
        const value = !Array.isArray(col.name) && (col.isCustom ? getCustomFieldValue() : row[col.name]);

        switch (col.type) {
            case IS_ACTIVE: {
                return <IsActive
                    value={value}
                    row={row}
                    clickMutation={clickMutation}
                    load={load}
                />
            }
            case BOOLEAN: {
                return !!value ? t('yes') : t('no')
            }
            case PHONE_NUMBER:
            case TEXT: {
                const isBigText = value?.length > 100;
                const valText = (value || '')?.slice(0, 100) + (isBigText ? '...' : '');
                return isBigText
                    ? <Popup
                        hoverable
                        content={<div
                            onClick={eventStopPropagation}
                            className='value-text'
                        >
                            {value}
                        </div>}
                        trigger={
                            <div className='value-text_val'>{valText}</div>
                        }
                    />
                    : valText
            }
            case KEY_TEXT: {
                return t(value || '')
            }
            case ENUM:
            case SELECT: {
                const withPopup = dictionaries.filter(d => !!d.fieldsForViewer).map(d => d.name).includes(col?.source);

                if (!withPopup) {
                    return value ? t(value.name) : '';
                } else {
                    return value ? <ValuePopup
                        col={col}
                        value={value}
                        load={load}
                    /> : ''
                }
            }
            case STATE: {
                const color = col?.forSelectValues?.find(s => s.value === value?.value)?.color;
                return value ? <span className='state-value'>{color && <Icon className='state-value__icon' color={color} name="circle"/>} {t(value.name)}</span> : ''
            }
            case COLOR: {
                return value ? <span className='state-value'>
                    <Icon
                        style={{
                            color: value || 'white'
                        }}
                        name="circle"
                    /> {value}
                </span> : ''
            }
            case MULTISELECT:
            case FILLING_SELECT:
            case MULTISELECT_WITH_ALL_PLUS_EMPTY:
            case MULTISELECT_WITH_ALL: {
                return ([
                    MULTISELECT_WITH_ALL,
                    MULTISELECT_WITH_ALL_PLUS_EMPTY
                ].includes(col.type) && value === null)
                    ? t(`${col.name}All`)
                    : value?.map((v, i) => <Label className='value-label' key={i}>{v ? t(v.name) : ''}</Label>)
            }
            case SECURITY_STATE: {
                const val = (value && typeof value === 'object') ? value.value : value
                return val &&
                    <StatusValue
                        value={val}
                        stateColors={securityServiceStatusColor()}
                        text={t(val)}
                    />
            }
            case ACTIONS: {
                return <Actions
                    actions={
                        typeof value === 'function' ?
                            value
                            : (value || []).map(v => ({
                                ...v,
                                ids: [row.id]
                            }))
                    }
                    refresh={load}
                    name={name}
                    actionFunc={actionFunc}
                />
            }
            case SCHEDULE: {
                return <Schedule
                    value={row}
                    name={name}
                    isDisabled
                />
            }
            default: {
                return (value && typeof value === 'object')
                    ? value.value
                    : value
            }
        }
    }

    return value(row, col);
};

export default memo(Value);
