import React from 'react';
import {Card, Form} from "semantic-ui-react";
import SectionForm from "./SectionForm";
import {useTranslation} from "react-i18next";

const SectionBlock = ({section, style, customBlock, ...props}) => {
    const {t} = useTranslation();

    return (
        <Form.Field
            style={style}
            className='section-form'
        >
            <label>{t(section)}</label>
            <Card>
                <SectionForm
                    {...props}
                    section={section}
                    customBlock={customBlock}
                />
            </Card>
        </Form.Field>
    );
};

export default SectionBlock;