import React from 'react';
import DictionaryDropdown from "./DictionaryDropdown";
import EnumDropdown from "./EnumDropdown";

const DictionaryField = ({dictionaryName, gridName, label, noLabel, ...props}) => {
    return (
        (props.forSelectValues?.length || props.refValuesByField || (!dictionaryName && !gridName && !props.source))
            ? <EnumDropdown
                {...props}
                label={label || props.name}
                noLabel={noLabel}
            />
            : <DictionaryDropdown
                {...props}
                label={!noLabel && (label || props.name)}
                dictionaryName={dictionaryName || (!gridName && props.source)}
                gridName={gridName}
            />
    );
};

export default DictionaryField;