import React from 'react';
import {Navigate} from "react-router-dom"
import {LOGIN_LINK} from "./links";
import {isAuthSelector} from "../store/slices/userSlice";
import {useSelector} from "react-redux";

export const PrivateRoute = ({ children }) => {
    const isAuth = useSelector(isAuthSelector);

    if (!isAuth) {
        return <Navigate to={LOGIN_LINK} replace />;
    }

    return children;
};