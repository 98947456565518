import React from 'react';
import {Grid} from "semantic-ui-react";
import LoadingSegment from "../../LoadingSegment";
import Actions from "../../actions/Actions";
import Backlights from "./Backlights";

const Footer = ({refFooter, actions, loading, actionFunc, name, refresh, setBacklights, backlights, backlightsIsFilter, setBacklightsIsFilter}) => {
    return (
        <div
            className='table-block__footer'
            ref={refFooter}
        >
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <LoadingSegment
                            isLoading={loading}
                            size='mini'
                        >
                            <Actions
                                actionFunc={actionFunc}
                                name={name}
                                actions={actions}
                                refresh={refresh}
                            />
                        </LoadingSegment>
                    </Grid.Column>
                    <Grid.Column textAlign='right' width={8}>
                        {
                            setBacklights && <Backlights
                                backlightsIsFilter={backlightsIsFilter}
                                setBacklightsIsFilter={setBacklightsIsFilter}
                                backlights={backlights}
                                gridName={name}
                                setBacklights={setBacklights}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default Footer;