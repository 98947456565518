import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "../../hooks/useForm";
import {surfaceStates} from "../../constants/surface";
import {Button, Icon, Loader, Message} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import logo from '../../img/logo.png'
import {
    useAcceptMutation,
    useClearMutation,
    useCreateMutation,
    useLazyGetContentQuery
} from "../../store/api/surfaceApi";
import LoadingSegment from "../../components/LoadingSegment";
import * as pdfjsLib from 'pdfjs-dist/webpack';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const Surface = ({}) => {
    const {t} = useTranslation();
    const [form, setValue] = useForm({
        state: surfaceStates.NONE
    });
    const [timer, setTimer] = useState();
    const [setAccept, {isLoading}] = useAcceptMutation();
    const [create, {isLoading: createLoading}] = useCreateMutation();
    const [clear, {isLoading: clearLoading}] = useClearMutation();
    const [getContent, {isLoading: getContentLoading}] = useLazyGetContentQuery();

    const {
        id, state, number, driverId
    } = form;

    const stateRef = useRef();
    const idRef = useRef();
    const timerRef = useRef(0);

    useEffect(() => {
        idRef.current = id;
    }, [id])

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    useEffect(() => {
        timerRef.current = timer;
    }, [timer])

    const callback = () => {
        const state = stateRef.current;
        const id = idRef.current;
        if (id) {
            getContent({id})
                .unwrap()
                .then((content) => {
                    if (state === surfaceStates.CREATED && content.connectedUserCount > 0) {
                        setValue(null, {name: 'state', value: surfaceStates.CONNECTED});
                        return;
                    }

                    if (state > surfaceStates.CREATED && !content.connectedUserCount) {
                        //сброс кода при отключении планшета
                        if (state === surfaceStates.VIEW_DRIVER) {
                            document.getElementById('surface-pdf-pages').innerHTML = "";
                        }
                        onOpenPage();
                    }

                    if (state === surfaceStates.CONNECTED && content.driverId !== null) {
                        const loadingTask = pdfjsLib.getDocument(window.location.origin + '/api/dataProcessingAgreementForDriver/Pdf?driverId=' + content.driverId);

                        loadingTask.promise.then(function (pdf) {
                            let renderPage = (pdf, pageNumber) => {
                                pdf.getPage(pageNumber).then(function (page) {
                                    let canvas = document.createElement('canvas');
                                    let scale = 1.5;
                                    let viewport = page.getViewport({scale: scale,});
                                    let pagesElement = document.getElementById('surface-pdf-pages');

                                    pagesElement.append(canvas);

                                    let context = canvas.getContext('2d');
                                    canvas.height = viewport.height;
                                    canvas.width = viewport.width;

                                    const renderContext = {
                                        canvasContext: context,
                                        viewport: viewport
                                    };
                                    page.render(renderContext);
                                    if (pdf._pdfInfo.numPages > pageNumber) {
                                        renderPage(pdf, pageNumber + 1)
                                    } else {
                                        return 0;
                                    }
                                }, function (reason) {
                                    console.error(reason);
                                });
                            };
                            renderPage(pdf, 1);
                        });

                        setValue(null, {name: 'state', value: surfaceStates.VIEW_DRIVER});
                        setValue(null, {name: 'driverId', value: content.driverId});
                        setTimer(10 * 60 * 1000);
                        return;
                    }

                    if (state === surfaceStates.VIEW_DRIVER && content.driverId === null) {
                        document.getElementById('surface-pdf-pages').innerHTML = "";
                        setValue(null, {name: 'state', value: surfaceStates.CONNECTED});
                        setValue(null, {name: 'driverId', value: ''});
                        setTimer(0);
                    }
                })
        }
    };

    const onOpenPage = () => {
        create()
            .unwrap()
            .then(data => {
                setValue(null, {name: 'id', value: data.id});
                setValue(null, {name: 'number', value: data.number});
                setValue(null, {name: 'state', value: surfaceStates.CREATED});

                setInterval(callback, 3000);
            })
    }

    useEffect(() => {
        onOpenPage();
        setInterval(tick, 1000);
    }, []);

    const tick = () => {
        if (timerRef.current > 0) {
            setTimer(timer => timer - 1000)
        } else {
            if (driverId) {
                clear({id})
                    .unwrap()
                    .then(() => {
                        setValue(null, {name: 'driverId', value: ''});
                    })
            }
        }
    }


    const accept = () => {
        setAccept({driverId})
            .unwrap()
            .then(() => {
                document.getElementById('surface-pdf-pages').innerHTML = "";
                setValue(null, {name: 'driverId', value: ''});
                setValue(null, {name: 'state', value: surfaceStates.CONNECTED});
            })
    }

    const getTimer = (millisec) => {
        let seconds = (millisec / 1000).toFixed(0);
        let minutes = Math.floor(seconds / 60);
        let hours = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        if (hours !== "") {
            return hours + ":" + minutes + ":" + seconds;
        }
        return `${minutes}${t('min')} ${seconds}${t('sec')}`;
    };

    const getSurfacePageDataByState = (state) => {
        switch (state) {
            case surfaceStates.NONE:
                return <div>
                    <div className="surface-text">
                        <Loader active/>
                        <span className="surface-text-header">{t('initializingARemoteTablet')}</span>
                    </div>
                </div>;
            case surfaceStates.CREATED:
                return <div className="surface">
                    <div className="surface-text">
                        <span className="surface-text-header">{t('remoteTablet')}</span>
                        <span className="margin-bottom-15">{t('configRemoteTabletTextInfo')}</span>
                        <span className="margin-bottom-15">{t('configRemoteTabletTextInstruction')}</span>
                        <span><span>{t('tabletNumber')}:</span> <span
                            className="surface-text-number">{number}</span></span>
                    </div>
                </div>;
            case surfaceStates.CONNECTED:
                return <div className="surface">
                    <div>
                        <img src={logo} title="Fm logistic" alt="Fm logistic"/>
                    </div>
                    <div className="surface-number">
                        <span>{t('tabletNumber')}:</span>
                        <span className="surface-text-number">{number}</span>
                    </div>
                </div>;
            case surfaceStates.VIEW_DRIVER:
                return (
                    <div className="surface-pdf">
                        <div id="surface-pdf-pages">
                        </div>
                        <div className="surface-confirm">
                            <Message warning>
                                <Icon name="info circle"/>
                                {t('consentDateTimeInfo')} {t('formHideTimer')} <span>{getTimer(timerRef.current)}</span>
                            </Message>
                            <Button
                                onClick={accept}
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                {t('agreeBtn')}
                            </Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <LoadingSegment isLoading={createLoading || clearLoading || getContentLoading}>
            <div className="surface">
                {getSurfacePageDataByState(state)}
            </div>
        </LoadingSegment>
    );
};

export default Surface;