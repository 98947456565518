import React from 'react';
import DocView from "./DocView";

const DocListView = ({documents}) => {
    return (
        <div className="flex-container">
            {documents.map((document, index) => (
                <DocView key={index} document={document} />
            ))}
        </div>
    );
};

export default DocListView;