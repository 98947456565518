import React, {useState} from 'react';
import {Checkbox} from "semantic-ui-react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Confirm from "../../modals/Confirm";

const IsActive = ({row, value, clickMutation, load}) => {
    const {t} = useTranslation();

    const [clickToggle, {isLoading, originalArgs}] = clickMutation();
    const [confirm, setConfirm] = useState(null);

    const changeToggleValue = (isConfirm) => {
        clickToggle({
            url: 'changeActivity',
            data: {
                id: row.id,
            },
            confirmation: isConfirm
        })
            .unwrap()
            .then((res) => {
                if (res.needConfirmation) {
                    setConfirm(t(res.message));
                } else {
                    load && load()
                    isConfirm && setConfirm(null);
                }
            })
            .catch((e) => {
                load && load()
                toast.error(t(e?.data?.message || e?.data || 'error'));
                isConfirm && setConfirm(null);
            });
    };

    return <>
        <Checkbox
            disabled={isLoading && (originalArgs?.data?.id === row.id)}
            toggle
            itemID={row.id}
            checked={!!value}
            onChange={(e, {checked}) => {
                e.stopPropagation()
                changeToggleValue()
            }}
        />
        <Confirm
            onClick={e => e.stopPropagation()}
            open={!!confirm}
            onCancel={() => setConfirm(null)}
            onConfirm={() => changeToggleValue(true)}
            content={confirm}
            cancelButton={t('cancel')}
            confirmButton={t("confirm")}
            disabled={isLoading}
            loading={isLoading}
        />
    </>
};

export default IsActive;