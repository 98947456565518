import React from 'react';
import Form from "./Form";

const SectionForm = ({form, setValue, errors, name, fieldSettings, section, colsCounts, customBlock, stateValue}) => {
    return (
        <Form
            errors={errors}
            form={form}
            setValue={setValue}
            name={name}
            colsCounts={colsCounts}
            preFieldSettings={fieldSettings}
            section={section}
            customBlock={customBlock}
            stateValue={stateValue}
        />
    );
};

export default SectionForm;