import React, {useCallback, useState} from 'react';
import {Button, Dropdown, Icon, Loader, Visibility} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useLazyGetListForDropdownQuery} from "../../store/api/dictionaryApi";
import {useLazyGetListForDropdownQuery as gridUseLazyGetListForDropdownQuery} from "../../store/api/gridApi";
import {PAGE_SIZE} from "../../constants/options";
import './style.scss'
import {getValue} from "./utils";
import {useSelector} from "react-redux";
import {dictionaryOrGridEditPermission} from "../../store/slices/userSlice";
import EditBtn from "../dictionary/EditBtn";
import {useDeepEffect} from "../../hooks/useDeepEffect";
import {removeDuplicates} from "../../utils/array";
import _debounce from "lodash/debounce";

const DictionaryDropdown = ({
                                onlyValue,
                                separator,
                                className,
                                name,
                                dictionaryName,
                                gridName,
                                label,
                                isDisabled,
                                isRequired,
                                value,
                                onChange,
                                withEdit = false,
                                withCreate = false,
                                filter,
                                multiple,
                                placeholder,
                                clearable,
                                nullIsAll,
                                hiddenValues,
                                upward,
                                onLoadValues,
                                requiredFilters
                            }) => {
    const {t} = useTranslation();

    const allRequiredFiltersIsNoEmpty = (filter) => (requiredFilters || []).length ? requiredFilters.every(f => filter[f]) : true;
    const canEdit = useSelector(state => dictionaryOrGridEditPermission(state, gridName || dictionaryName))

    const getListQuery = !gridName ? useLazyGetListForDropdownQuery : gridUseLazyGetListForDropdownQuery;

    const [getList, {data, isLoading}] = getListQuery();
    const [searchQuery, setSearchQuery] = useState('');

    const selectedItems = data?.selectedItems || [];
    const items = removeDuplicates([...selectedItems, ...(data?.items || [])]);
    const totalCount = data?.totalCount || 0;

    const loadList = (searchQuery, skip) => {
        allRequiredFiltersIsNoEmpty(filter) && getList({
            name: gridName || dictionaryName,
            selectedIds: (
                    value && (
                        multiple
                            ? (separator ? value?.split(separator) : value?.map(v => v.value))
                            : (!onlyValue ? (value?.value && [value.value]) : value && [value])
                    )
                )
                || null,
            search: searchQuery || '',
            paging: {skip: skip || 0, take: PAGE_SIZE},
            filter
        })
            .unwrap()
            .then(res => {
                onLoadValues && onLoadValues(itemsToOptions(res?.items), name)
            })
            .catch(e => console.error(e))
    }

    const debounceGetList = useCallback(_debounce(loadList, 1000), [requiredFilters, filter]);

    useDeepEffect(() => {
        loadList();
    }, [dictionaryName, !onlyValue && value, requiredFilters && filter]);

    const handleOnChange = (e, {name, value, options}) => {
        onChange && onChange(e, {
                name,
                value: onlyValue
                    ? (separator ? value?.join(separator) : value)
                    : getValue(value, multiple, options)
            }
        );
        setSearchQuery('')
    }

    const handleSearchChange = (e, {searchQuery}) => {
        setSearchQuery(searchQuery || '');
        debounceGetList(searchQuery);
    }

    const onFocus = () => {
        loadList(searchQuery)
    }

    const search = (options, queryStr) => {
        const query = queryStr.replace(/\s{2,}/g, ' ').trim();
        const lowerCaseQuery = query.toLowerCase();

        return options.filter(opt =>
            opt.text?.toLowerCase().includes(lowerCaseQuery) ||
            opt.description?.toLowerCase().includes(lowerCaseQuery)
        );
    };

    const editBtn = (isEdit) => {
        return (
            <EditBtn
                dictionaryName={dictionaryName}
                id={isEdit && value?.value}
                isDisabled={isDisabled}
                onSave={(data) => onChange(null, {name, value: {value: data.id}})}
                btn={<Button className='btn-add'>
                    <Icon name={isEdit ? 'edit' : 'plus'}/>
                </Button>}
            />
        )
    }

    const itemsToOptions = (items) => {
        return items.map(i => ({
            ...i,
            key: i.value,
            value: i.value,
            text: i.name,
            description: i.description,
            disabled: i.disabled
        }))
    }

    const getOptions = () => {
        const values = search(itemsToOptions(items || []).filter(v => !hiddenValues?.includes(v.value)), searchQuery);
        if (value && !onlyValue) {
            itemsToOptions(multiple ? value : [value]).forEach(v => {
                if (!values.find(val => val.value === v.value)) {
                    values.unshift(v);
                }
            })
        }
        if (value && onlyValue) {
            const selectedValues = multiple ? value.split(separator) : [value];

            (selectedValues || []).filter(v => v).map(selectedValue => {
                if (!values.find(val => val.value === selectedValue)) {
                    const v = itemsToOptions(items || []).find(i => i.value === selectedValue);
                    values.unshift(v || {value: selectedValue});
                }
            })
        }

        (totalCount > items.length && (items.length === values.length)) && values.push({
            className: 'visibility-item',
            key: 'infinity-key',
            // компонент Visibility устарел, но альтернативный вариант, предложенный разработчиками
            // Semantic UI в виде Intersection Observer API внедрить без багов в инфинити скролл дропдауна не удалось
            // пока оставляю так, если найду способ лучше - изменю.
            // При обновлении версии Semantic UI потребуется import Visibility from "@semantic-ui-react/component-visibility"
            content: <Visibility
                continuous={false}
                once={false}
                onBottomVisible={() => {
                    loadList(searchQuery, items.length)
                }}
            >
                <Loader size='tiny' active/>
            </Visibility>
        })

        return values;
    }

    return (
        <>
            {label && <label
                className={isDisabled ? "label-disabled" : ''}
            >
                {t(label)} {isRequired && '*'}
            </label>}
            <div className="dropbox-button">
                <Dropdown
                    upward={upward}
                    text={((!value || (value && value.length === 0)) && nullIsAll) && t(`${name}IsAny`)}
                    id={`dropdown_${name}`}
                    clearable={clearable}
                    multiple={multiple}
                    name={name}
                    value={
                        onlyValue
                            ? (separator ? value?.split(separator) : value)
                            : (multiple ? (value?.map(v => v?.value) || []) : (value?.value || ''))
                    }
                    onChange={handleOnChange}
                    onFocus={onFocus}
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    disabled={isDisabled || false}
                    search={(options) => {
                        return multiple ? options : options.filter(o => o.value !== value?.value)
                    }}
                    selection
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    className={className}
                    options={getOptions()}
                    onBlur={() => setSearchQuery('')}
                    placeholder={placeholder}
                    noResultsMessage={t('noResultFound')}
                />
                {(withEdit && value?.value && canEdit) && editBtn(true)}
                {(withCreate && !isDisabled && canEdit) && editBtn()}
            </div>
        </>
    );
};

export default DictionaryDropdown;