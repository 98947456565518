import React, {memo} from 'react';
import {
    ACTIONS,
    BOOLEAN,
    COLOR,
    DATE,
    DATE_TIME,
    EMAIL,
    ENUM,
    FILLING_SELECT,
    INTEGER,
    IS_ACTIVE,
    MULTISELECT,
    MULTISELECT_WITH_ALL,
    MULTISELECT_WITH_ALL_PLUS_EMPTY,
    NUMBER,
    PASSWORD,
    PHONE_NUMBER,
    SCHEDULE,
    SELECT,
    STATE,
    TEXT,
    TEXTAREA,
    TIME,
    TIMESLOT
} from "../../constants/fieldTypes";
import Text from "./Text";
import Bool from "./Bool";
import EnumDropdown from "./EnumDropdown";
import DictionaryField from "./DictionaryField";
import TextAreaEdit from "./TextArea";
import DropdownWithAllCheckbox from "./DropdownWithAllCheckbox";
import {Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import './style.scss'
import DateTime from "./DateTime";
import Date from "./Date";
import {settingsByName} from "../../constants/utils";
import {fieldSettingsValues} from "../../constants/options";
import PhoneNumber from "./PhoneNumber";
import RowActions from "../table/components/rowActions";
import Schedule from "../table/components/schedule";
import Timeslot from "./Timeslot";
import Color from "./Color";

const fieldComponents = {
    [TEXT]: <Text/>,
    [NUMBER]: <Text type='number'/>,
    [INTEGER]: <Text isInteger/>,
    [TIME]: <Text type='time'/>,
    [PHONE_NUMBER]: <PhoneNumber/>,
    [DATE_TIME]: <DateTime/>,
    [DATE]: <Date/>,
    [PASSWORD]: <Text type='password'/>,
    [TEXTAREA]: <TextAreaEdit/>,
    [EMAIL]: <Text type='email'/>,
    [BOOLEAN]: <Bool/>,
    [IS_ACTIVE]: <Bool/>,
    [ENUM]: <EnumDropdown/>,
    [STATE]: <EnumDropdown/>,
    [FILLING_SELECT]: <EnumDropdown multiple allowAdditions/>,
    [SELECT]: <DictionaryField/>,
    [MULTISELECT_WITH_ALL]: <DropdownWithAllCheckbox/>,
    [MULTISELECT_WITH_ALL_PLUS_EMPTY]: <DropdownWithAllCheckbox showWithEmpty/>,
    [TIMESLOT]: <Timeslot/>,
    [MULTISELECT]: <DictionaryField multiple/>,
    [SCHEDULE]: <Schedule/>,
    [ACTIONS]: <RowActions/>,
    [COLOR]: <Color/>,
}

const FieldComponent = ({type, ...props}) => {
    const {t} = useTranslation();

    const {error, hintKey, className, style, fieldSettings = [], name, isDisabled: disabled, stateValue, isReadOnly, width} = props;

    const settings = settingsByName(name, fieldSettings) || {};

    const settingByState = settings.accessTypes && settings.accessTypes[stateValue];

    const isHidden = settings.isHidden || settingByState === fieldSettingsValues.HIDDEN;
    const isDisabled = isReadOnly || settings.isReadOnly || disabled || settingByState === fieldSettingsValues.SHOW;

    return (
        !isHidden && <Form.Field
            style={style}
            error={error}
            className={className}
            width={width}
        >
            {React.cloneElement(fieldComponents[type] || <Text/>, {...props, isDisabled})}
            {(hintKey || error) && <div className='mini-text'>
                {hintKey && <div className='mini-text_hint'>{t(hintKey)}</div>}
                {(error && typeof error === 'string') && <div className='mini-text_error'>{t(error)}</div>}
            </div>}
        </Form.Field>
    );
};

export default memo(FieldComponent);