import {DATE, MULTISELECT, SELECT} from "./fieldTypes";

export const gateReviewsFilters = [
    {
        name: 'platformId',
        displayNameKey: 'platforms',
        type: SELECT,
        dictionaryName: 'platforms',
        onlyValue: true
    },
    {
        name: 'activityId',
        displayNameKey: 'activities',
        type: SELECT,
        dictionaryName: 'activities',
        onlyValue: true,
    },
    {
        name: 'gates',
        displayNameKey: 'gates',
        type: MULTISELECT,
        dictionaryName: 'gates',
        nullIsAll: true,
        onlyValue: true,
        separator: '|'
    },
    {
        displayNameKey: 'visitDate',
        name: 'visitDate',
        type: DATE,
    },
    {
        name: 'companyIds',
        displayNameKey: 'companies',
        type: MULTISELECT,
        dictionaryName: 'companies',
        nullIsAll: true,
        onlyValue: true,
        separator: '|'
    },
    {
        name: 'visits',
        displayNameKey: 'visits',
        type: MULTISELECT,
        gridName: 'visits',
        nullIsAll: true,
        onlyValue: true,
        separator: '|'
    }
]

export const requestFiltersForEnabled = {
    activityId: [gateReviewsFilters[0].name],
    gates: [gateReviewsFilters[0].name],
    companyIds: [gateReviewsFilters[0].name, gateReviewsFilters[1].name],
    visits: [gateReviewsFilters[0].name, gateReviewsFilters[1].name, gateReviewsFilters[3].name],
    button: [gateReviewsFilters[0].name, gateReviewsFilters[3].name]
}